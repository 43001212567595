export default (theme) => ({
	teamSettings: {
		display: 'flex',
		flexDirection: 'column',
		padding: '25px',
		maxWidth: '1010px',
		[theme.breakpoints.down('sm')]: {
			padding: '16px',
			maxWidth: '100%',
			marginBottom: '84px'
		}
	},
	settingsWrapper: {
		display: 'flex',
		marginTop: '42px',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'center'
		}
	},
	pofileImgWrapper: {
		display: 'flex',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '20px'
		}
	},
	formWrapper: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		marginLeft: '33px',
		[theme.breakpoints.down('sm')]: {
			margin: '0px'
		}
	},
	mobileFormRow: {
		display: 'flex',
		alignItems: 'flex-end'
	},
	ageGroup: {
		width: '176px',
		marginLeft: '16px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	gender: {
		width: '176px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	name: {
		maxWidth: '720px',
		[theme.breakpoints.down('sm')]: {
			maxWidth: '100%'
		}
	},
	formRow: {
		[theme.breakpoints.up('sm')]: {
			display: 'flex',
			alignItems: 'flex-end'
		}
	},
	websiteUrl: {
		width: '368px',
		marginRight: '16px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			margin: '0px'
		}
	},
	stateZipFields: {
		width: '176px',
		marginLeft: '16px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			margin: '0px',
			'&:not(:first-child)': {
				marginLeft: '16px',
			},
		}
	},
	city: {
		width: '176px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},

	sport: {
		width: '216px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	actionButtons: {
		display: 'flex',
		justifyContent: 'flex-end',
		[theme.breakpoints.down('sm')]: {
			position: 'fixed',
			bottom: '0px',
			left: '0px',
			padding: '16px',
			width: '100%',
			boxShadow: '3px 0 6px 0 #00000029',
			backgroundColor: '#fff',
			zIndex: 1
		}
	},
	cancelBtn: {
		marginRight: '14px',
		width: '141px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	submitBtn: {
		width: '167px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},

});
