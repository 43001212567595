export default (theme) => ({
	teams: {
		display: 'flex',
		flexDirection: 'column',
		padding: '26px',
		[theme.breakpoints.down('sm')]: {
			padding: '16px'
		}
	},
	header: {
		marginBottom: '24px'
	},
	section: {
		marginBottom: '24px'
	}
});
