export default (theme) => ({
	mainMenuPaper: {
		minWidth: '330px',
		padding: '21px 24px 0px 24px'
	},
	menuNameTitle: {
		fontSize: '24px',
		marginBottom: '10px',
		display: 'block'
	},
	menuSectionHeader: {
		color: '#7c7c7c',
		fontSize: '14px',
		textTransform: 'uppercase',
		marginBottom: '5px',
	},
	menuItem: {
		paddingLeft: '0px',
		paddingRight: '0px',
		fontSize: '14px',
		color: theme.palette.text.primary,
		'&:hover': {
			backgroundColor: 'transparent',
			color: theme.palette.primary.main,
		}
	},
	divider: {
		marginTop: '10px',
		marginBottom: '10px'
	}
});
