export default () => ({
	loginForm: {
		marginTop: '2px'
	},
	formControl: {
		marginTop: '33px'
	},
	forgotPasswd: {
		marginTop: '11px',
		marginBottom: '13px',
		display: 'inline-block'
	},
	signupWrapper: {
		marginTop: '22px',
	},
	recoveryMsg: {
		width: '100%',
		top: '0px'
	},
	alert: {
		width: '100%',
		borderRadius: '0px',
		height: '58px',
		alignItems: 'center',
		'& .MuiSvgIcon-root': {
			fontSize: '30px'
		}
	},
	snackBump: {
		height: '58px'
	}
});
