import React from 'react';
import Typography from '@material-ui/core/Typography';
import {
	Link as RouterLink,
} from 'react-router-dom';
import { ReactComponent as PencilIcon } from 'icons/Pencil_edit_icon.svg';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
const useStyles = makeStyles(styles);

const TeamProfileHeader = ({
	team,
	editable
}) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.teamProfileHeader}>
			<Typography variant="h5" className={classes.name}>{team.name}</Typography>
			<Typography variant="h6" className={classes.location}>{`${team.city}, ${team.state}`}</Typography>
			<Typography variant="h6" className={classes.genderAgeGroup}>{`${t(team.gender)} | ${t(team.ageGroup)}`}</Typography>
			{team.websiteUrl && <Link href={team.websiteUrl} variant="body2">{t('team_website')}</Link>}
			{editable &&
				<Button
					className={classes.editProfileBtn}
					component={RouterLink}
					color="primary"
					startIcon={<PencilIcon />}
					to={`/team/${team.id}/settings`}
				>{t('edit_team_info')}</Button>
			}
		</div>
	);
};

export default TeamProfileHeader;
