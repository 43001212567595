export default (theme) => ({
	root: {
		display: 'flex',
		width: '100%',
		height: '100%',
		backgroundColor: '#fff'
	},
	leftSide: {
		display: 'flex',
		flexDirection: 'column',
		paddingTop: '20px',
		[theme.breakpoints.up('md')]: {
			paddingTop: '52px',
			paddingLeft: '47px',
			paddingRight: '47px'
		},
		paddingBottom: '30px',
		flex: 1,
		width: '100%',
		overflow: 'auto',
		[theme.breakpoints.down('sm')]: {
			alignItems: 'center'
		}
	},
	mobileDivider: {
		width: '100%',
		marginTop: '20px'
	},
	leftSideFormContent: {
		display: 'flex',
		flexDirection: 'column',
	},
	hiddenPanel: {
		display: 'none !important'
	},
	welcomeHeading: {
		marginTop: '67px',
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
			marginTop: '38px',
			paddingLeft: '16px',
			paddingRight: '16px'
		}
	},
	welcomeSubText: {
		fontSize: '26px',
		marginTop: '12px',
		maxWidth: '600px'
	},
	profileForm: {
		display: 'flex',
		marginTop: '60px',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			paddingLeft: '20px',
			paddingRight: '20px',
			marginTop: '20px'
		}
	},
	profileFormRightSide: {
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.down('sm')]: {
			marginTop: '30px'
		},
		[theme.breakpoints.up('md')]: {
			marginLeft: '47px'
		}
	},
	profileFormLeftSide: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between'
	},
	medWidthFields: {
		[theme.breakpoints.up('md')]: {
			width: '176px'
		}
	},
	cityField: {
		[theme.breakpoints.up('md')]: {
			width: '378px'
		}
	},
	stateZipWrapper: {
		display: 'flex'
	},
	stateZipFields: {
		[theme.breakpoints.up('md')]: {
			width: '176px',
		},
		marginLeft: '30px',
		'&:first-child': {
			margin: '0px'
		}
	},
	actionWrapper: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '16px',
			marginRight: '16px',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center'
		},
	},
	submitButton: {
		[theme.breakpoints.up('md')]: {
			width: '254px',
			marginTop: '36px'
		}
	},
	finishButton: {
		[theme.breakpoints.up('md')]: {
			width: '254px',
			alignSelf: 'center'
		}
	},
	leftSideSportSelectContent: {
		display: 'block'
	},
	sportListWrapper: {
		marginTop: '33px',
		flexDirection: 'column',
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '2px',
			paddingRight: '2px'
		}
	},
	gridList: {
		display: 'flex',
		flexWrap: 'wrap',
		margin: '-8px',
		marginBottom: '72px',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '24px',
		}
	},
	tile: {
		overflow: 'visible'
	},
	sportsButton: {
		height: '100px',
		width: '100px',
		[theme.breakpoints.down('sm')]: {
			paddingTop: '10px',
			height: '80px',
			width: '80px',
		},
		borderRadius: '4px',
		boxShadow: '0 0 3px 0 rgba(0, 0, 0, 0.16)',
		border: 'solid 1px #d6d6d6',
		margin: '8px',
		flexDirection: 'column',
		color: '#7c7c7c',
		'&:hover': {
			color: '#00171f',
			borderColor: theme.palette.secondary.main,
			borderWidth: '2px',
			'& $sportIcon': {
				stroke: '#00171f',
			},
			'& sportIconWithFill': {
				fill: '00171f',
				stroke: '00171f'
			}
		}
	},
	selected: {
		color: '#00171f',
		borderColor: theme.palette.secondary.main,
		borderWidth: '2px',
		'& $sportIcon': {
			stroke: '#00171f',
		},
		'& sportIconWithFill': {
			fill: '00171f',
			stroke: '00171f'
		}
	},
	sportIcon: {
		width: '44px',
		height: '44px',
		[theme.breakpoints.down('sm')]: {
			width: '32px',
			height: '32px',
		},
		stroke: '#7c7c7c',
		'& g, & path, & circle, & line, & polyline': {
			stroke: 'inherit'
		}
	},
	sportIconWithFill: {
		fill: '#7c7c7c',
		stroke: '#7c7c7c',
		'& g, & path, & circle, & line, & polyline': {
			fill: 'inherit',
			stroke: 'inherit'
		}
	},
	buttonText: {
		display: 'block',
		marginTop: '10px',
	},
	backButton: {
		marginTop: '4px',
		[theme.breakpoints.up('md')]: {
			marginTop: '35px',
			alignSelf: 'flex-start',
		},
		fontSize: '18px',
		fontFamily: 'Source Sans Pro',
		'& .MuiButton-startIcon': {
			'& svg': {
				fontSize: '24px'
			},
			marginRight: '0px'
		}
	},
	rightSide: props => ({
		display: 'flex',
		position: 'relative',
		width: '559px',
		backgroundImage: `url(${props.splashImg})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'bottom center',
		backgroundSize: 'cover',
	}),
	rightOverlay: {
		width: '100%',
		height: '100%',
		position: 'absolute',
		zIndex: 2,
		opacity: 0.3,
		background: 'linear-gradient(to bottom, #0059ff 1%, #6b17f2)'
	},

});
