export default () => ({
	article: {
		padding: '34px 24px',
	},
	actionButtonWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: '16px',
		height: '50px',
	},
	backButton: {
		fontSize: '18px',
		letterSpacing: '-0.36px',
		minWidth: '65px',
	},
	articleWrapper: {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '200px',
		width: '100%',
		padding: '16px 26px',
	},
	header: {
		display: 'flex',
		flexDirection: 'column',
		marginBottom: '30px',
	},
	subTextRow: {
		display: 'flex',
	},
	entityName: {
		fontSize: '24px',
		flex: 1,
	},
	date: {
		fontSize: '18px',
	},
	body: {
		fontSize: '18px',
		whiteSpace: 'pre-wrap',
	},
	shareToFacebookButton: {
		marginRight: '15px'
	}
});
