import { createSelector } from 'reselect';

const getTeamInvitesFromState = state => state.team.teamInvites;
const getTeamMembersFromState = state => state.team.teamMembers;
const getChildrenFromState = state => state.parentChildren;
const getTeamLinkIdFromState = state => state.team.inviteLinkId;

export const getRoster = createSelector(
	[getTeamInvitesFromState, getTeamMembersFromState],
	(teamInvites, teamMembers) => {
		if (teamInvites && teamMembers) {
			const pendingInvites = teamInvites.filter((invite) => invite.inviteStatus === 'invitation_pending');
			return [
				...teamMembers,
				...pendingInvites
			];
		}
		return teamMembers;
	}
);

export const getChildren = createSelector(
	[getChildrenFromState, getRoster], (children, roster) =>
		children.filter((child) => !roster.some((tm) => tm.userId === child.id))
);

export const getTeamInviteLink = createSelector(
	[getTeamLinkIdFromState], (inviteLinkId) => `${window.location.origin}/invite/${inviteLinkId}`);
