import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '@material-ui/core/Button';
import ArticleItem from './ArticleItem';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import clsx from 'clsx';
import Divider from '@material-ui/core/Divider';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
const useStyles = makeStyles(styles);

const MediaCollection = ({
	items,
	emptyMsg,
	title,
	emptyButtonText,
	onEmptyActionButtonClick,
	isAdmin,
	onRemoveItem
}) => {
	const classes = useStyles();
	return (
		<div className={classes.mediaCollection}>
			<div className={classes.header}>
				<Typography variant="h6" className={classes.title}>{title}</Typography>
				<Divider />
			</div>
			<>
				<Fade in={items === null} timeout={1000}>
					<div className={clsx(classes.skeletonWrapper, { [classes.hiddenPanel]: items })}>
						<Skeleton className={classes.skeletonRect} variant="rect" width={327} height={216} animation="wave" />
						<Skeleton className={classes.skeletonRect} variant="rect" width={327} height={216} animation="wave" />
						<Skeleton className={classes.skeletonRect} variant="rect" width={327} height={216} animation="wave" />
					</div>
				</Fade>
				<Fade in={items && items.length} timeout={1000}>
					<div className={clsx(classes.itemList, { [classes.hiddenPanel]: (!items || !items.length) })}>
						{items && items.map((item) => (
							<ArticleItem
								key={item.id}
								item={item}
								classes={classes}
								isAdmin={isAdmin}
								onRemoveItem={onRemoveItem}
							/>
						))}
					</div>
				</Fade>
				<Fade in={items && items.length === 0} timeout={1000}>
					<div className={clsx(classes.emptyWrapper, { [classes.hiddenPanel]: (!items || items.length) })} >
						<Typography variant="body1" className={classes.emptyText}>{emptyMsg}</Typography>
						{onEmptyActionButtonClick &&
							<Button variant="contained" color="primary" className={classes.emptyButton} onClick={onEmptyActionButtonClick}>{emptyButtonText}</Button>
						}
					</div>
				</Fade>
			</>
		</div>
	);
};

export default MediaCollection;
