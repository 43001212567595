export default (theme) => ({
	paperWidthMd: {
		[theme.breakpoints.up('sm')]: {
			maxWidth: '850px',
			minWidth: '850px'
		}
	},
	dialogTitle: {
		[theme.breakpoints.down('sm')]: {
			borderBottom: '1px solid #e6e6e6',
			padding: '16px',
			paddingTop: '20px',
			paddingBottom: '20px'
		}
	},
	title: {
		[theme.breakpoints.up('sm')]: {
			fontSize: '26px'
		}
	},
	contentWrapper: {
		display: 'flex',
		paddingTop: '20px',
		paddingLeft: '66px',
		paddingRight: '71px',
		[theme.breakpoints.down('sm')]: {
			padding: '16px',
			flexDirection: 'column',
		}
	},
	leftSide: {
		marginRight: '75px',
		[theme.breakpoints.down('sm')]: {
			margin: '0px'
		}
	},
	rightSide: {
		flex: 1,
		[theme.breakpoints.down('sm')]: {
			marginTop: '20px'
		}
	},
	name: {
		maxWidth: '378px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	formRow: {
		[theme.breakpoints.up('sm')]: {
			display: 'flex',
			alignItems: 'flex-end'
		}
	},
	mobileFormRow: {
		display: 'flex',
		alignItems: 'flex-end'
	},
	zipCode: {
		width: '107px',
		[theme.breakpoints.down('sm')]: {
			width: '176px'
		}
	},
	state: {
		width: '176px',
		marginRight: '16px',
		marginLeft: '16px',
		[theme.breakpoints.down('sm')]: {
			marginLeft: '0px',
		}
	},
	city: {
		width: '176px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	actionsWrapper: {
		paddingBottom: '55px',
		paddingTop: '28px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			paddingTop: '20px',
			paddingBottom: '0px',
			boxShadow: '3px 0 6px 0 #00000029'
		}
	},
	cancelBtn: {
		marginRight: '14px',
		width: '141px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	submitBtn: {
		width: '206px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	}
});
