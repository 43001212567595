import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import KList from 'components/KList';
import Paper from '@material-ui/core/Paper';
import { usePrevious } from 'utils/hooks/customHooks';
import Toast from 'components/Toast';
import AddChildModal from './AddChildModal';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import styles from './styles';
const useStyles = makeStyles(styles);

const ChildrenSettings = ({
	user,
	createChildProfile,
	creatingChildProfile,
	creatingChildProfileSuccess,
	acceptInviteSuccess,
	resetSuccess
}) => {
	const theme = useTheme();
	const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();
	const { t } = useTranslation();
	const prevCreatingChildProfile = usePrevious(creatingChildProfile);
	const [showAddChildModal, setShowAddChildModal] = useState(false);
	const [childAddedToTeam, setChildAddedToTeam] = useState(null);
	const [toastProps, setToastProps] = useState({
		open: false,
		message: '',
		severity: null
	});
	const onToastClose = () => {
		setToastProps({
			open: false,
			message: '',
			severity: ''
		});
	};
	useEffect(() => {
		if (creatingChildProfileSuccess && prevCreatingChildProfile && !creatingChildProfile) {
			handleAddChildModalClose();
			if(childAddedToTeam) { 
				setToastProps({
					open: true,
					message: t('child_invite_accepted', {childName: `${childAddedToTeam.firstName} ${childAddedToTeam.lastName}`, teamName: childAddedToTeam.teamName}),
					severity: 'success'
				});
				setChildAddedToTeam(null);
			}
		}
	}, [prevCreatingChildProfile, creatingChildProfileSuccess, creatingChildProfile]);
	useEffect(() => {
		if (acceptInviteSuccess) {
			setToastProps({
				open: true,
				message: t('parent_invite_accepted'),
				severity: 'success'
			});
			resetSuccess();
		}
	}, [acceptInviteSuccess]);
	const getExistingChildren = () => user.children.map((child) => ({
		id: child.id,
		primaryText: `${child.firstName} ${child.lastName}`,
		avatar: child.profileImgUrl,
		linkTo: `/user/${child.id}`
	}));
	const handleOnAddChildClick = () => {
		setShowAddChildModal(true);
	};
	const handleAddChildSubmit = (child) => {
		if (child.teamId) {
			setChildAddedToTeam({...child, teamName: user.teams.find((t) => t.id === child.teamId).name});
		}
		createChildProfile(child);
	};
	const handleAddChildModalClose = () => {
		setShowAddChildModal(false);
	};
	return (
		<div className={classes.childrenSettings}>
			{user &&
				<div className={classes.formWrapper}>
					<Typography variant="h6" className={classes.sectionTitle}>{t('your_child_accounts')}</Typography>
					<Divider className={classes.divider} variant="fullWidth" />
					<div className={classes.header}>
						<Button
							variant="contained"
							color="primary"
							className={classes.addChildButton}
							onClick={handleOnAddChildClick}
						>{t('add_a_child')}</Button>
					</div>
					<div className={classes.childListWrapper}>
						<FormLabel className={classes.listLabel}>{t('existing')}</FormLabel>
						{!user.children || user.children.length === 0 ?
							<div className={classes.emptyListWrapper}>
								<Typography variant="body1" className={classes.emptyText}>{t('you_have_no_child_accounts')}</Typography>
								{!matchesSm &&
									<Button onClick={handleOnAddChildClick} color="primary" variant="contained" className={classes.addChildButton}>
										{t('add_child')}
									</Button>
								}
							</div>
							:
							<Paper className={classes.listWrapper}>
								<KList
									links
									items={getExistingChildren()}
								/>
							</Paper>
						}
					</div>
				</div>
			}
			<AddChildModal
				show={showAddChildModal}
				onClose={handleAddChildModalClose}
				onSubmit={handleAddChildSubmit}
				teams={user.teams}
			/>
			<Toast
				{...toastProps}
				onClose={onToastClose}
			/>
		</div>
	);
};

export default ChildrenSettings;
