/* eslint-disable no-empty-pattern */
import { connect } from 'react-redux';
import flow from 'lodash.flow';
import TeamHighlights from './TeamHighlights';
import { fetchTeamArticles, deleteArticle, clearArticleSuccess } from 'reactive-core';
import {getArticleCardsForEntity} from 'selectors/articleSelector';

export default flow(
	connect(
		({
			profile: { team },
			uievents: {deleteArticleSuccess}
		}) => ({
			team,
			deleteArticleSuccess,
			articles: getArticleCardsForEntity({entity:team}) || null
		}),
		(dispatch) => ({
			fetchTeamArticles: (id) => dispatch(fetchTeamArticles(id)),
			deleteArticle: (id) => dispatch(deleteArticle(id)),
			clearArticleSuccess: () => dispatch(clearArticleSuccess())
		})
	)
)(TeamHighlights);
