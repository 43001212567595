import { connect } from 'react-redux';
import flow from 'lodash.flow';
import WithAuth from 'WithAuth';
import Settings from './Settings';
import WithResourceNotFound from 'components/WithResourceNotFound';
import { fetchChildFullProfile, clearResourceNotFound, fetchAuthUserFullProfile } from 'reactive-core';
export default flow(
	connect(
		({
			authUser: { user },
			profile: { user: profileUser },
			uievents: { resourceNotFound },
			authevents: { emailUpdateSuccess, updatingEmail }
		}) => ({
			authUser: user,
			profileUser,
			emailUpdateSuccess,
			updatingEmail,
			resourceNotFound
		}), (dispatch) => ({
			fetchChildFullProfile: (id) => dispatch(fetchChildFullProfile(id)),
			clearResourceNotFound: () => dispatch(clearResourceNotFound()),
			fetchAuthUserFullProfile: () => dispatch(fetchAuthUserFullProfile())
		})),
	WithAuth
)(WithResourceNotFound(Settings));
