export default (theme) => ({
	accountSettings: {
		display: 'flex',
		paddingLeft: '361px',
		[theme.breakpoints.down('sm')]: {
			padding: '16px'
		}
	},
	formWrapper: {
		[theme.breakpoints.up('sm')]: {
			width: '808px',
			paddingBottom: '40px',
			marginTop: '50px'
		},
		display: 'flex',
		flexDirection: 'column',

	},
	divider: {
		marginTop: '4px',
		marginBottom: '40px'
	},
	title: {
		fontSize: '18px'
	},
	submitButton: {
		marginTop: '5px',
		width: '185px',
		marginBottom: '32px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	subTitle: {
		fontSize: '16px',
		color: theme.palette.text.secondary,
		marginBottom: '18px'
	},
	closeAccountBtn: {
		backgroundColor: theme.palette.error.main,
		color: '#fff',
		'&:hover': {
			backgroundColor: theme.palette.error.dark
		}
	},
	labels: {
		marginTop: '50px'
	},
});
