export default () => ({
	formWrapper: {
		marginTop: '107px',
	},
	fullWidthForm: {
		marginTop: '107px',
		textAlign: 'center'
	},
	resetForm: {
		marginTop: '2px',
		maxWidth: '450px'
	},
	loginLink: {
		display: 'flex',
		marginTop: '47px'
	},
	passwordControl: {
		marginTop: '35px',
		textAlign: 'left'
	},
	emailControl: {
		marginTop: '45px',
		marginBottom: '38px'
	},
	passwordBtn: {
		marginTop: '60px',
	},
	loginLinkBtn: {
		width: '230px',
		marginTop: '81px'
	},
	signupWrapper: {
		marginTop: '35px',
	}
});
