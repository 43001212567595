export default (theme) => ({
	teamRoster: {
		padding: '20px 26px',
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.down('sm')]: {
			padding: '16px',
			marginBottom: '84px',
		}
	},
	header: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginBottom: '24px'
	},
	importRosterButton: {
		marginRight: '10px',
		minWidth: '167px'
	},
	addTeamMemberButton: {
		minWidth: '210px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},

});
