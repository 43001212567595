export default () => ({
	paperWidthSm: {
		minWidth: '457px',
		maxWidth: '457px'
	},
	titleWrapper: {
		padding: '32px 36px 32px 36px'
	},
	contentWrapper: {
		paddingLeft: '36px',
		paddingRight: '36px'
	},
	actionsWrapper: {
		marginBottom: '50px'
	},
	role: {
		display: 'flex',
		marginBottom: '30px',
	},
	cancelBtn: {
		paddingLeft: '46px',
		paddingRight: '46px'
	},
	submitBtn: {
		paddingLeft: '39px',
		paddingRight: '39px'
	},
	checkbox: {
		marginBottom: '25px',
	}
});
