export default (theme) => ({
	paperWidthSm: {
		[theme.breakpoints.up('sm')]: {
			minWidth: '559px',
			maxWidth: '559px'
		}
	},
	titleWrapper: {
		padding: '32px 36px 32px 36px',
		[theme.breakpoints.down('sm')]: {
			padding: '16px'
		}
	},
	formRow: {
		display: 'flex',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column'
		}
	},
	name: {
		marginLeft: '16px',
		'&:first-child': {
			margin: '0px'
		},
		[theme.breakpoints.down('sm')]: {
			margin: '0px'
		}
	},
	contentWrapper: {
		paddingLeft: '36px',
		paddingRight: '36px',
		[theme.breakpoints.down('sm')]: {
			padding: '16px'
		}
	},
	actionsWrapper: {
		marginTop: '24px',
		marginBottom: '50px'
	},
	cancelBtn: {
		paddingLeft: '46px',
		paddingRight: '46px',
		marginRight: '14px'
	},
	submitBtn: {
		paddingLeft: '39px',
		paddingRight: '39px'
	},
	gender: {
		width: '176px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	teamWrapper: {
		display: 'flex',
		alignItems: 'center'
	},
	team: {
		width: '100%'
	}
});
