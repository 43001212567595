import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import pnfImg from 'img/404.png';
import styles from './styles';

const useStyles = makeStyles(styles);

const PageNotFound = () => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.pageNotFound}>
			<img className={classes.pnfImg} src={pnfImg} alt={t('404_error')} />
			<Typography variant="h4" className={classes.notFoundText}>{t('the_page_requested_was_not_found')}</Typography>
			<Typography variant="body1" className={classes.notFoundSubText} color="textSecondary">{t('the_link_may_be_broken')}</Typography>
			<Button
				component={RouterLink}
				to="/"
				variant="contained"
				color="primary"
			>{t('go_home')}</Button>
		</div>
	);
};

export default PageNotFound;
