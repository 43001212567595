import { connect } from 'react-redux';
import flow from 'lodash.flow';
import TeamProfile from './TeamProfile';
import { fetchTeam, clearResourceNotFound, clearTeamOrgSuccess, resetSuccess } from 'reactive-core';
import WithResourceNotFound from 'components/WithResourceNotFound';

export default flow(
	connect(
		({
			authevents: {acceptInviteSuccess},
			authUser: { user },
			uievents: { resourceNotFound, updateTeamSuccess},
			profile: { team }
		}) => ({
			authUser: user,
			profileTeam: team,
			resourceNotFound,
			updateTeamSuccess,
			acceptInviteSuccess
		}),
		(dispatch) => ({
			fetchTeam: (id) => dispatch(fetchTeam(id)),
			clearResourceNotFound: () => dispatch(clearResourceNotFound()),
			clearTeamOrgSuccess: () => dispatch(clearTeamOrgSuccess()),
			resetSuccess: () => dispatch(resetSuccess())
		})
	)
)(WithResourceNotFound(TeamProfile));

