/* eslint-disable no-empty-pattern */
import { connect } from 'react-redux';
import flow from 'lodash.flow';
import Teams from './Teams';
import { fetchUserFeed } from 'reactive-core';
import { getOrganizationsForListWidget, getTeamsForListWidget } from 'selectors/userSelectors';

export default flow(
	connect(
		({
		}, {
			user
		}) => ({
			organizations: getOrganizationsForListWidget({ user }),
			teams: getTeamsForListWidget({ user })
		}),
		(dispatch) => ({
			fetchUserFeed: (id) => dispatch(fetchUserFeed(id)),
		})
	)
)(Teams);
