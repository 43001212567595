import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import {ReactComponent as RightArrowIcon} from 'icons/Right_arrow_icon.svg';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
const useStyles = makeStyles(styles);

const KListLinkItem = ({
	item
}) => {
	const classes = useStyles();
	return (
		<ListItem button component={RouterLink} to={item.linkTo} className={classes.listItem}>
			<ListItemAvatar>
				<Avatar alt={item.primaryText} src={item.avatar} className={classes.avatar} />
			</ListItemAvatar>
			<ListItemText
				primary={item.primaryText}
				secondary={item.secondaryText}
				primaryTypographyProps={{
					variant: 'h6',
					component: 'span'
				}}
				classes={{
					primary: classes.listItemText,
					secondary: classes.listItemText
				}}
			/>
			<ListItemSecondaryAction>
				<RightArrowIcon className={classes.rightArrow}/>
			</ListItemSecondaryAction>
			
		</ListItem>
	);
};

export default KListLinkItem;
