import React, { useState } from 'react';
import clsx from 'clsx';
import LogoHeading from 'components/LogoHeading';
import ProfileImageEdit from 'components/ProfileImageEdit';
import CreateProfileForm from './CreateProfileForm';
import CreateOrgForm from './CreateOrgForm';
import SportsGridSelect from './SportsGridSelect';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import { SPORTS } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import splashImg from 'img/createProfile-splash.png';
import { useQuery } from 'utils/hooks/customHooks';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import styles from './styles';
const useStyles = makeStyles(styles);

const CreateProfile = ({ onSubmitClick, user }) => {
	const theme = useTheme();
	const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
	const query = useQuery();
	const inviteId = query.get('invite');
	const isParent = query.get('isParent');
	const classes = useStyles({ splashImg, matchesSm });
	const { t } = useTranslation();
	const [updatedProfile, setUpdatedProfile] = useState(null);
	const [activeStep, setActiveStep] = useState(0);
	const [profileImg, setProfileImg] = useState(null);
	const [orgProfileImg, setOrgProfileImg] = useState(null);


	const handleOnProfileSubmit = (userProfileData) => {
		setUpdatedProfile(userProfileData);
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};
	const handleOnBackClick = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleOnSportsSubmit = (selectedSports) => {
	
		if (inviteId) {
			onSubmitClick({ ...updatedProfile,
				sports: selectedSports,
				firstName: user.firstName,
				lastName: user.lastName,
				profileImg 
			});
		} else {
			setUpdatedProfile({ ...updatedProfile, sports: selectedSports });
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	};
	const handleOnOrgSubmit = (orgProfile) => {
		onSubmitClick({
			...updatedProfile,
			firstName: user.firstName,
			lastName: user.lastName,
			profileImg,
			org: {
				...orgProfile,
				profileImg: orgProfileImg
			}
		});
	};
	return (
		<div className={classes.root}>
			<div className={classes.leftSide}>
				<LogoHeading size={matchesSm ? 'sm' : 'lg'} />
				{matchesSm &&
					<Divider className={classes.mobileDivider} />
				}
				<Fade in={activeStep === 0} timeout={500}>
					<div
						className={clsx(classes.leftSideFormContent, {
							[classes.hiddenPanel]: activeStep !== 0,
						})}
					>
						<div className={classes.welcomeHeading}>
							<Typography variant="h2" className={classes.welcomeTitle}>
								{t('welcome_user', { firstName: user.firstName })}
							</Typography>
							<Typography variant="h5" className={classes.welcomeSubText}>
								{isParent ?
									t('lets_first_create_your_parent_profile')
									: t('lets_create_your_profile')
								}
							</Typography>
						</div>
						<form className={classes.profileForm}>
							<ProfileImageEdit
								// user now contains profileImgUrl if is signed in with google but it's not rendering
								uploadTitle={t('add_profile_pic')}
								avatarImageUrl={user.profileImgUrl}
								size={matchesSm ? '130' : '246'}
								onUpdateAvatar={(imgObj) => {
									setProfileImg(imgObj.file);
								}}
								buttonLabel={t('choose_profile_picture')}
							/>
							<div className={classes.profileFormRightSide}>
								<CreateProfileForm
									user={user}
									onSubmit={handleOnProfileSubmit}
								/>
							</div>
						</form>
					</div>
				</Fade>
				<Fade in={activeStep === 1} timeout={500}>
					<div
						className={clsx(classes.leftSideSportSelectContent, {
							[classes.hiddenPanel]: activeStep !== 1,
						})}
					>
						<div className={classes.welcomeHeading}>
							<Typography variant="h2" className={classes.welcomeTitle}>
								{t('whats_your_sport')}
							</Typography>
							<Typography variant="h5" className={classes.welcomeSubText}>
								{t('select_as_many_you_can_change')}
							</Typography>
						</div>
						<SportsGridSelect
							sports={[...SPORTS, 'other']}
							onSubmit={handleOnSportsSubmit}
							onBackClick={handleOnBackClick}
							submitBtnText={inviteId ? t('finish') : t('next')}
						/>
					</div>
				</Fade>
				<Fade in={activeStep === 2} timeout={500}>
				
					<div
						className={clsx(classes.leftSideFormContent, {
							[classes.hiddenPanel]: activeStep !== 2,
						})}
					>
						{activeStep === 2 && 
					<>
						<div className={classes.welcomeHeading}>
							<Typography variant="h2" className={classes.welcomeTitle}>
								{t('create_your_org')}
							</Typography>
							<Typography variant="h5" className={classes.welcomeSubText}>
								{t('as_an_org_admin')}
							</Typography>
						</div>
						<form className={classes.profileForm}>
							<div className={classes.profileFormLeftSide}>
								<ProfileImageEdit
									uploadTitle={t('add_organization_logo')}
									size={matchesSm ? '130' : '246'}
									onUpdateAvatar={(imgObj) => {
										setOrgProfileImg(imgObj.file);
									}}
									buttonLabel={t('choose_logo')}
								/>
								{!matchesSm && 
									<Button color="primary" startIcon={<ChevronLeftIcon />} onClick={handleOnBackClick}>{t('previous')}</Button>
								}
							</div>
							<div className={classes.profileFormRightSide}>
								<CreateOrgForm
									user={updatedProfile}
									onSubmit={handleOnOrgSubmit}
								/>
								{matchesSm && 
									<Button color="primary" startIcon={<ChevronLeftIcon />} onClick={handleOnBackClick}>{t('previous')}</Button>
								}
							</div>
						</form>
					</>
						}
					</div>
				</Fade>
			</div>
			{!matchesSm && (
				<div className={classes.rightSide}>
					<div className={classes.rightOverlay}></div>
				</div>
			)}
		</div>
	);
};

export default CreateProfile;
