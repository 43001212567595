import React, { useEffect, useState } from 'react';
import ProfileLayout from 'components/ProfileLayout';
import UserProfileHeader from './UserProfileHeader';
import profileBackgroundImg from 'img/Profile_banner.png';
import HighlightReel from './UserHighlights';
import Teams from './Teams';
import About from './About';
import { useParams, useHistory } from 'react-router-dom';
import {  useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';

const UserProfile = ({
	profileUser,
	authUser,
	fetchProfileUser,
	showCreateOrg
}) => {
	const theme = useTheme();
	const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));

	const { t } = useTranslation();

	let { userId, tab } = useParams();
	const [user, setUser] = useState(null);
	const [activeTab, setActiveTab] = useState('highlights');
	const history = useHistory();

	useEffect(() => {
		if (userId) {
			fetchProfileUser(userId);
		} else {
			fetchProfileUser(authUser.id);
		}
	}, [userId]);
	useEffect(() => {
		setUser(profileUser);
	}, [profileUser]);
	useEffect(() => {
		if (tab) {
			setActiveTab(tab);
		} else if (userId) {
			history.replace(`/user/${userId}/highlights`);
		}
	}, [tab, matchesSm, userId]);
	const isEditable = user ? user.id === authUser.id || user.parentId === authUser.id : false;
	const handleOnTabChange = (e, tab) => {
		setActiveTab(tab);
		history.push(`/user/${user.id}/${tab}`);
	};

	return (
		<ProfileLayout
			activeTab={activeTab}
			defaultBackgroundImg={profileBackgroundImg}
			profileImageUrl={user && user.profileImgUrl}
			actionButtonText={t('follow')}
			profileHeader={<UserProfileHeader user={user} editable={isEditable} />}
			title={user ? `${user.firstName} ${user.lastName}` : ''}
			onTabChange={handleOnTabChange}
			tabs={[
				{
					value: 'highlights',
					component: (activeTab === 'highlights' && user) && <HighlightReel user={user} />
				},
				{
					value: 'teams',
					component: (activeTab === 'teams' && user) && <Teams user={user} onCreateOrgClick={showCreateOrg} />,
				},
				{
					value: 'about',
					component: ((!matchesSm || activeTab === 'about') && user) && <About user={user} />,
					hidden: !matchesSm
				}
			]}
		/>
	);
};

export default UserProfile;
