import React, { useState, useRef } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormInput from 'components/FormInput';
import ProfileImageEdit from 'components/ProfileImageEdit';
import Typography from '@material-ui/core/Typography';
import { stateOptions } from 'utils/selectOptions';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import styles from './styles';
const useStyles = makeStyles(styles);

const CreateOrgModal = ({
	show,
	onClose,
	onSubmit
}) => {
	const theme = useTheme();
	const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();
	const { t } = useTranslation();

	const [validateError, setValidateError] = useState(false);
	const [profileImg, setProfileImg] = useState(null);

	const refs = {
		name: useRef(null),
		websiteUrl: useRef(null),
		city: useRef(null),
		state: useRef(null),
		zipcode: useRef(null)
	};

	const handleOnSubmit = () => {
		const keys = Object.keys(refs);
		if (keys.filter((key) => key !== 'websiteUrl').some((key) => !refs[key].current.value)) {
			setValidateError(true);
		} else {
			let updated = {};
			keys.forEach((key) => updated[key] = refs[key].current.value);
			onSubmit({
				...updated,
				profileImg
			});
		}
	};
	return (
		<Dialog
			onClose={onClose}
			aria-labelledby="create-org-title"
			maxWidth="md"
			fullScreen={matchesSm}
			disableBackdropClick
			classes={{ paperWidthMd: classes.paperWidthMd }}
			open={show}
		>
			<DialogTitle id="create-org-title" disableTypography className={classes.dialogTitle}>
				<Typography variant="h4" component="h1" className={classes.title}>
					{t('new_organization')}
				</Typography>
			</DialogTitle>
			<DialogContent className={classes.contentWrapper}>
				<div className={classes.leftSide}>
					<div className={classes.pofileImgWrapper}>
						<ProfileImageEdit
							size="150"
							onUpdateAvatar={(imgObj) => { setProfileImg(imgObj.file); }}
							uploadTitle={t('add_organization_logo')}
							buttonLabel={t('choose_logo')}
						/>
					</div>
				</div>
				<div className={classes.rightSide}>
					<div className={classes.formWrapper}>
						<FormInput
							label={t('name_of_organization')}
							required
							id="org_name"
							errorText={t('field_required')}
							type="text"
							inputRef={refs.name}
							error={validateError && (refs.name && !refs.name.current.value)}
							classes={{ formControl: classes.name }}
						/>
						<FormInput
							label={t('organization_website')}
							id="org_website"
							type="text"
							inputRef={refs.websiteUrl}
							classes={{ formControl: classes.name }}
						/>
						<div className={classes.formRow}>
							<FormInput
								label={t('city')}
								required
								id="org-city"
								errorText={t('field_required')}
								type="text"
								inputRef={refs.city}
								error={validateError && (refs.city && !refs.city.current.value)}
								classes={{ formControl: classes.city }}
							/>
							<div className={classes.mobileFormRow}>
								<FormInput
									label={t('state')}
									select
									required
									id="org-state"
									selectOptions={stateOptions(t)}
									native
									inputRef={refs.state}
									error={validateError && (refs.state && !refs.state.current.value)}
									errorText={t('field_required')}
									classes={{ formControl: classes.state }}
								/>
								<FormInput
									label={t('zip_code')}
									required
									id="org-zip-code"
									inputRef={refs.zipcode}
									error={validateError && (refs.zipcode && !refs.zipcode.current.value)}
									errorText={t('field_required')}
									type="text"
									classes={{ formControl: classes.zipCode }}
								/>
							</div>
						</div>
					</div>
				</div>
			</DialogContent>
			<DialogActions className={classes.actionsWrapper} >
				<Button onClick={onClose} variant="outlined" className={classes.cancelBtn}>
					{t('cancel')}
				</Button>
				<Button onClick={handleOnSubmit} color="primary" variant="contained" className={classes.submitBtn}>
					{matchesSm ? t('create') : t('create_organization')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default CreateOrgModal;
