export default (theme) => ({
	createEditArticle: {
		display: 'flex',
		flexDirection: 'column',
		padding: '24px',
		[theme.breakpoints.down('sm')]: {
			padding: '16px',
			marginBottom: '84px'
		}
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		marginBottom: '30px'
	},
	actionButtons: {
		flex: 1,
		display: 'flex',
		justifyContent: 'flex-end',
		[theme.breakpoints.down('sm')]: {
			position: 'fixed',
			bottom: '0px',
			left: '0px',
			padding: '16px',
			width: '100%',
			boxShadow: '3px 0 6px 0 #00000029',
			backgroundColor: '#fff',
			zIndex: 1
		}
	},
	actionButton: {
		marginLeft: '10px',
		'&:first-child': {
			marginLeft: 0
		}
	},
	formWrapper: {
		display: 'flex',
		flexDirection: 'column'
	},
	title: {
		width: '50%',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	date: {
		width: '176px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	deleteButton: {
		width: '167px',
		backgroundColor: theme.palette.error.main,
		color: '#fff',
		'&:hover': {
			backgroundColor: theme.palette.error.dark
		}
	}
});
