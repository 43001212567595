import React, { useEffect, useState, useRef } from 'react';
import ProfileLayout from 'components/ProfileLayout';
import { useParams, useHistory } from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';
import TeamHighlights from './TeamHighlights';
import KMenu from 'components/KMenu';
import TeamSettings from './TeamSettings';
import TeamAbout from './TeamAbout';
import Toast from 'components/Toast';
import teamBackgroundImg from 'img/Team_banner.png';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styles from './styles';
import TeamRoster from './TeamRoster';
import TeamProfileHeader from './TeamProfileHeader';
import Article from '../Article';

const useStyles = makeStyles(styles);
const TeamProfile = ({
	profileTeam,
	fetchTeam,
	updateTeamSuccess,
	clearTeamOrgSuccess,
	acceptInviteSuccess,
	resetSuccess
}) => {
	const theme = useTheme();
	const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();
	const { t } = useTranslation();
	const history = useHistory();
	let { teamId, tab } = useParams();
	const [team, setTeam] = useState(null);
	const [activeTab, setActiveTab] = useState('highlights');
	const [showSettingsMenu, setShowSettingsMenu] = useState(false);
	const settingsButtonRef = useRef(null);

	useEffect(() => {
		if ((!profileTeam && teamId) || (teamId !== profileTeam.id)) {
			fetchTeam(teamId);
		} else if (profileTeam.id === teamId) {
			setTeam(profileTeam);
		}
	}, [teamId, profileTeam]);
	useEffect(() => {
		if (tab) {
			if(tab === 'article') {
				setActiveTab('highlights');
			} else {
				setActiveTab(tab);
			}
		} else {
			history.replace(`/team/${teamId}/highlights`);
		}
	}, [tab]);
	useEffect(() => {
		if (updateTeamSuccess) {
			setToastProps({
				open: true,
				message: t('team_info_saved'),
				severity: 'success'
			});
			clearTeamOrgSuccess();
		}
	}, [updateTeamSuccess]);
	useEffect(() => {
		if (acceptInviteSuccess && profileTeam) {
			setToastProps({
				open: true,
				message: t('team_invite_accepted', {teamName: profileTeam.name}),
				severity: 'success'
			});
			resetSuccess();
		}
	}, [acceptInviteSuccess, profileTeam]);
	const [toastProps, setToastProps] = useState({
		open: false,
		message: '',
		severity: null
	});
	const settingsMenuItems = [
		{
			title: 'settings',
			items: [
				{
					label: 'team_settings',
					to: `/team/${teamId}/settings`
				}
			]
		},
	];
	const onToastClose = () => {
		setToastProps({
			open: false,
			message: '',
			severity: ''
		});
	};
	const handleOnTabChange = (e, tab) => {
		setActiveTab(tab);
		history.push(`/team/${teamId}/${tab}`);
	};
	const handleOnCloseSettings = () => {
		history.push(`/team/${teamId}/highlights`);
	};

	const handleOnSettingsButtonClick = () => {
		setShowSettingsMenu(true);
	};
	const getHighlightsComponent = () => {
		if(tab === 'highlights' && activeTab === 'highlights') {
			return (<TeamHighlights isOrgTeamAdmin={team.isAdmin} />);
		} else if(tab === 'article' && activeTab === 'highlights') {
			return (<Article isAdmin={team.isAdmin} backUrl={`/team/${teamId}/highlights`} />);
		}
		return null;
	};
	return (
		<>
			<ProfileLayout
				activeTab={activeTab}
				defaultBackgroundImg={teamBackgroundImg}
				title={team && team.name}
				profileImageUrl={team && team.profileImgUrl}
				actionButtonText={t('follow')}
				onSettingsButtonClick={team && team.isAdmin && handleOnSettingsButtonClick}
				settingsButtonRef={settingsButtonRef}
				backButtonTo={team && `/organization/${team.orgId}/teams`}
				backButtonText={t('back_to_org')}
				onTabChange={handleOnTabChange}
				profileHeader={team && <TeamProfileHeader team={team} editable={(activeTab !== 'settings') && team.isAdmin} />}
				tabs={[
					{
						value: 'highlights',
						component: (activeTab === 'highlights' && team) && getHighlightsComponent()
					},
					{
						value: 'roster',
						component: (activeTab === 'roster' && team) && <TeamRoster team={team} isOrgTeamAdmin={team.isAdmin} />
					},
					{
						hidden: true,
						value: 'settings',
						component: (activeTab === 'settings' && team) && <TeamSettings team={team} onCancel={handleOnCloseSettings} />
					},
					{
						value: 'about',
						component: ((!matchesSm || activeTab === 'about') && team) && <TeamAbout team={team} />,
						hidden: !matchesSm
					}
				]}
			/>
			<KMenu
				id="team-settings-menu"
				anchorEl={settingsButtonRef.current}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				marginThreshold={0}
				classes={{ paper: classes.mainMenuPaper }}
				TransitionComponent={Collapse}
				open={showSettingsMenu}
				onClose={() => { setShowSettingsMenu(false); }}
				title={team && team.name}
				menuItems={settingsMenuItems}
			/>
			<Toast
				{...toastProps}
				onClose={onToastClose}
			/>
		</>
	);
};

export default TeamProfile;
