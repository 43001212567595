export default (theme) => ({
	root: {
		display: 'flex',
		width: '100%',
		height: '100%',
		backgroundColor: '#fff'
	},
	leftSide: props => ({
		display: 'flex',
		position: 'relative',
		maxWidth: '559px',
		backgroundImage: `url(${props.splashImg})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'bottom center',
		backgroundSize: 'cover',
		flexGrow: 1
	}),
	leftOverlay: {
		width: '100%',
		height: '100%',
		position: 'absolute',
		zIndex: 2,
		opacity: 0.3,
		background: 'linear-gradient(to bottom, #0059ff 1%, #6b17f2)'
	},
	rightSide: {
		display: 'flex',
		flexDirection: 'column',
		paddingLeft: '47px',
		paddingRight: '47px',
		flex: 1,
		width: '100%',
		paddingBottom: '40px',
		overflow: 'auto'
	},
	logoWrapper: {
		marginTop: '47px'
	},
	formWrapper: {
		marginTop: '24px',
		alignSelf: 'center',
		maxWidth: '536px',
		[theme.breakpoints.down('sm')]: {
			maxWidth: '100%'
		}
	},
	errorMsgWrapper: {
		height: '50px',
		marginBottom: '20px',
		width: '100%'

	},

});
