import React, { useRef } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormInput from 'components/FormInput';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
const useStyles = makeStyles(styles);

const EditTeamMemberModal = ({
	onClose,
	teamMember,
	show,
	onSubmit
}) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const refs = {
		role:  useRef(null),
		isAdmin:  useRef(null)
	};

	const memberOptions = () => teamMember.isChild ?
		<option key='player' value='player'>{t('player')}</option>
		: (
			<>
				<option key='player' value='player'>{t('player')}</option>
				<option key='coach' value='coach'>{t('coach')}</option>
				<option key='assistant_coach' value='assistant_coach'>{t('assistant_coach')}</option>
				<option key='manager' value='manager'>{t('manager')}</option>
				<option key='parent' value='parent'>{t('parent')}</option>
			</>
		);
	const handleOnSaveClick = () => {
		onSubmit({
			id: teamMember.id,
			role: refs.role.current.value,
			isAdmin: refs.isAdmin.current.checked
		});
	};

	return (
		<Dialog
			onClose={onClose}
			aria-labelledby="add-org-member-title"
			maxWidth="sm"
			disableBackdropClick
			classes={{ paperWidthSm: classes.paperWidthSm }}
			open={show}
		>
			<DialogTitle id="add-org-member-title" disableTypography className={classes.titleWrapper}>
				<Typography variant="h5" component="h1" className={classes.title}>
					{t('edit_team_member_role')}
				</Typography>
				<Typography variant="h5" color="textSecondary" className={classes.subText}>
					{`${teamMember.firstName} ${teamMember.lastName}`}
				</Typography>
			</DialogTitle>
			<DialogContent className={classes.contentWrapper}>
				<FormInput
					label={t('role')}
					select
					selectOptions={memberOptions()}
					native
					dense
					defaultValue={teamMember.role}
					inputRef={refs.role}
					classes={{ formControl: classes.role }}
				/>
				<FormControlLabel
					className={classes.checkbox}
					control={
						<Checkbox
							name="isAdmin"
							inputRef={refs.isAdmin}
							defaultChecked={teamMember.isAdmin}
							color="primary"
						/>
					}
					label={t('team_admin')}
				/>
			</DialogContent>
			<DialogActions className={classes.actionsWrapper} >
				<Button onClick={onClose} variant="outlined" className={classes.cancelBtn}>
					{t('cancel')}
				</Button>
				<Button onClick={handleOnSaveClick} color="primary" variant="contained" className={classes.submitBtn}>
					{t('save')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default EditTeamMemberModal;
