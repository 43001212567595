import { createSelector } from 'reselect';
import {DateTime} from 'luxon';

const getArticleFromState = state => state.article;

export const getArticle = createSelector(
	[getArticleFromState], (article) => ({
		...article,
		date: DateTime.fromMillis(article.date).toLocaleString(DateTime.DATE_FULL)
	}));
export const getEditArticle = createSelector(
	[getArticleFromState], (article) => ({
		...article,
		date: DateTime.fromMillis(article.date).toISODate()
	}));
export const getArticleCardsForEntity = createSelector(
	[state => state.entity],
	(entity) => (entity && entity.articles) && entity.articles.map((article) => ({
		to: `article/${article.id}`,
		id: article.id,
		name: entity.name,
		title: article.title,
		body: article.body,
		date: Math.abs(DateTime.fromMillis(article.updatedDate).diffNow().as('days')) > 1 ?
			DateTime.fromMillis(article.updatedDate).toLocaleString(DateTime.DATE_FULL) :
			DateTime.fromMillis(article.updatedDate).toRelative()
	}))
);
const getEntityNameFromUser = (user, entityId) => {
	const team = user.teams.find((team) => team.id === entityId);
	if(team) {
		return team.name;
	} else {
		const org = user.organizations.find((org) => org.id === entityId);
		if(org) {
			return org.name;
		}
	}
	return '';
};
export const getArticleCardsForUser = createSelector(
	[state => state.user],
	(user) => (user && user.articles) && user.articles.map((article) => ({
		to: `${article.type === 'team' ? '/team' : '/organization'}/${article.entityId}/article/${article.id}`,
		id: article.id,
		name: getEntityNameFromUser(user, article.entityId),
		title: article.title,
		body: article.body,
		date: Math.abs(DateTime.fromMillis(article.updatedDate).diffNow().as('days')) > 1 ?
			DateTime.fromMillis(article.updatedDate).toLocaleString(DateTime.DATE_FULL) :
			DateTime.fromMillis(article.updatedDate).toRelative()
	}))
);
