import { connect } from 'react-redux';
import flow from 'lodash.flow';
import InvitationHandler from './InvitationHandler';
import { signOutAuthUser } from 'reactive-core';


export default flow(
	connect(
		() => ({
		}),
		(dispatch) => ({
			signOutAuthUser: () => dispatch(signOutAuthUser()),
		})
	)
)(InvitationHandler);

