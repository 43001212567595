import { connect } from 'react-redux';
import flow from 'lodash.flow';
import OrgSettings from './OrgSettings';
import {
	updateOrganization
} from 'reactive-core';

export default flow(
	connect(
		({
			authUser: {user},
			uievents: {
				updateOrgSuccess,
				updatingOrgProfile
			},
			profile: {organization}
		}) => ({
			authUser: user,
			organization,
			updateOrgSuccess,
			updatingOrgProfile
		}),
		(dispatch) => ({
			onSubmit: (orgData) => dispatch(updateOrganization(orgData))
		})
	)
)(OrgSettings);
