import React from 'react';
import Typography from '@material-ui/core/Typography';
import SportsSelect from 'components/MultiSelect';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
const useStyles = makeStyles(styles);

const About = ({
	user
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const hasStats = user && (user.height || user.weight);
	const getInfoRow = (id, label, value) => (
		<div key={id} className={classes.infoWrapper}>
			<label htmlFor={id}>
				<Typography variant="h6" className={classes.infoLabel}>{`${label}:`}</Typography>
			</label>
			<Typography id={id} variant="h6" className={classes.infoValue}>{value}</Typography>
		</div>
	);
	return (
		<div className={classes.about}>
			{(user && user.bio) && 
				<div className={classes.infoSection}>
					<Typography variant="h6" className={classes.sectionLabel}>{t('bio')}</Typography>
					<Typography variant="body1" className={classes.bioText} >{user.bio}</Typography>
				</div>
			}
			{hasStats &&
				<div className={classes.infoSection}>
					<Typography variant="h6" className={classes.sectionLabel}>{t('stats')}</Typography>
					{user.height &&
						getInfoRow('userHeight', t('height'), user.height.in ? `${user.height.ft}'${user.height.in}"` : `${user.height.ft}'`)
					}
					{user.weight &&
						getInfoRow('userWeight', t('weight'), `${user.weight} ${t('lbs')}.`)
					}
				</div>
			}
			{user && 
				<div className={classes.infoSection}>
					<Typography variant="h6" className={classes.sectionLabel}>{t('contact')}</Typography>
					{getInfoRow('userEmail', t('email'), user.email)}
					{user.phone &&
						getInfoRow('userPhone', t('phone'), user.phone)
					}
					{user.city &&
						getInfoRow('userCity', t('city'), user.city)
					}
					{user.state &&
						getInfoRow('userState', t('state'), user.state)
					}
				</div>
			}
			{(user && user.sports.length) ?
				<div className={classes.infoSection}>
					<Typography variant="h6" className={classes.sectionLabel}>{t('sport_plural')}</Typography>
					<SportsSelect
						selectedItems={user.sports.map((sport) => ({
							key: sport,
							removeable: false,
							value: sport
						}))}
					/>
				</div>
				: null
			}
		</div>
	);
};
export default About;
