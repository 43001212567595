import React from 'react';
import Typography from '@material-ui/core/Typography';
import {
	Link as RouterLink,
} from 'react-router-dom';
import { ReactComponent as PencilIcon } from 'icons/Pencil_edit_icon.svg';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
const useStyles = makeStyles(styles);

const OrgProfileHeader = ({
	org,
	editable
}) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.orgProfileHeader}>
			<Typography variant="h5" className={classes.name}>{org.name}</Typography>
			<Typography variant="h6" className={classes.location}>{`${org.city}, ${org.state}`}</Typography>
			{org.websiteUrl && <Link href={org.websiteUrl} variant="body2">{t('organization_website')}</Link>}
			{editable &&
				<Button
					className={classes.editProfileBtn}
					component={RouterLink}
					color="primary"
					startIcon={<PencilIcon />}
					to={`/organization/${org.id}/settings`}
				>{t('edit_org_info')}</Button>
			}
		</div>
	);
};

export default OrgProfileHeader;
