import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import {ReactComponent as Logo} from 'img/Kinectem_logo.svg';
const useStyles = makeStyles(styles);

const LogoHeading = ({size}) => {
	let logoSize =  '';
	let fontSize = '';
	switch(size) {
	case 'lg': 
		logoSize = '75px';
		fontSize= '40px';
		break;
	case 'md': 
		logoSize = '60px';
		fontSize= '32px';
		break;
	default: {
		logoSize =  '50px';
		fontSize = '25px';
	}
	} 
	const classes = useStyles({logoSize, fontSize});

	return (
		<div className={classes.root}>
			<Logo className={classes.logo} />
			<span className={classes.logoText}>Kinectem</span>
		</div>
	);
};

export default LogoHeading;
