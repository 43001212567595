export default (theme) => ({
	root: {
		width: '100%',
		top: '0px',
		[theme.breakpoints.down('sm')]: {
			left: '0px'
		}
	},
	hide: {
		display: 'none'
	},
	alert: {
		width: '100%',
		borderRadius: '0px',
		height: '58px',
		alignItems: 'center',
		'& .MuiSvgIcon-root': {
			fontSize: '30px'
		}
	}
});
