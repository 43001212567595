export default (theme) => ({
	paperWidthSm: {
		[theme.breakpoints.up('sm')]: {
			minWidth: '466px',
			maxWidth: '466px'
		}
	},
	titleWrapper: {
		padding: '20px 36px 20px 36px',
		[theme.breakpoints.down('sm')]: {
			padding: '16px'
		}
	},
	contentWrapper: {
		display: 'flex',
		flexDirection: 'column',
		paddingLeft: '68px',
		paddingRight: '68px',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			padding: '16px'
		}
	},
	actionsWrapper: {
		marginBottom: '50px',
		[theme.breakpoints.down('sm')]: {
			margin: '8px'
		}
	},
	userWrapper: {
		display: 'flex',
		alignItems: 'center',
		alignSelf: 'flex-start'
	},
	avatar: {
		width: '60px',
		height: '60px',
		marginRight: '21px'
	},
	userName: {
		fontSize: '18px'
	},
	cancelBtn: {
		paddingLeft: '46px',
		paddingRight: '46px'
	},
	submitBtn: {
		paddingLeft: '39px',
		paddingRight: '39px'
	},
	whistleIcon: {
		marginBottom: '30px',
		marginTop: '60px'
	},
	closeAccountMsg: {
		fontSize: '18px',
		marginTop: '16px',
		marginBottom: '20px'
	},
	clostAccountQuest: {
		fontSize: '32px',
		marginBottom: '28px'
	}
});
