import { connect } from 'react-redux';
import flow from 'lodash.flow';
import UserProfile from './UserProfile';
import WithResourceNotFound from 'components/WithResourceNotFound';
import {fetchProfileUser, showCreateOrg, clearResourceNotFound} from 'reactive-core';

export default flow(
	connect(
		({
			authUser: { user },
			uievents: {resourceNotFound},
			profile: {user: profileUser}
		}) => ({
			authUser: user,
			profileUser,
			resourceNotFound
		}),
		(dispatch) => ({
			fetchProfileUser: (id) => dispatch(fetchProfileUser(id)),
			showCreateOrg: () => dispatch(showCreateOrg()),
			clearResourceNotFound: () => dispatch(clearResourceNotFound())
		})
	)
)(WithResourceNotFound(UserProfile));
