import { connect } from 'react-redux';
import flow from 'lodash.flow';
import OrganizationProfile from './OrganizationProfile';
import {
	fetchOrganization,
	clearResourceNotFound,
	followOrg,
	clearTeamOrgSuccess,
	resetSuccess
} from 'reactive-core';
import WithResourceNotFound from 'components/WithResourceNotFound';

export default flow(
	connect(
		({
			authevents: { acceptInviteSuccess },
			authUser: { user },
			uievents: { createdOrgId, updateOrgSuccess, resourceNotFound },
			profile: { organization },
		}) => ({
			authUser: user,
			profileOrg: organization,
			createdOrgId,
			updateOrgSuccess,
			resourceNotFound,
			acceptInviteSuccess
		}),
		(dispatch) => ({
			fetchOrg: (id) => dispatch(fetchOrganization(id)),
			clearResourceNotFound: () => dispatch(clearResourceNotFound()),
			followOrg: (id, action) => dispatch(followOrg({ id, action })),
			clearTeamOrgSuccess: () => dispatch(clearTeamOrgSuccess()),
			resetSuccess: () => dispatch(resetSuccess())
		})
	)
)(WithResourceNotFound(OrganizationProfile));

