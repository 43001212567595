import React, { useState, useRef } from 'react';
import FormInput from 'components/FormInput';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { stateOptions } from 'utils/selectOptions';
import styles from './styles';
const useStyles = makeStyles(styles);

const CreateOrgForm = ({ user, onSubmit }) => {
	const theme = useTheme();
	const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();
	const { t } = useTranslation();

	const refs = {
		name: useRef(null),
		city: useRef(null),
		state: useRef(null),
		zipcode: useRef(null)
	};
	const [validateError, setValidateError] = useState(false);

	const handleOnSubmitClick = () => {
		const keys = Object.keys(refs);
		if (keys.some((key) => !refs[key].current.value)) {
			setValidateError(true);
		} else {
			let updated = {};
			keys.forEach((key) => updated[key] = refs[key].current.value);
			onSubmit(updated);
		}
	};
	return (
		<>
			<FormInput
				label={t('name_of_org')}
				required
				id="org-name"
				fullWidth
				errorText={t('field_required')}
				type="text"
				inputRef={refs.name}
				error={validateError && (refs.name && !refs.name.current.value)}
				classes={{ formControl: classes.cityField }}
			/>
			<FormInput
				label={t('city')}
				required
				id="profile-city"
				fullWidth
				errorText={t('field_required')}
				type="text"
				inputRef={refs.city}
				defaultValue={user && user.city}
				error={validateError && (refs.city && !refs.city.current.value)}
				classes={{ formControl: classes.cityField }}
			/>
			<div className={classes.stateZipWrapper}>
				<FormInput
					label={t('state')}
					select
					required
					selectOptions={stateOptions(t, user && user.state)}
					native
					errorText={t('field_required')}
					defaultValue={user && user.state}
					inputRef={refs.state}
					error={validateError && (refs.state && !refs.state.current.value)}
					classes={{ formControl: classes.stateZipFields }}
				/>
				<FormInput
					label={t('zip_code')}
					required
					id="profile-zip-code"
					fullWidth
					errorText={t('field_required')}
					type="text"
					defaultValue={user && user.zipcode}
					inputRef={refs.zipcode}
					error={validateError && (refs.zipcode && !refs.zipcode.current.value)}
					classes={{ formControl: classes.stateZipFields }}
				/>
			</div>
			<div className={classes.actionWrapper}>
				<Button variant="contained" color="primary" fullWidth={matchesSm} className={classes.submitButton} onClick={handleOnSubmitClick}>{t('finish')}</Button>
			</div>
		</>
	);
};

export default CreateOrgForm;
