import React, { useEffect, useState, useRef } from 'react';
import ProfileLayout from 'components/ProfileLayout';
import { useParams, useHistory } from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';
import KMenu from 'components/KMenu';
import OrgSettings from './OrgSettings';
import orgBackgroundImg from 'img/Org_banner.png';
import Toast from 'components/Toast';
import OrgProfileHeader from './OrgProfileHeader';
import OrgAbout from './OrgAbout';
import OrgTeams from './OrgTeams';
import OrgMembers from './OrgMembers';
import OrgHighlights from './OrgHighlights';
import Article from 'containers/Article';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import styles from './styles';



const useStyles = makeStyles(styles);
const OrganizationProfile = ({
	profileOrg,
	fetchOrg,
	updateOrgSuccess,
	clearTeamOrgSuccess,
	acceptInviteSuccess,
	resetSuccess
}) => {
	const theme = useTheme();
	const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();
	const { t } = useTranslation();
	const history = useHistory();
	let { orgId, tab } = useParams();
	const [org, setOrg] = useState(null);
	const [activeTab, setActiveTab] = useState('highlights');
	const [showSettingsMenu, setShowSettingsMenu] = useState(false);
	const settingsButtonRef = useRef(null);
	useEffect(() => {
		if ((!profileOrg && orgId) || (orgId !== profileOrg.id)) {
			fetchOrg(orgId);
		} else if (profileOrg.id === orgId) {
			setOrg(profileOrg);
		}
		
	}, [orgId, profileOrg]);
	useEffect(() => {
		if (tab) {
			if(tab === 'article') {
				setActiveTab('highlights');
			} else {
				setActiveTab(tab);
			}
		} else {
			history.replace(`/organization/${orgId}/highlights`);
		}
	}, [tab]);

	useEffect(() => {
		if (updateOrgSuccess) {
			setToastProps({
				open: true,
				message: t('organization_info_saved'),
				severity: 'success'
			});
			clearTeamOrgSuccess();
		}
	}, [updateOrgSuccess]);
	useEffect(() => {
		if (acceptInviteSuccess && profileOrg) {
			setToastProps({
				open: true,
				message: t('org_invite_accpeted', {orgName: profileOrg.name}),
				severity: 'success'
			});
			resetSuccess();
		}
	}, [acceptInviteSuccess, profileOrg]);
	const [toastProps, setToastProps] = useState({
		open: false,
		message: '',
		severity: null
	});
	const settingsMenuItems = [
		{
			title: 'settings',
			items: [
				{
					label: 'organization_settings',
					to: `/organization/${orgId}/settings`,
				},
			],
		},
		{
			title: 'users',
			items: [
				{
					label: 'manage_users',
					to: `/organization/${orgId}/members`,
				},
			],
		},
	];
	const handleOnSettingsButtonClick = () => {
		setShowSettingsMenu(true);
	};
	const onToastClose = () => {
		setToastProps({
			open: false,
			message: '',
			severity: ''
		});
	};
	const handleOnCloseSettings = () => {
		history.push(`/organization/${orgId}/highlights`);
	};
	const handleOnTabChange = (e, tab) => {
		setActiveTab(tab);
		history.push(`/organization/${orgId}/${tab}`);
	};
	const getHighlightsComponent = () => {
		if(tab === 'highlights' && activeTab === 'highlights') {
			return (<OrgHighlights isAdmin={org.isAdmin} />);
		} else if(tab === 'article' && activeTab === 'highlights') {
			return (<Article isAdmin={org.isAdmin} backUrl={`/organization/${orgId}/highlights`} />);
		}
		return null;
	};
	return (
		<div className={classes.dashboard}>
			<ProfileLayout
				activeTab={activeTab}
				defaultBackgroundImg={orgBackgroundImg}
				title={org && org.name}
				profileImageUrl={org && org.profileImgUrl}
				profileHeader={org && <OrgProfileHeader org={org} editable={org.isAdmin} />}
				onSettingsButtonClick={(org && org.isAdmin) && handleOnSettingsButtonClick}
				actionButtonText={(org && org.isFollowing) ? t('following') : t('follow')}
				settingsButtonRef={settingsButtonRef}
				onTabChange={handleOnTabChange}
				tabs={[
					{
						value: 'highlights',
						component: (activeTab === 'highlights' && org) && getHighlightsComponent()
					},
					{
						value: 'teams',
						component: (activeTab === 'teams' && org) && <OrgTeams org={org} isOrgAdmin={org.isAdmin} />
					},
					{
						hidden: true,
						value: 'settings',
						component: (activeTab === 'settings' && org) && <OrgSettings onCancel={handleOnCloseSettings} />,
					},
					{
						hidden: true,
						value: 'members',
						component: (activeTab === 'members' && org) && <OrgMembers onCancel={handleOnCloseSettings} />,
					},
					{
						value: 'about',
						component: ((!matchesSm || activeTab === 'about') && org) && <OrgAbout org={org} />,
						hidden: !matchesSm
					}
				]}
			/>
			<KMenu
				id="org-settings-menu"
				anchorEl={settingsButtonRef.current}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				marginThreshold={0}
				classes={{ paper: classes.mainMenuPaper }}
				TransitionComponent={Collapse}
				open={showSettingsMenu}
				onClose={() => { setShowSettingsMenu(false); }}
				title={org && org.name}
				menuItems={settingsMenuItems}
			/>
			<Toast
				{...toastProps}
				onClose={onToastClose}
			/>

		</div>
	);
};

export default OrganizationProfile;
