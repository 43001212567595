export default (theme) => ({
	orgMembers: {
		display: 'flex',
		flexDirection: 'column',
		padding: '25px',
		[theme.breakpoints.down('sm')]: {
			padding: '16px',
			marginBottom: '84px',
		}
	},
	actionButtons: {
		display: 'flex',
		justifyContent: 'flex-end',
		[theme.breakpoints.down('sm')]: {
			position: 'fixed',
			bottom: '0px',
			left: '0px',
			padding: '16px',
			width: '100%',
			boxShadow: '3px 0 6px 0 #00000029',
			backgroundColor: '#fff',
			zIndex: 1
		}
	},
	cancelBtn: {
		marginRight: '14px',
		width: '141px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	addTeamMemberButton: {
		width: '210px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		}
	},
	emptyAddTeamMemberButton: {
		minWidth: '167px',
		marginTop: '20px'
	},
	listWrapper: {
		marginTop: '20px'
	}
});
