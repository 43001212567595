import React, { useState, useRef } from 'react';
import FormInput from 'components/FormInput';
import ProviderLogin from 'containers/ProviderLogin';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Trans } from 'react-i18next';
import {
	Link as RouterLink,
	useHistory
} from 'react-router-dom';
import Link from '@material-ui/core/Link';
const SignUpForm = ({
	t,
	classes,
	onSubmitClick
}) => {
	const history = useHistory();
	const [state, setState] = useState({
		emailError: false,
		passwordError: false,
		firstNameError: false,
		lastNameError: false
	});
	const refs = {
		email: useRef(null),
		password: useRef(null),
		firstName: useRef(null),
		lastName: useRef(null)
	};

	const validateFields = () => {
		let retVal = true;
		const validateList = [
			{ key: 'emailError', value: refs.email.current.value },
			{ key: 'passwordError', value: refs.password.current.value },
			{ key: 'firstNameError', value: refs.firstName.current.value },
			{ key: 'lastNameError', value: refs.lastName.current.value }
		];
		let newState = { ...state };
		validateList.forEach((field) => {
			if (field.value.trim() === '') {
				newState = {
					...newState,
					[field.key]: true
				};
				retVal = false;
			}
		});
		setState(newState);
		return retVal;
	};
	const handleOnSubmitClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (validateFields()) {
			onSubmitClick({
				email: refs.email.current.value,
				password: refs.password.current.value,
				firstName: refs.firstName.current.value,
				lastName: refs.lastName.current.value
			});
		}
	};

	return (
		<form className={classes.loginForm} onSubmit={handleOnSubmitClick} noValidate>
			<div className={classes.formRow}>
				<FormInput
					label={t('first_name')}
					required
					error={state.firstNameError}
					id="signup-firstname"
					fullWidth
					errorText={t('field_required')}
					type="text"
					inputRef={refs.firstName}
					classes={{ formControl: classes.firstName }}
				/>
				<FormInput
					label={t('last_name')}
					id="signup-lastname"
					required
					error={state.lastNameError}
					errorText={t('field_required')}
					fullWidth
					type="text"
					inputRef={refs.lastName}
					classes={{ formControl: classes.lastName }}
				/>
			</div>
			<FormInput
				label={t('email')}
				id="signup-email"
				required
				error={state.emailError}
				errorText={t('field_required')}
				fullWidth
				type="email"
				inputRef={refs.email}
				classes={classes}
			/>
			<FormInput
				label={t('password')}
				id="signup-password"
				required
				error={state.passwordError}
				errorText={t('field_required')}
				fullWidth
				type="password"
				inputRef={refs.password}
				classes={classes}
			/>
			<Typography className={classes.termsOfService} variant="body2">
				<Trans t={t} i18nKey="terms_of_service">
					By creating an account, you agree to Kinectem’s <Link component={RouterLink} to="/" color="primary">terms of use</Link> and <Link component={RouterLink} to="/" color="primary">privacy policy</Link>.
				</Trans>
			</Typography>
			<Button variant="contained" color="primary" type="submit" fullWidth >{t('create_account')}</Button>
			<ProviderLogin />
			<Typography className={classes.loginLinkText} variant="subtitle1">
				<Trans i18nKey="already_have_an_account">
					Already have an account? <Link component={RouterLink} to={`/sign-in${history.location.search}`} color="primary" className={classes.loginLink}>Log In</Link>
				</Trans>
			</Typography>
		</form>
	);
};
export default SignUpForm;
