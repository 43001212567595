import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import {resources} from 'reactive-core';

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: 'en',
		debug: true,
		keySeparator: false, // we do not use keys in form messages.welcome
		ns: ['kinectem','common'],
		defaultNS: 'kinectem',
		interpolation: {
			escapeValue: false // react already safes from xss
		}
	});

export default i18n;
