export default (theme) => ({
	pageNotFound: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '100px',
		[theme.breakpoints.down('sm')]: {
			padding: '16px',
			textAlign: 'center'
		}
	},
	pnfImg: {
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	notFoundText: {
		fontSize: '26px'
	},
	notFoundSubText: {
		fontSize: '18px',
		marginBottom: '60px'
	}
});
