import React, { useRef, useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import LogoHeading from 'components/LogoHeading';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ReactComponent as ArrowDownIcon } from 'icons/Down_arrow_icon.svg';
import KMenu from 'components/KMenu';
import { useTranslation } from 'react-i18next';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import styles from './styles';
const useStyles = makeStyles(styles);

const TopBar = ({ authUser, onCreateOrgClick, isSignedIn, createdOrgId }) => {
	const theme = useTheme();
	const { t } = useTranslation();
	const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();
	const [openMenu, setOpenMenu] = useState(false);
	const menuAnchor = useRef(null);
	useEffect(() => {
		if (createdOrgId) {
			setOpenMenu(false);
		}
	}, [createdOrgId]);
	const handleMenu = () => {
		setOpenMenu(true);
	};
	const handleClose = () => {
		setOpenMenu(false);
	};
	const handleonCreateOrgClick = () => {
		onCreateOrgClick();
		setOpenMenu(false);
	};
	const mainMenuItems = [
		{
			title: 'profile',
			items: [
				{
					label: 'view_profile',
					to: `/user/${authUser.id}`
				},
				{
					label: 'edit_profile',
					to: '/settings/edit-profile'
				},
				{
					label: 'children_settings',
					to: '/settings/children'
				}
			]
		},
		{
			title: 'settings',
			items: [
				{
					label: 'account_settings',
					to: '/settings/account-settings'
				}
			]
		},
		{
			title: 'organizations',
			items: [
				{
					label: 'create_organizations',
					onClick: handleonCreateOrgClick
				}
			]
		},
		{
			items: [
				{
					label: 'log_out',
					to: '/sign-out',
					classes: classes.logOut
				}
			]
		}
	];
	return (
		<>
			<AppBar position={matchesSm ? 'fixed' : 'fixed'} color="inherit" classes={{ root: classes.root }}>
				<Toolbar disableGutters>
					<Link to={isSignedIn ? '/' : 'https://kinectem.com'} className={classes.homelink}>
						<LogoHeading size="sm" />
					</Link>
					<div className={classes.rightSide}>
						{isSignedIn ?
							<>
								<div className={classes.profileButtonWrapper} ref={el => { menuAnchor.current = el; }}>
									<Avatar alt={`${authUser.firstName} ${authUser.lastName}`} onClick={handleMenu} src={authUser.profileImgUrl} className={classes.avatar} />
									{!matchesSm && <Button
										endIcon={<ArrowDownIcon className={classes.profileButtonIcon} />}
										className={classes.profileButton}
										onClick={handleMenu}
									>
										{`${authUser.firstName} ${authUser.lastName}`}
									</Button>
									}
								</div>
								<KMenu
									id="menu-appbar"
									anchorEl={menuAnchor.current}
									getContentAnchorEl={null}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'right',
									}}
									keepMounted
									transformOrigin={{
										vertical: -5,
										horizontal: matchesSm ? 315 : 'right',
									}}
									marginThreshold={0}
									classes={{ paper: classes.mainMenuPaper }}
									TransitionComponent={Collapse}
									open={openMenu}
									onClose={handleClose}
									title={`${authUser.firstName} ${authUser.lastName}`}
									menuItems={mainMenuItems}
								/>
							</>
							: 
							<Box display="flex" justifyContent="flex-end" mr="44px">
								<Button variant="outlined" component={Link} to="/sign-up" className={classes.signUp}>{t('join_kinectem')}</Button>
								<Button variant="contained" type="submit" component={Link} color="primary" to="/sign-in" >{t('sign_in')}</Button>
							</Box>
						}
					</div>
				</Toolbar>
			</AppBar>
			<div className={classes.offset} />
		</>
	);
};

export default TopBar;
