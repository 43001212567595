import { getRotatedImage } from 'utils/imageUtils/rotateImage';
import { getOrientation } from 'get-orientation/browser';
const ORIENTATION_TO_ANGLE = {
	'3': 180,
	'6': 90,
	'8': -90,
};
const readFile = (file)  =>
	new Promise(resolve => {
		const reader = new FileReader();
		reader.addEventListener('load', () => resolve(reader.result), false);
		reader.readAsDataURL(file);
	});

export const getFileUrl = async (file) => {
	let imageDataUrl = await readFile(file);
	// apply rotation if needed
	const orientation = await getOrientation(file);
	const rotation = ORIENTATION_TO_ANGLE[orientation];
	if (rotation) {
		imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
	}
	return imageDataUrl;
};
