import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import FormInput from 'components/FormInput';
import Toast from 'components/Toast';
import PasswordConfirmModal from 'components/PasswordConfirmModal';
import {usePrevious} from 'utils/hooks/customHooks';
import {FB_AUTH_WRONG_PASSWORD} from 'utils/constants';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import styles from './styles';
const useStyles = makeStyles(styles);

const AccountSettings = ({
	user,
	onSubmitChangeEmail,
	onSubmitChangePassword,
	onDeleteAccount,
	authErrorCode,
	emailUpdateSuccess,
	updatingEmail,
	passwordUpdateSuccess,
	updatingPassword,
	resetSuccess
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [enableEmail, setEnableEmail] = useState(false);
	const [enablePassword, setEnablePassword] = useState(false);
	const [email, setEmail] = useState(user.email);
	const [currentPassword, setCurrentPassword] = useState('');
	const [currentPasswordError, setCurrentPasswordError] = useState(null);
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [newPasswordError, setNewPasswordError] = useState(null);
	const [confirmError, setConfirmError] = useState(null);
	const [toastProps, setToastProps] = useState({
		open:  false,
		message: '',
		severity: null
	});
	const [confirmProps, setConfirmProps] = useState({
		type: null,
		subTitle: null,
		errorCode: null,
		primaryBtnText: null,
		show: false
	});
	const prevUpdatingEmail = usePrevious(updatingEmail);
	const prevUpdatingPassword = usePrevious(updatingPassword);

	useEffect(() => {
		if(emailUpdateSuccess && !updatingEmail && prevUpdatingEmail && confirmProps.type === 'email')  {
			setConfirmProps({
				type: null,
				subTitle: null,
				warning: null,
				primaryBtnText: null,
				show: false
			});
		}
	}, [emailUpdateSuccess, confirmProps, updatingEmail, prevUpdatingEmail]);
	useEffect(() => {
		if(currentPassword && newPassword && confirmPassword) {
			setEnablePassword(true);
		}
	}, [currentPassword, newPassword, confirmPassword]);
	useEffect(() => {
		if(authErrorCode && confirmProps.show) {
			setConfirmError(authErrorCode);
		} else if(authErrorCode === FB_AUTH_WRONG_PASSWORD) {
			setCurrentPasswordError(authErrorCode);
		} else {
			setNewPasswordError(authErrorCode);
		}
	}, [authErrorCode, confirmProps]);
	useEffect(() => {
		if(passwordUpdateSuccess && !updatingPassword && prevUpdatingPassword) {
			setConfirmPassword('');
			setNewPassword('');
			setCurrentPassword('');
			setNewPasswordError(null);
			setCurrentPasswordError(null);
			setToastProps({
				open:  true,
				message: t('password_changed_success'),
				severity: 'success'
			});
		}
	}, [passwordUpdateSuccess, updatingPassword, prevUpdatingPassword]);
	const emailIsValid = (email)  =>
		/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

	const handleOnEmailChange = (e) => {
		if(e.target.value !== user.email && emailIsValid(e.target.value)){
			setEnableEmail(true);
		} else {
			setEnableEmail(false);
		}
		setEmail(e.target.value);
	};
	const handleOnEmailChangeClick = () => {
		resetSuccess();
		setConfirmError(null);
		setConfirmProps({
			type: 'email',
			subTitle: t('please_enter_password_for_email_change'),
			primaryBtnText: t('change_email_address'),
			show: true
		});
		
	};
	const handleOnCloseAccountClick = () => {
		resetSuccess();
		setConfirmError(null);
		setConfirmProps({
			type: 'closeAccount',
			subTitle: t('please_enter_password_to_close_account'),
			showCloseAccount: true,
			primaryBtnText: t('close_account'),
			show: true
		});
	};
	const onPasswordConfirmSubmitClick = (password) => {
		if(confirmProps.type === 'email'){
			onSubmitChangeEmail({id: user.id, email, password});
		} else {
			onDeleteAccount({id: user.id, password});
		}
	};
	const onPasswordConfirmClose = () => {
		resetSuccess();
		setConfirmProps({
			type: null,
			subTitle: null,
			warning: null,
			primaryBtnText: null,
			show: false
		});
	};
	const onToastClose = () => {
		setToastProps({
			open:  false,
			message: '',
			severity: ''
		});
	};
	const handleOnPasswordChangeClick = () => {
		setNewPasswordError(null);
		setCurrentPasswordError(null);
		if(newPassword === confirmPassword) {
			setNewPasswordError(null);
			onSubmitChangePassword({currentPassword, newPassword});
		} else {
			setNewPasswordError('passwords_dont_match');
		}
	};
	return (
		<div className={classes.accountSettings}>
			<div className={classes.formWrapper} >
				<Typography variant="h6" className={classes.sectionTitle}>{t('email_and_password')}</Typography>
				<Divider className={classes.divider} variant="fullWidth"/>
				<FormInput
					label={t('email')}
					id="email"
					fullWidth
					errorText={t('field_required')}
					type="email"
					defaultValue={user.email}
					onChange={handleOnEmailChange}
					classes={{formControl: classes.emailPasswordInput}}
				/>
				<Button
					variant="contained"
					color="primary"
					disabled={!enableEmail}
					className={classes.submitButton}
					onClick={handleOnEmailChangeClick}
				>{t('change_email_address')}</Button>
				<div className={classes.passwordWrapper}>
					<FormInput
						label={t('current_password')}
						id="current_password"
						fullWidth
						error={currentPasswordError}
						value={currentPassword}
						errorText={t('password_incorrect')}
						type="password"
						onChange={(e) => {setCurrentPassword(e.target.value);}}
						classes={{formControl: classes.emailPasswordInput}}
					/>
					<FormInput
						label={t('new_password')}
						id="new_password"
						fullWidth
						error={newPasswordError}
						autocomplete="off"
						type="password"
						value={newPassword}
						onChange={(e) => {setNewPassword(e.target.value);}}
						classes={{formControl: classes.emailPasswordInput}}
					/>
					<FormInput
						label={t('confirm_password')}
						id="confirm_password"
						fullWidth
						autocomplete="off"
						error={newPasswordError}
						errorText={t(newPasswordError)}
						type="password"
						value={confirmPassword}
						onChange={(e) => {setConfirmPassword(e.target.value);}}
						classes={{formControl: classes.emailPasswordInput}}
					/>
					<Button
						variant="contained"
						color="primary"
						disabled={!enablePassword}
						className={classes.submitButton}
						onClick={handleOnPasswordChangeClick}
					>{t('change_password')}</Button>
				</div>
				<Typography variant="h6" className={classes.sectionTitle}>{t('account_changes')}</Typography>
				<Divider className={classes.divider} variant="fullWidth"/>
				<Typography variant="body1" className={classes.closeAccountMsg}>{t('closing_your_account')}</Typography>
				<Button
					variant="contained"
					className={clsx(classes.submitButton, classes.closeAccountBtn)}
					onClick={handleOnCloseAccountClick}
				>{t('close_account')}</Button>
			</div>
			<PasswordConfirmModal
				{...confirmProps}
				errorCode={confirmError}
				onClose={onPasswordConfirmClose}
				onSubmit={onPasswordConfirmSubmitClick}
			/>
			<Toast
				{...toastProps}
				onClose={onToastClose}
			/>
		</div>
	);
};

export default AccountSettings;
