export default (theme) => ({
	editProfile: {
		display: 'flex',
		marginLeft: '58px',
		marginTop: '50px',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			margin: '0px',
			padding: '16px'
		}
	},
	formWrapper: {
		marginLeft: '52px',
		width: '808px',
		display: 'flex',
		flexDirection: 'column',
		paddingBottom: '40px',
		[theme.breakpoints.down('sm')]: {
			margin: '0px',
			marginTop: '10px',
			width: '100%',
		}
	},
	formRow: {
		[theme.breakpoints.up('sm')]: {
			display: 'flex',
			alignItems: 'flex-end'
		}
	},
	mobileFormRow: {
		display: 'flex',
		alignItems: 'flex-end'
	},
	name: {
		[theme.breakpoints.up('sm')]: {
			'&:last-child': {
				marginLeft: '16px'
			}
		}
	},
	divider: {
		marginBottom: '40px'
	},
	manageTeamMessage: {
		marginTop: '20px'
	},
	medWidthFields: {
		width: '176px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	halfWidthFields: {
		width: '50%',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	minWidthFields: {
		width: '100px',
		'&:not(:first-child)': {
			marginLeft: '30px',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	cityField: {
		width: '378px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	stateZipFields: {
		width: '176px',
		marginLeft: '30px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			margin: '0px',
			'&:not(:first-child)': {
				marginLeft: '30px',
			},
		}
	},
	submitButton: {
		padding: '11px 43px',
		width: 'fit-content',
		marginTop: '48px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	}
});
