import React, {useState, useRef} from 'react'; 
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import clsx from 'clsx';
import { ButtonBase } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {ReactComponent as CameraIcon}  from 'icons/Camera_icon.svg';
import Avatar from '@material-ui/core/Avatar';
import ImageUpload from 'components/ImageUpload';
import {getFileUrl} from 'utils/imageUtils/fileHelper';
const useStyles = makeStyles(styles);

const ProfileImageEdit = ({
	avatarImageUrl,
	onUpdateAvatar,
	showIcon,
	size,
	uploadTitle,
	buttonLabel
}) => {
	const classes = useStyles({size});
	const inputEl = useRef(null);
	const [updatedAvatarImg, setUpdateAvatarImg] = useState(avatarImageUrl);
	const [imageUploadProps, setImageUploadProps] = useState({
		title: uploadTitle,
		image: null,
		show: false,
		cropShape: 'rect',
		aspect: 4 / 1,
		activeUpload: 'bg' 
	});

	const handleImageUploadClose = () => {
		setImageUploadProps({...imageUploadProps, show: false});
	};
	const handleOnApply = (rawUploadedImg, updatedImg) => {
		setUpdateAvatarImg(updatedImg.url);
		onUpdateAvatar(updatedImg);
		handleImageUploadClose();
	};
	const handleOnEditClick = () => {
		inputEl.current.click();
	};
	const onFileChange = async (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const image = await getFileUrl(e.target.files[0]);
			
			setImageUploadProps({
				title: uploadTitle,
				image,
				show: true,
				cropShape: 'round',
				aspect: 1,
				activeUpload: 'avatar' 
			});
		}
	};
	return (
		<div className={classes.ProfileImageEdit}>
			<div className={clsx(classes.avatarWrapper, {[classes.avatarWrapperWithIcon]:(showIcon && updatedAvatarImg) })}>
				<Avatar 
					src={updatedAvatarImg} 
					className={clsx(classes.avatar, {
						[classes.emptyAvatar]: !updatedAvatarImg,
					})}
					onClick={handleOnEditClick}
					variant="circle" />
				{updatedAvatarImg && showIcon ? 
					<ButtonBase
						color="primary"
						aria-label="upload picture"
						className={classes.editAvatarButton}
						onClick={handleOnEditClick}
					>
						<CameraIcon fontSize="inherit" />
					</ButtonBase>
					:
					<Button
						color="secondary"
						disableRipple
						onClick={handleOnEditClick}
						className={classes.editAvatarTextButton}
					>{buttonLabel}</Button>
				}
				<input
					hidden
					ref={inputEl}
					accept="image/*"
					className={classes.flieInput}
					onChange={onFileChange}
					multiple
					id="contained-button-file"
					type="file"
				/>
			</div>
			<ImageUpload
				{...imageUploadProps}
				onApply={handleOnApply}
				onClose={() => {handleImageUploadClose();}}
			/>
		</div>
	);
};
export default ProfileImageEdit;
