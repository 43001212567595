import { connect } from 'react-redux';
import flow from 'lodash.flow';
import ProviderLogin from './ProviderLogin';
import {
	signInWithGoogle
} from 'reactive-core';

export default flow(
	connect(
		({
			authevents: {
				signInUserSuccess,
				authErrorCode,
				emailVerificationSent,
				sendPasswordReset,
			},
		}) => ({
			signInUserSuccess,
			authErrorCode,
			emailVerificationSent,
			sendPasswordReset,
		}),
		(dispatch) => ({
			onSignInWithGoogle: () => dispatch(signInWithGoogle())
		})
	)
)(ProviderLogin);
