export default (theme) => ({
	highlights: {
		padding: '26px',
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.down('sm')]: {
			padding: '16px'
		}
	},
	header: {
		display: 'flex',
		justifyContent: 'flex-end',
		paddingRight: '16px',
		[theme.breakpoints.down('sm')]: {
			paddingRight: '0px'
		}
	},
	newArticleButton: {
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	}
});
