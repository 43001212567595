export default (theme) => ({
	orgTeams: {
		padding: '20px 26px',
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.down('sm')]: {
			padding: '16px'
		}
	},
	header: {
		display: 'flex',
		justifyContent: 'flex-end'
	},

	addTeamButton: {
		minWidth: '167px',
		marginBottom: '22px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			marginBottom: '20px'
		}
	},
	emptyListWrapper: {
		height: '239px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center'
	}
});
