export default (theme) => ({
	root: {
		paddingTop: '8px',
		paddingBottom: '8px',
		paddingLeft: '44px',
		[theme.breakpoints.down('sm')]: {
			padding: '16px',
		}
	},
	homelink: {
		textDecoration: 'none',
		color: 'initial'
	},
	offset: {
		minHeight: '80px'
	},
	rightSide: {
		flex: 1
	},
	avatar: {
		width: '45px',
		height: '45px'
	},
	profileButtonWrapper: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center'
	},
	profileButton: {
		fontSize: '16px',
		marginLeft: '20px',
		paddingRight: '43px'
	},
	profileButtonIcon: {
		marginTop: '5px'
	},
	mainMenuPaper: {
		minWidth: '330px',
		padding: '24px',
		paddingBottom: '0px'
	},
	menuNameTitle: {
		fontSize: '24px',
		marginBottom: '10px',
		display: 'block'
	},
	menuSectionHeader: {
		color: '#7c7c7c',
		fontSize: '14px',
		textTransform: 'uppercase',
		marginBottom: '5px',
	},
	menuItem: {
		paddingLeft: '0px',
		paddingRight: '0px',
		fontSize: '14px',
		color: theme.palette.text.primary,
		'&:hover': {
			backgroundColor: 'transparent',
			color: theme.palette.primary.main,
		}
	},
	divider: {
		marginTop: '10px',
		marginBottom: '10px'
	},
	logOut: {
		color: theme.palette.error.main,
		'&:hover': {
			color: theme.palette.error.main
		}
	},
	'signUp': {
		marginRight: '18px'
	}
});
