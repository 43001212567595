import React, { useState, useEffect } from 'react';
import FormInput from 'components/FormInput';
import FormLabel from '@material-ui/core/FormLabel';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';


import styles from './styles';

const useStyles = makeStyles(styles);

const DobFormInput = ({
	date,
	onDateChange,
	required,
	error,
	errorText,
	dobRef
}) => {
	const theme = useTheme();
	const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();
	const { t } = useTranslation();
	const [monthEmpty, setMonthEmpty] = useState(!date);
	const [dayEmpty, setDayEmpty] = useState(!date);
	const [yearEmpty, setYearEmpty] = useState(!date);
	const [internalDate, setInternalDate] = useState(date ? new Date(date) : new Date());
	useEffect(() => {
		if (date) {
			setInternalDate(new Date(date));
			setMonthEmpty(false);
			setDayEmpty(false);
			setYearEmpty(false);
		}
	}, [date]);
	useEffect(() => {
		if (!(monthEmpty || dayEmpty || yearEmpty)) {
			if (onDateChange) {
				onDateChange(internalDate.valueOf());
			}
			if (dobRef) {
				dobRef.current.value = internalDate.valueOf();
			}
		}
	}, [monthEmpty, dayEmpty, yearEmpty, internalDate]);
	const months = [t('common:January'), t('common:February'), t('common:March'), t('common:April'), t('common:May'), t('common:June'), t('common:July'),
		t('common:August'), t('common:September'), t('common:October'), t('common:November'), t('common:December')];

	const getMonths = () => {
		return monthEmpty ?
			[t('common:month'), ...months].map((month, idx) => (<option key={idx - 1} value={idx - 1}>{month}</option>))
			: months.map((month, idx) => (<option key={idx} value={idx}>{month}</option>));
	};
	const getDays = () => {

		const totalDays = new Date(internalDate.getUTCFullYear(), internalDate.getUTCMonth() + 1, 0).getDate();
		const days = dayEmpty ? [<option key="empty" value="empty">{t('common:day')}</option>] : [];
		for (let d = 1; d <= totalDays; d++) {
			days.push(
				<option key={d} value={d}>{d}</option>
			);
		}
		return days;
	};
	const getYears = () => {
		const minYears = 1900;
		const maxYears = new Date().getFullYear();
		const years = yearEmpty ? [<option key="empty" value="empty">{t('common:year')}</option>] : [];
		for (let y = maxYears; y >= minYears; y--) {
			years.push(
				<option key={y} value={y}>{y}</option>
			);
		}
		return years;
	};

	const handleMonthChange = (e) => {
		setInternalDate(new Date(internalDate.getUTCFullYear(), parseInt(e.target.value, 10), internalDate.getUTCDate()));
		setMonthEmpty(false);
	};
	const handleDayChange = (e) => {
		setInternalDate(new Date(internalDate.getUTCFullYear(), internalDate.getUTCMonth(), parseInt(e.target.value, 10)));
		setDayEmpty(false);
	};
	const handleYearChange = (e) => {
		setInternalDate(new Date(parseInt(e.target.value, 10), internalDate.getUTCMonth(), internalDate.getUTCDate()));
		setYearEmpty(false);
	};

	return (
		<div className={classes.root}>
			<FormLabel required={required}>{t('date_of_birth')}</FormLabel>
			<div className={classes.fieldsWrapper}>
				<FormInput
					inputProps={{
						'aria-label': t('common:month'),
					}}
					select
					selectOptions={getMonths()}
					native
					error={error && monthEmpty}
					errorText={errorText}
					onChange={handleMonthChange}
					value={monthEmpty ? -1 : internalDate.getUTCMonth()}
					classes={{ formControl: classes.monthInput }}
				/>
				<FormInput
					inputProps={{
						'aria-label': t('common:day'),
					}}
					select
					selectOptions={getDays()}
					native
					error={error && dayEmpty}
					errorText={errorText}
					onChange={handleDayChange}
					value={dayEmpty ? 'empty' : internalDate.getUTCDate()}
					classes={{ formControl: matchesSm ? classes.dayInput : classes.dayYearInput }}
				/>
				<FormInput
					inputProps={{
						'aria-label': t('common:year'),
					}}
					select
					selectOptions={getYears()}
					native
					error={error && yearEmpty}
					errorText={errorText}
					onChange={handleYearChange}
					value={yearEmpty ? 'empty' : internalDate.getUTCFullYear()}
					classes={{ formControl: classes.dayYearInput }}
				/>
			</div>
		</div>
	);
};

export default DobFormInput;
