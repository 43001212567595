import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
	useHistory,
} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import PasswordConfirmModal from 'components/PasswordConfirmModal';
import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';
import styles from './styles';
const useStyles = makeStyles(styles);

const ChildAccountSettings = ({
	user,
	onDeleteChildAccount,
	deletingChildAccountSuccess,
	authErrorCode,
	resetSuccess
}) => {

	const classes = useStyles();
	const { t } = useTranslation();
	const history = useHistory();
	const [confirmProps, setConfirmProps] = useState({
		type: null,
		subTitle: null,
		errorCode: null,
		primaryBtnText: null,
		show: false,
		isChild: true
	});
	useEffect(() => {
		setConfirmProps({
			...confirmProps,
			errorCode: authErrorCode
		});
	}, [authErrorCode]);
	useEffect(() => {
		if (deletingChildAccountSuccess) {
			resetSuccess();
			history.push('/settings/children');
		}
	}, [deletingChildAccountSuccess]);
	const handleOnCloseAccountClick = () => {
		setConfirmProps({
			type: 'closeAccount',
			subTitle: t('please_enter_password_to_close_account'),
			showCloseAccount: true,
			primaryBtnText: t('close_account'),
			show: true,
			isChild: true
		});
	};
	const onPasswordConfirmSubmitClick = (password) => {
		onDeleteChildAccount({ id: user.id, password });
	};
	const onPasswordConfirmClose = () => {
		setConfirmProps({
			type: null,
			subTitle: null,
			warning: null,
			primaryBtnText: null,
			show: false,
			errorCode: null,
			isChild: true
		});
	};
	return (
		<div className={classes.accountSettings}>
			<div className={classes.formWrapper} >
				<Typography variant="h6" className={classes.sectionTitle}>{t('account_changes')}</Typography>
				<Divider className={classes.divider} variant="fullWidth" />
				<Typography variant="body1" className={classes.title}>{t('close_this_account')}</Typography>
				<Typography variant="body1" className={classes.subTitle}>{t('closing_your_account')}</Typography>
				<Button
					variant="contained"
					className={clsx(classes.submitButton, classes.closeAccountBtn)}
					onClick={handleOnCloseAccountClick}
				>{t('close_account')}</Button>
			</div>
			<PasswordConfirmModal
				{...confirmProps}
				onClose={onPasswordConfirmClose}
				onSubmit={onPasswordConfirmSubmitClick}
			/>
		</div>
	);
};

export default ChildAccountSettings;
