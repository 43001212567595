import React, { useState, useEffect, useRef } from 'react';
import AuthSplashForm from 'components/AuthSplashForm';
import splash from 'img/signin-splash.png';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import ProviderLogin from 'containers/ProviderLogin';
import FormInput from 'components/FormInput';
import Button from '@material-ui/core/Button';
import SignUpOption from 'components/SignUpOption';
import { useQuery } from 'utils/hooks/customHooks';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { FB_AUTH_USER_EMAIL_NOT_VERIFIED } from 'utils/constants';
import { useTranslation, Trans } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
const useStyles = makeStyles(styles);

const SignIn = ({
	onSubmitSignIn,
	authErrorCode,
	resetSuccess,
	signInUserSuccess,
	emailVerificationSent,
	sendEmailVerification,
	isSignedIn,
	isRecoverEmail,
	verifyEmailRecoveryCode
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const history = useHistory();
	const query = useQuery();
	const inviteId = query.get('invite');
	const isParent = query.get('isParent');
	const [verifyEmail, setVerifyEmail] = useState('');

	const refs = {
		email: useRef(null),
		password: useRef(null)
	};
	useEffect(() => {
		resetSuccess();
		if (!authErrorCode) {
			if (isSignedIn) {
				history.replace(`/${history.location.search}`);
			}
		}
	}, []);
	useEffect(() => {
		if (signInUserSuccess) {
			history.push(`/${history.location.search}`);
		}
	}, [signInUserSuccess]);
	useEffect(() => {
		if (emailVerificationSent) {
			history.push(`/sign-up${history.location.search}`, { reason: FB_AUTH_USER_EMAIL_NOT_VERIFIED });
		}
	}, [emailVerificationSent]);
	useEffect(() => {
		if (isRecoverEmail) {
			verifyEmailRecoveryCode(query.get('oobCode'));
		}
	}, [isRecoverEmail]);
	useEffect(() => {
		if (authErrorCode === FB_AUTH_USER_EMAIL_NOT_VERIFIED) {
			setVerifyEmail(refs.email.current.value);
		}
	}, [authErrorCode]);
	const handleResendVerifyClick = () => {
		const verifyData = { email: verifyEmail };
		if (inviteId) {
			verifyData['inviteId'] = inviteId;
		}
		if (isParent === 'true') {
			verifyData['isParent'] = true;
		}
		sendEmailVerification(verifyData);
	};
	const messageSeverity = authErrorCode ? 'error' : 'success';
	const getMessage = () => {
		let message = null;
		if (authErrorCode) {
			message = authErrorCode === FB_AUTH_USER_EMAIL_NOT_VERIFIED ?
				(
					<Trans t={t} i18nKey={authErrorCode}>
						This email has not been verified. <Link component="button" onClick={handleResendVerifyClick} color="primary">Resend activation link.</Link>
					</Trans>
				)
				: t(authErrorCode);
		} else if (history.location.state) {
			message = history.location.state.reason && t(history.location.state.reason);
			window.history.pushState(null, '');
		}
		return message;
	};

	const handleLoginClick = (e) => {
		const signInData = {
			email: refs.email.current.value,
			password: refs.password.current.value
		};
		if (inviteId) {
			signInData['inviteId'] = inviteId;
		}
		if (isParent === 'true') {
			signInData['isParent'] = true;
		}
		e.preventDefault();
		e.stopPropagation();
		onSubmitSignIn(signInData);
	};

	return (
		<>
			{isRecoverEmail && <div className={classes.snackBump} />}
			<AuthSplashForm
				formTitle={t("log_in")}
				formSubTitle={t("sign_in_sub_text")}
				splashImg={splash}
				messageSeverity={messageSeverity}
				classes={classes}
				message={getMessage()}
			>
				<form className={classes.loginForm} onSubmit={handleLoginClick} noValidate>
					<FormInput
						label={t("email")}
						id="login-email"
						fullWidth
						type="email"
						inputRef={refs.email}
						classes={classes}
					/>
					<FormInput
						label={t("password")}
						id="login-password"
						fullWidth
						type="password"
						inputRef={refs.password}
						classes={classes}
					/>
					<Link
						component={RouterLink}
						to={`/password-reset${history.location.search}`}
						color="primary"
						className={classes.forgotPasswd}
					>
						{t("forgot_your_password")}
					</Link>
					<Button variant="contained" type="submit" color="primary" fullWidth >{t('login')}</Button>
					<ProviderLogin />
					<SignUpOption className={classes.signupWrapper} />
				</form>
			</AuthSplashForm>
			<Snackbar
				className={classes.recoveryMsg}
				open={isRecoverEmail}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<Alert
					variant="filled"
					severity="success"
					icon={false}
					className={classes.alert}
				>
					{t("email_reset_password_reset_sent")}
				</Alert>
			</Snackbar>
		</>
	);
};

export default SignIn;
