import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
const useStyles = makeStyles(styles);

const OrgAbout = ({ org }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.about}>
			<Typography variant="h6" className={classes.sectionLabel}>{t('mission_statement')}</Typography>
			{org.missionStatement &&
				<div className={classes.infoSection}>
					<Typography variant="body1" className={classes.bioText} >{org.missionStatement}</Typography>
				</div>
			}
		</div>
	);
};

export default OrgAbout;
