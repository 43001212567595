import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as Whistle } from 'icons/Whistle_icon.svg';

import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import styles from './styles';
const useStyles = makeStyles(styles);

const RemoveEntityModal = ({
	onClose,
	show,
	entity,
	onSubmit,
	isWarning,
	warningText,
	title,
	areYouSureText,
	submitButtonText,
	submitDisabled
}) => {
	const theme = useTheme();
	const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Dialog
			onClose={onClose}
			aria-labelledby="remove-org-member-title"
			maxWidth="sm"
			fullScreen={matchesSm}
			disableBackdropClick
			classes={{ paperWidthSm: classes.paperWidthSm }}
			open={show}
		>
			{show &&
				<>
					{!isWarning &&
						<DialogTitle id="remove-org-member-title" disableTypography className={classes.titleWrapper}>
							<Typography variant="h5" component="h1" className={classes.title}>
								{title}
							</Typography>
						</DialogTitle>
					}
					<DialogContent className={classes.contentWrapper}>
						{isWarning ?
							<>
								<Whistle className={classes.whistleIcon} />
								<Typography variant="h2">{t('timeout')}</Typography>
								<Typography variant="body1" className={classes.closeAccountMsg}>{warningText}</Typography>
							</>
							:
							<>
								<div className={classes.userWrapper}>
									{entity.profileImgUrl && <Avatar alt={entity.name} src={entity.profileImgUrl} className={classes.avatar} />}
									<Typography variant="h6" className={classes.userName}>{entity.name}</Typography>
								</div>
								<Typography variant="body1" className={classes.closeAccountMsg}>{areYouSureText}</Typography>
							</>
						}
					</DialogContent>
					<DialogActions className={classes.actionsWrapper} >
						<Button onClick={onClose} variant="outlined" className={classes.cancelBtn}>
							{t('cancel')}
						</Button>
						<Button onClick={() => { onSubmit(entity); }} disabled={submitDisabled} color="primary" variant="contained" className={classes.submitBtn}>
							{submitButtonText}
						</Button>
					</DialogActions>
				</>
			}
		</Dialog>
	);
};

export default RemoveEntityModal;
