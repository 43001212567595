import React, {useEffect, useState} from 'react';
import PageNotFound from 'components/PageNotFound';

const WithResourceNotFound = (WrappedComponent) => {
	const NotFoundComponent = (props) => {
		const [isNotFound, setIsNotFound] = useState(false);
		useEffect(() => {
			if(props.resourceNotFound) {
				setIsNotFound(true);
				props.clearResourceNotFound();
			}
		}, [props.resourceNotFound]);
		return (
			<>
				{(props.resourceNotFound || isNotFound) ? 
					<PageNotFound />
					:
					<WrappedComponent {...props} />
				}
			</>
		);
	};
	return NotFoundComponent;
};

export default WithResourceNotFound;
