/* eslint-disable no-empty-pattern */
import { connect } from 'react-redux';
import flow from 'lodash.flow';
import UserHighlights from './UserHighlights';
import { fetchUserArticles } from 'reactive-core';
import {getArticleCardsForUser} from 'selectors/articleSelector';

export default flow(
	connect(
		({
			profile: { user }
		}) => ({
			user,
			articles: getArticleCardsForUser({user}) || null
		}),
		(dispatch) => ({
			fetchUserArticles: (id) => dispatch(fetchUserArticles(id)),
		})
	)
)(UserHighlights);
