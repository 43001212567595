import React, { useState, useRef } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormInput from 'components/FormInput';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
	teamGenderOptions,
	stateOptions,
	ageGroupOptions,
	sportOptions
} from 'utils/selectOptions';
import styles from './styles';
const useStyles = makeStyles(styles);

const CreateTeamModal = ({
	show,
	org,
	user,
	onClose,
	onSubmit
}) => {
	const theme = useTheme();
	const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();
	const { t } = useTranslation();
	const [validateError, setValidateError] = useState(false);
	const [isCoach, setIsCoach] = useState(false);

	const refs = {
		name: useRef(null),
		gender: useRef(null),
		ageGroup: useRef(null),
		sport: useRef(null),
		city: useRef(null),
		state: useRef(null),
		zipcode: useRef(null)
	};
	const validEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [invalidEmail, setInvalidEmail] = useState(false);

	const handleOnSubmit = () => {
		const keys = Object.keys(refs);
		if (keys.some((key) => !refs[key].current.value)) {
			setValidateError(true);
		} else {
			let updated = {coach: {}};
			keys.forEach((key) => {
				updated[key] = refs[key].current.value;
			});
			if(email.trim() !== '') {
				if(validEmail(email)) {
					updated.coach = {
						firstName,
						lastName,
						email
					};
				} else {
					setInvalidEmail(true);
					return;
				} 
			}
			onSubmit(updated);
		}
	};
	const handleOnIsCoach = (value) => {
		console.log(value);
		if(isCoach) {
			setFirstName('');
			setLastName('');
			setEmail('');
		} else {
			setFirstName(user.firstName);
			setLastName(user.lastName);
			setEmail(user.email);
		}
		setIsCoach(!isCoach);
	};
	return (
		<Dialog
			onClose={onClose}
			aria-labelledby="create-team-title"
			maxWidth="md"
			fullScreen={matchesSm}
			disableBackdropClick
			classes={{ paperWidthMd: classes.paperWidthMd }}
			open={show}
		>
			<DialogTitle id="create-team-title" disableTypography className={classes.titleWrapper}>
				<Typography variant="h5" className={classes.title}>
					{t('create_new_team')}
				</Typography>
			</DialogTitle>
			<DialogContent className={classes.contentWrapper}>
				<div className={classes.formWrapper}>
					<FormInput
						label={t('team_name')}
						required
						id="team_name"
						errorText={t('field_required')}
						type="text"
						inputRef={refs.name}
						error={validateError && (refs.name && !refs.name.current.value)}
						classes={{ formControl: classes.name }}
					/>
					<FormInput
						label={t('sport')}
						select
						required
						selectOptions={sportOptions(t, false)}
						native
						inputRef={refs.sport}
						error={validateError && (refs.sport && !refs.sport.current.value)}
						errorText={t('field_required')}
						fullWidth={false}
						classes={{ formControl: classes.sport }}
					/>
					<div className={matchesSm ? classes.mobileFormRow : classes.formRow}>
						<FormInput
							label={t('gender')}
							select
							required
							selectOptions={teamGenderOptions(t, false)}
							native
							inputRef={refs.gender}
							error={validateError && (refs.gender && !refs.gender.current.value)}
							errorText={t('field_required')}
							classes={{ formControl: classes.medWidthFields }}
						/>
						<FormInput
							label={t('age_group')}
							select
							required
							selectOptions={ageGroupOptions(t,false)}
							native
							inputRef={refs.ageGroup}
							error={validateError && (refs.ageGroup && !refs.ageGroup.current.value)}
							errorText={t('field_required')}
							classes={{ formControl: classes.medWidthFields }}
						/>
					</div>
					<div className={classes.formRow}>
						<FormInput
							label={t('city')}
							required
							id="org-city"
							errorText={t('field_required')}
							type="text"
							defaultValue={org.city}
							inputRef={refs.city}
							error={validateError && (refs.city && !refs.city.current.value)}
							classes={{ formControl: classes.medWidthFields }}
						/>
						<div className={classes.mobileFormRow}>
							<FormInput
								label={t('state')}
								select
								required
								id="org-state"
								selectOptions={stateOptions(t, true)}
								native
								errorText={t('field_required')}
								defaultValue={org.state}
								inputRef={refs.state}
								error={validateError && (refs.state && !refs.state.current.value)}
								classes={{ formControl: classes.stateZipFields }}
							/>
							<FormInput
								label={t('zip_code')}
								required
								id="org-zip-code"
								error={validateError && (refs.zipcode && !refs.zipcode.current.value)}
								errorText={t('field_required')}
								type="text"
								inputRef={refs.zipcode}
								defaultValue={org.zipcode}
								classes={{ formControl: classes.stateZipFields }}
							/>
						</div>
					</div>
					<Typography variant="h6">{t('invite_the_coach')}</Typography>
					<Typography variant="body1">{t('send_an_invite_to_team_coach')}</Typography>
					<FormControlLabel
						control={
							<Checkbox
								checked={isCoach}
								onChange={handleOnIsCoach}
								name="checkedB"
								color="primary"
							/>
						}
						label={t('you_are_coaching_this_team')}
					/>
					<div className={classes.formRow}>
						<FormInput
							label={t('first_name')}
							id="first_name"
							fullWidth
							type="text"
							disabled={isCoach}
							value={firstName}
							classes={{ formControl: classes.coachName }}
						/>
						<FormInput
							label={t('last_name')}
							id="last_name"
							fullWidth
							type="text"
							disabled={isCoach}
							value={lastName}
							classes={{ formControl: classes.coachName }}
						/>
					</div>
					<FormInput
						label={t('email')}
						id="email"
						fullWidth
						type="text"
						error={invalidEmail}
						errorText={t('invalid_email_address')}
						disabled={isCoach}
						value={email}
						classes={{ formControl: classes.name }}
					/>
				</div>
			</DialogContent>
			<DialogActions className={classes.actionsWrapper} >
				<Button onClick={onClose} variant="outlined" className={classes.cancelBtn}>
					{t('cancel')}
				</Button>
				<Button onClick={handleOnSubmit} color="primary" variant="contained" className={classes.submitBtn}>
					{t('create_team')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default CreateTeamModal;
