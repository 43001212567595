import { connect } from 'react-redux';
import flow from 'lodash.flow';
import Root from './Root';
import WithAuth from 'WithAuth';
import { fetchAuthUser, clearCreatedOrgId, resetSuccess, clearResourceNotFound, acceptInvite } from 'reactive-core';

export default flow(
	connect(
		({
			authUser: { user, invite },
			authevents: { acceptInviteSuccess, acceptingInvite },
			uievents: { showCreateUserProfile, fetchingAuthUser, createdOrgId, resourceNotFound }
		}) => ({
			user,
			invite,
			createdOrgId,
			showCreateUserProfile,
			fetchingAuthUser,
			acceptInviteSuccess,
			acceptingInvite,
			resourceNotFound
		}),
		(dispatch) => ({
			fetchAuthUser: () => dispatch(fetchAuthUser()),
			clearCreatedOrgId: () => dispatch(clearCreatedOrgId()),
			acceptInvite: (id) => dispatch(acceptInvite(id)),
			resetSuccess: () => dispatch(resetSuccess()),
			clearResourceNotFound: () => dispatch(clearResourceNotFound())
		})
	),
	WithAuth
)(Root);
