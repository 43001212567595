import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {ReactComponent as PencilIcon}  from 'icons/Pencil_edit_icon.svg';

import { 
	Link as RouterLink,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
const useStyles = makeStyles(styles);

const UserProfileHeader = ({
	user,
	editable
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<div className={classes.userProfileHeader}>
			<Typography variant="h5" className={classes.name}>{user ? `${user.firstName} ${user.lastName}` : ''}</Typography>
			{editable && 
				<Button
					className={classes.editProfileBtn}
					component={RouterLink}
					color="primary"
					startIcon={<PencilIcon />}
					to={user.parentId ? `/settings/edit-profile/${user.id}` : '/settings/edit-profile'}
				>{t('add_edit_profile_info')}</Button>
			}
		</div>
	);
};

export default UserProfileHeader;
