import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const SignOut = ({
	signOutAuthUser,
	signOutAuthUserSuccess
}) => {
	const history = useHistory();
	useEffect(() => {
		signOutAuthUser();
	}, []);
	useEffect(() => {
		history.push('/sign-in');
	}, [signOutAuthUserSuccess]);
	return (
		<div>
		</div>
	);
};

export default SignOut;
