export default (theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column'
	},
	fieldsWrapper: {
		display: 'flex'
	},
	monthInput: {
		[theme.breakpoints.up('sm')]: {
			width: '176px'
		}
	},
	dayInput: {
		marginLeft: '16px',
		width: '176px'
	},
	dayYearInput: {
		[theme.breakpoints.up('sm')]: {
			width: '116px',
		},
		marginLeft: '16px'
	}
});
