import React from 'react';
import Chip from '@material-ui/core/Chip';
import FormInput from 'components/FormInput';
import { ReactComponent as Close } from 'icons/Close_x_icon.svg';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
const useStyles = makeStyles(styles);

const MultiSelect = ({ placholderText, items, selectedItems, onChange, color }) => {
	const classes = useStyles();
	const { t } = useTranslation();


	const itemOptions = () => [{
		key: placholderText,
		value: placholderText
	}, ...items].map((item) => (
		<option key={item.key} value={item.key}>{t(item.value)}</option>
	));
	return (
		<div className={classes.root}>
			<ul className={classes.selectedList}>
				{
					selectedItems.map((item) => (
						<li key={item.key}>
							<Chip
								label={t(item.value)}
								onDelete={item.removeable && onChange ? () => { onChange('delete', item.key); } : null}
								className={classes.chip}
								deleteIcon={item.removeable && onChange ? <Close /> : null}
								color={color}
								classes={{ deleteIcon: classes.deleteIcon, label: classes.chipLabel }}
							/>
						</li>
					))
				}
				{onChange &&
					<li key="select">
						<FormInput
							select
							required
							selectOptions={itemOptions()}
							native
							fullWidth={false}
							value='add_sport'
							onChange={(e) => { onChange('add', e.target.value); }}
							classes={{ formControl: classes.addSportSelect }}
						/>
					</li>
				}
			</ul>
		</div>
	);
};

export default MultiSelect;
