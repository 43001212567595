export const FB_AUTH_EXPIRED_ACTION_CODE = 'auth/expired-action-code';
export const FB_AUTH_INVALID_ACTION_CODE = 'auth/invalid-action-code';
export const FB_AUTH_USER_DISABLED = 'auth/user-disabled';
export const FB_AUTH_USER_NOT_FOUND = 'auth/user-not-found';
export const FB_AUTH_WEAK_PASSWORD = 'auth/weak-password';
export const FB_AUTH_WRONG_PASSWORD = 'auth/wrong-password';
export const FB_AUTH_EMAIL_ALREADY_IN_USE = 'auth/email-already-in-use';
export const FB_AUTH_INVALID_EMAIL = 'auth/invalid-email';
export const FB_AUTH_OPERATION_NOT_ALLOWED = 'auth/operation-not-allowed';
export const FB_AUTH_USER_EMAIL_NOT_VERIFIED = 'auth/user-email-not-verified';
export const FB_AUTH_USER_NOT_SIGNED_IN = 'auth/user-not-signed-in';
export const FB_AUTH_REQUIRES_RECENT_LOGIN = 'auth/requires-recent-login';

export const US_STATES = ['AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'];
export const SPORTS = [
	'baseball',
	'basketball',
	'bowling',
	'cricket',
	'cross_country',
	'equestrian',
	'fencing',
	'field_hockey',
	'football',
	'golf',
	'gymnastics',
	'ice_hockey',
	'lacrosse',
	'rifle',
	'rowing',
	'rugby',
	'skiing',
	'soccer',
	'softball',
	'swim_dive',
	'tennis',
	'track_field',
	'volleyball',
	'water_polo',
	'wrestling'
];
export const mainMenu = [
	{
		title: 'profile',
		items: [
			{
				label: 'view_profile',
				to: '/user/'
			},
			{
				label: 'edit_profile',
				to: '/settings/edit-profile'
			}
		]
	},
	{
		title: 'settings',
		items: [
			{
				label: 'account_settings',
				to: '/settings/account-settings'
			}
		]
	},
	{
		title: 'organizations',
		items: [
			{
				label: 'create_organizations',
				onClick: true
			},
			{
				label: 'manage_organizations',
				to: '/settings/manage-organizations'
			}
		]
	},
	{
		title: 'help',
		items: [
			{
				label: 'contact_support',
				to: '/support'
			}
		]
	}
];
export const INVITATION_PENDING = 'invitation_pending';
export const INVITATION_ACCEPTED = 'invitation_accepted';
export const INVITATION_CANCELED = 'invitation_canceled';

export const ORG = 'organization';
export const TEAM = 'team';
