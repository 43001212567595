export default (theme) => ({
	accountSettings: {
		display: 'flex',
		paddingLeft: '361px',
		[theme.breakpoints.down('sm')]: {
			padding: '16px'
		}
	},
	formWrapper: {
		width: '808px',
		display: 'flex',
		flexDirection: 'column',
		paddingBottom: '40px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',

		}
	},
	divider: {
		marginTop: '4px',
		marginBottom: '40px'
	},
	emailPasswordInput: {
		width: '352px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		}
	},
	submitButton: {
		marginTop: '5px',
		width: '185px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		}
	},
	closeAccountMsg: {
		marginBottom: '18px'
	},
	closeAccountBtn: {
		backgroundColor: theme.palette.error.main,
		color: '#fff',
		'&:hover': {
			backgroundColor: theme.palette.error.dark
		}
	},
	passwordResetMsg: {
		fontSize: '14px',
		letterSpacing: '-0.28px',
		color: theme.palette.text.secondary,
		marginTop: '10px'
	},
	labels: {
		marginTop: '50px'
	},
	sectionTitle: {
		marginTop: '50px',
		[theme.breakpoints.down('sm')]: {
			'&:first-child': {
				marginTop: '20px'
			}
		}
	},
	passwordWrapper: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: '50px'
	}
});
