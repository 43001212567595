import { connect } from 'react-redux';
import flow from 'lodash.flow';
import SignIn from './SignIn';
import WithAuth from 'WithAuth';
import { signInUser, resetSuccess, sendEmailVerification, verifyEmailRecoveryCode } from 'reactive-core';

export default flow(
	connect(
		({
			authevents: { signInUserSuccess, authErrorCode, emailVerificationSent, sendPasswordReset },
		}) => ({
			signInUserSuccess,
			authErrorCode,
			emailVerificationSent,
			sendPasswordReset
		}),
		(dispatch) => ({
			onSubmitSignIn: (signInData) => dispatch(signInUser(signInData)),
			resetSuccess: () => dispatch(resetSuccess()),
			sendEmailVerification: (email) => dispatch(sendEmailVerification(email)),
			verifyEmailRecoveryCode: (actionCode) => dispatch(verifyEmailRecoveryCode({ actionCode }))
		})
	),
	WithAuth
)(SignIn);
