import { connect } from 'react-redux';
import flow from 'lodash.flow';
import {deleteChildAccount, resetSuccess} from 'reactive-core';
import ChildAccountSettings from './ChildAccountSettings';
export default flow(
	connect(
		({
			authevents: {
				deletingChildAccount,
				deletingChildAccountSuccess,
				authErrorCode
			}
		}, {user}) => ({
			user,
			deletingChildAccount,
			deletingChildAccountSuccess,
			authErrorCode
		}),
		(dispatch) => ({
			onDeleteChildAccount: (deleteData) => dispatch(deleteChildAccount(deleteData)),
			resetSuccess: () => dispatch(resetSuccess())
		}))
)(ChildAccountSettings);
