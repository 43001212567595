import { connect } from 'react-redux';
import flow from 'lodash.flow';
import { createChildProfile, resetSuccess } from 'reactive-core';
import ChildrenSettings from './ChildrenSettings';
export default flow(
	connect(
		({
			authevents: {
				creatingChildProfile,
				creatingChildProfileSuccess,
				acceptInviteSuccess
			}
		}, {
			user
		}) => ({
			user,
			creatingChildProfile,
			creatingChildProfileSuccess,
			acceptInviteSuccess
		}),
		(dispatch) => ({
			createChildProfile: (childData) => dispatch(createChildProfile(childData)),
			resetSuccess: () => dispatch(resetSuccess())
		}))
)(ChildrenSettings);
