import React, { useState, useEffect, useRef } from 'react';
import ProfileImageEdit from 'components/ProfileImageEdit';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import FormInput from 'components/FormInput';
import DobFormInput from 'components/DobFormInput';
import PhoneMaskedInput from 'components/PhoneMaskedInput';
import Toast from 'components/Toast';
import { usePrevious } from 'utils/hooks/customHooks';
import MultiSelect from 'components/MultiSelect';
import { SPORTS } from 'utils/constants';
import { genderOptions, stateOptions } from 'utils/selectOptions';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import styles from './styles';
const useStyles = makeStyles(styles);

const EditProfile = ({
	user,
	authUser,
	updatingProfile,
	updateProfileSuccess,
	onSubmitClick,
	onSubmitChildClick
}) => {
	const theme = useTheme();
	const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();
	const { t } = useTranslation();
	const [validateError, setValidateError] = useState(false);
	const [profileImg, setProfileImg] = useState(null);
	const prevUpdatingProfile = usePrevious(updatingProfile);

	const refs = {
		firstName: useRef(null),
		lastName: useRef(null),
		dob: useRef({ value: user.dob }),
		gender: useRef(null),
		bio: useRef(null),
		phone: useRef(null),
		city: useRef(null),
		state: useRef(null),
		zipcode: useRef(null),
		height: {
			in: useRef(null),
			ft: useRef(null),
		},
		weight: useRef(null)
	};


	const [toastProps, setToastProps] = useState({
		open: false,
		message: '',
		severity: null
	});

	useEffect(() => {
		if (updateProfileSuccess && !updatingProfile && prevUpdatingProfile) {
			setToastProps({
				open: true,
				message: t('profile_info_saved'),
				severity: 'success'
			});
		}
	}, [updatingProfile, prevUpdatingProfile, updateProfileSuccess]);
	const requiredFields = [
		'firstName',
		'lastName',
		'city',
		'state'
	];
	const [selectedSports, setSelectedSports] = useState(user.sports.map((sport) => ({
		key: sport,
		removeable: true,
		value: sport
	})));
	const getSports = () => SPORTS.map((sport) => ({
		key: sport,
		value: sport
	}));
	const handleSportsChange = (action, key) => {
		if (action === 'delete') {
			setSelectedSports(selectedSports.filter((s) => s.key !== key));
		} else if (action === 'add' && !selectedSports.find((sport) => sport.key === key)) {
			setSelectedSports([...selectedSports, {
				key: key,
				removeable: true,
				value: key
			}]);
		}
	};
	const getChildTeams = () => authUser.teams.map((team) => ({
		key: team.id,
		value: team.name
	}));
	const [selectedTeams, setSelectedTeams] = useState(user.teams.map((team) => ({
		key: team.id,
		removeable: !team.role,
		value: team.name
	})));
	const handleTeamChange = (action, key) => {
		
		if (action === 'delete') {
			setSelectedTeams(selectedTeams.filter((s) => s.key !== key));
		} else if (action === 'add' && !selectedTeams.find((team) => team.key === key)) {
			const addedTeam = authUser.teams.find((team) => team.id === key);
			setSelectedTeams([...selectedTeams, {
				key: addedTeam.id,
				removeable: true,
				value: addedTeam.name
			}]);
		}
	};
	const getUpdatedData = () => {
		const updated = { id: user.id };
		Object.keys(refs).forEach((key) => {
			if (key === 'phone') {
				if (!(/\d/.test(refs.phone.current.value)) && updated[key] !== null) {
					updated[key] = null;
				} else if (refs.phone.current.value !== user.phone) {
					updated[key] = refs.phone.current.value;
				}
			} else if (key === 'height') {
				if (!refs.height.ft.current.value && user.height) {
					updated[key] = null;
				} else if ((user.height === null && refs.height.ft.current.value) ||
					(user.height && (refs.height.ft.current.value !== user.height.ft || refs.height.in.current.value !== user.height.in))) {
					updated[key] = {
						ft: refs.height.ft.current.value,
						in: refs.height.in.current.value
					};
				}
			} else if (refs[key].current.value !== user[key] && user[key] !== null || user[key] === null && refs[key].current.value !== '') {
				updated[key] = refs[key].current.value;
			}
		});
		if (profileImg) {
			updated.profileImg = profileImg;
		}
		if (JSON.stringify(selectedSports) !== JSON.stringify(user.sports)) {
			updated.sports = selectedSports.map((sport) => sport.key);
		}
		if (user.parentId === authUser.id) {
			if(JSON.stringify(selectedTeams.map((team) => team.key)) !== JSON.stringify(user.teams.map((team) => team.id)))
				updated.teams = selectedTeams.map((team) => ({
					id: team.key
				}));
		}
		return updated;
	};
	const handleOnSubmitClick = () => {
		if (requiredFields.some((key) => !refs[key].current.value)) {
			setValidateError(true);
		} else {
			if (user.parentId === authUser.id) {
				onSubmitChildClick(getUpdatedData());
			} else {
				onSubmitClick(getUpdatedData());
			}
		}
	};
	const onToastClose = () => {
		setToastProps({
			open: false,
			message: '',
			severity: ''
		});
	};
	return (
		<div className={classes.editProfile}>
			<div className={classes.pofileImgWrapper}>
				<ProfileImageEdit
					size={matchesSm ? '145' : '190'}
					showIcon
					onUpdateAvatar={(imgObj) => { setProfileImg(imgObj.file); }}
					avatarImageUrl={user.profileImgUrl}
					buttonLabel={t('choose_profile_picture')}
					uploadTitle={t('add_profile_pic')}
				/>
			</div>
			<div className={classes.formWrapper} >
				<Typography variant="h6" className={classes.sectionTitle}>{t('personal_info')}</Typography>
				<Divider className={classes.divider} variant="fullWidth" />
				<div className={classes.formRow}>
					<FormInput
						label={t('first_name')}
						required
						id="first_name"
						fullWidth
						errorText={t('field_required')}
						type="text"
						inputRef={refs.firstName}
						defaultValue={user.firstName}
						error={validateError && (refs.firstName && !refs.firstName.current.value)}
						classes={{ formControl: classes.name }}
					/>
					<FormInput
						label={t('last_name')}
						required
						id="last_name"
						fullWidth
						errorText={t('field_required')}
						type="text"
						inputRef={refs.lastName}
						defaultValue={user.lastName}
						error={validateError && (refs.lastName && !refs.lastName.current.value)}
						classes={{ formControl: classes.name }}
					/>
				</div>
				<DobFormInput
					date={user.dob}
					required
					error={validateError && (refs.dob && !refs.dob.current.value)}
					errorText={t('field_required')}
					dobRef={refs.dob}
				/>
				<FormInput
					label={t('gender')}
					select
					required
					selectOptions={genderOptions(t, true)}
					native
					inputRef={refs.gender}
					error={validateError && (refs.gender && !refs.gender.current.value)}
					errorText={t('field_required')}
					defaultValue={user.gender}
					classes={{ formControl: classes.medWidthFields }}
				/>
				<FormInput
					label={t('bio')}
					id="bio"
					fullWidth
					multiline
					rows={4}
					type="text"
					inputRef={refs.bio}
					defaultValue={user.bio}
					error={validateError && (refs.bio && !refs.bio.current.value)}
				/>
				<Typography variant="h6" className={classes.sectionTitle}>{t('contact')}</Typography>
				<Divider className={classes.divider} variant="fullWidth" />
				<FormInput
					label={t('phone')}
					id="phone"
					fullWidth
					inputComponent={PhoneMaskedInput}
					type="text"
					inputRef={refs.phone}
					defaultValue={user.phone || '(  )    -    '}
					error={validateError && (refs.phone && !refs.phone.current.value)}
					classes={{ formControl: classes.halfWidthFields }}
				/>
				<div className={classes.formRow}>
					<FormInput
						label={t('city')}
						required
						id="profile-city"
						fullWidth
						errorText={t('field_required')}
						type="text"
						inputRef={refs.city}
						defaultValue={user.city}
						error={validateError && (refs.city && !refs.city.current.value)}
						classes={{ formControl: classes.cityField }}
					/>
					<div className={classes.mobileFormRow}>
						<FormInput
							label={t('state')}
							select
							required
							selectOptions={stateOptions(t, true)}
							native
							inputRef={refs.state}
							error={validateError && (refs.state && !refs.state.current.value)}
							errorText={t('field_required')}
							defaultValue={user.state}
							classes={{ formControl: classes.stateZipFields }}
						/>
						<FormInput
							label={t('zip_code')}
							required
							id="profile-zip-code"
							fullWidth
							error={validateError && (refs.zipcode && !refs.zipcode.current.value)}
							errorText={t('field_required')}
							type="text"
							inputRef={refs.zipcode}
							defaultValue={user.zipcode}
							classes={{ formControl: classes.stateZipFields }}
						/>
					</div>
				</div>
				<Typography variant="h6" className={classes.sectionTitle}>{t('stats')}</Typography>
				<Divider className={classes.divider} variant="fullWidth" />
				<div className={matchesSm ? classes.mobileFormRow : classes.formRow}>
					<FormInput
						label={t('height')}
						suffixLabel={t('ft')}
						id="profile-height-ft"
						error={validateError && (refs.height.ft && !refs.height.ft.current.value)}
						type="text"
						inputRef={refs.height.ft}
						defaultValue={user.height ? user.height.ft : null}
						classes={{ formControl: classes.minWidthFields }}
					/>
					<FormInput
						suffixLabel={t('in')}
						id="profile-height-in"
						error={validateError && (refs.height.in && !refs.height.in.current.value)}
						type="text"
						inputRef={refs.height.in}
						defaultValue={user.height ? user.height.in : null}
						classes={{ formControl: classes.minWidthFields }}
					/>
				</div>
				<div>
					<FormInput
						label={t('weight')}
						suffixLabel={t('lbs')}
						id="profile-weight"
						error={validateError && (refs.weight && !refs.weight.current.value)}
						type="text"
						inputRef={refs.weight}
						defaultValue={user.weight}
						classes={{ formControl: classes.minWidthFields }}
					/>
				</div>
				<Typography variant="h6" className={classes.sectionTitle}>{t('sport_plural')}</Typography>
				<Divider className={classes.divider} variant="fullWidth" />
				<MultiSelect
					items={getSports()}
					selectedItems={selectedSports}
					onChange={handleSportsChange}
					color="secondary"
					placholderText="add_sport"
				/>
				{(user.parentId === authUser.id) &&
					<Box mt={3}>
						<Typography variant="h6" className={classes.sectionTitle}>{t('teams')}</Typography>
						<Divider variant="fullWidth" />
						<Typography variant="body1" className={classes.manageTeamMessage}>{t('manage_your_child_team')}</Typography>
						<MultiSelect
							items={getChildTeams()}
							selectedItems={selectedTeams}
							onChange={handleTeamChange}
							color="primary"
							placholderText="add_team"
						/>
					</Box>
				}
				<Button variant="contained" color="primary" className={classes.submitButton} onClick={handleOnSubmitClick}>{t('save_profile')}</Button>
			</div>
		
			<Toast
				{...toastProps}
				onClose={onToastClose}
			/>
		</div>
	);
};

export default EditProfile;
