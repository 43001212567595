import { connect } from 'react-redux';
import flow from 'lodash.flow';
import CreateProfile from './CreateProfile';
import {createUserProfile, signOutAuthUser} from 'reactive-core';

export default flow(
	connect(
		({
			authUser: { user },
		}) => ({
			user
		}),
		(dispatch) => ({
			onSignOutClick: () => dispatch(signOutAuthUser()),
			onSubmitClick: (profileData) => dispatch(createUserProfile(profileData)),
		})
	)
)(CreateProfile);
