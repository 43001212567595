export default () => ({
	highlights: {
		padding: '26px',
		display: 'flex',
		flexDirection: 'column'
	},
	header: {
		display: 'flex',
		justifyContent: 'flex-end',
		paddingRight: '16px'
	},
});
