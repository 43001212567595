export default (theme) => ({
	childrenSettings: {
		display: 'flex',
		paddingLeft: '361px',
		marginTop: '54px',
		[theme.breakpoints.down('sm')]: {
			margin: '0px',
			marginTop: '20px',
			padding: '16px'
		}
	},
	formWrapper: {
		width: '808px',
		display: 'flex',
		flexDirection: 'column',
		paddingBottom: '40px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		}
	},
	addChildButton: {
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			marginBottom: '20px'
		}
	},
	divider: {
		marginTop: '4px',
		marginBottom: '30px'
	},
	header: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center'
	},
	listLabel: {
		marginBottom: '18px',
		display: 'block'
	},
	listWrapper: {
		marginBottom: '24px'
	},
	plusIcon: {
		width: '27px',
		height: '27px',
		'& path': {
			fill: theme.palette.primary.main
		}
	},
	emptyText: {
		color: theme.palette.text.secondary,
		textAlign: 'center',
		marginBottom: '20px'
	},
	emptyListWrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginTop: '40px'
	}
});
