import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormInput from 'components/FormInput';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as Whistle } from 'icons/Whistle_icon.svg';

import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import styles from './styles';
const useStyles = makeStyles(styles);

const PasswordConfirmModal = ({
	show,
	subTitle,
	onClose,
	onSubmit,
	errorCode,
	primaryBtnText,
	showCloseAccount,
	isChild
}) => {
	const theme = useTheme();
	const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();
	const { t } = useTranslation();
	const [password, setPassword] = useState('');
	const [error, setError] = useState(false);
	const [showCloseMsg, setShowCloseMsg] = useState(showCloseAccount);
	useEffect(() => {
		if (showCloseAccount) {
			setShowCloseMsg(true);
		} else {
			setShowCloseMsg(false);
		}
	}, [showCloseAccount]);
	const handleOnSubmit = () => {
		if (password) {
			onSubmit(password);
		} else {
			setError(true);
		}
	};
	return (
		<Dialog onClose={onClose} aria-labelledby="confirm-password-title" maxWidth="md" fullScreen={matchesSm} disableBackdropClick classes={classes} open={show}>
			{!showCloseMsg &&

				<DialogTitle id="confirm-password-title" disableTypography className={classes.dialogTitle}>
					<Typography variant="h5">
						{t('enter_password')}
					</Typography>
					<Typography variant="subtitle2" color="textSecondary">{subTitle}</Typography>
				</DialogTitle>
			}
			<DialogContent className={classes.contentWrapper}>
				{showCloseMsg ?
					<div className={classes.closeAccountContent}>
						<Whistle className={classes.whistleIcon} />
						<Typography variant="h2">{t('timeout')}</Typography>
						{isChild ?
							<>
								<Typography variant="body1" className={classes.closeAccountMsg}>{t('closing_your_child_account')}</Typography>
							</>
							:
							<>
								<Typography variant="body1" className={classes.closeAccountMsg}>{t('closing_your_account')}</Typography>
								<Typography variant="h4" className={classes.clostAccountQuest}>{t('are_you_sure_we_hate_to_see_you_go')}</Typography>
							</>
						}
					</div>
					:
					<div className={classes.passwordContent}>
						<FormInput
							label={t('password')}
							id="password"
							fullWidth
							error={errorCode || error}
							errorText={t('password_incorrect')}
							type="password"
							onChange={(e) => { setPassword(e.target.value); }}
							classes={{ formControl: classes.password }}
						/>
					</div>
				}
			</DialogContent>
			<DialogActions className={classes.actionsWrapper} >
				{showCloseMsg ?
					<>
						<Button onClick={onClose} variant="outlined" color="primary" className={classes.closeAccountBtns}>
							{t('nevermind')}
						</Button>
						<Button onClick={() => { setShowCloseMsg(false); }} color="primary" variant="contained" className={clsx(classes.closeAccountBtns, classes.error)}>
							{primaryBtnText}
						</Button>
					</>
					:
					<>
						<Button onClick={onClose} variant="outlined" className={classes.cancelBtn}>
							{t('cancel')}
						</Button>
						<Button onClick={handleOnSubmit} color="primary" variant="contained" className={clsx({ [classes.error]: showCloseAccount })}>
							{primaryBtnText}
						</Button>
					</>
				}
			</DialogActions>
		</Dialog>
	);
};

export default PasswordConfirmModal;
