/* eslint-disable react/display-name */
import React, { useState } from 'react';
import clsx from 'clsx';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { ReactComponent as Baseball } from 'icons/baseball.svg';
import { ReactComponent as Basketball } from 'icons/basketball.svg';
import { ReactComponent as Bowling } from 'icons/bowling-ball.svg';
import { ReactComponent as Cricket } from 'icons/cricket-bat.svg';
import { ReactComponent as Equestrian } from 'icons/equestrian-helmet.svg';
import { ReactComponent as CrossCountry } from 'icons/run-shoes.svg';
import { ReactComponent as Fencing } from 'icons/fencing-swords.svg';
import { ReactComponent as FieldHockey } from 'icons/field-hockey.svg';
import { ReactComponent as Football } from 'icons/football-headguard.svg';
import { ReactComponent as Golf } from 'icons/golf-strike.svg';
import { ReactComponent as Gymnastics } from 'icons/rings.svg';
import { ReactComponent as IceHockey } from 'icons/hockey-stick.svg';
import { ReactComponent as Lacrosse } from 'icons/racket.svg';
import { ReactComponent as Rifle } from 'icons/target.svg';
import { ReactComponent as Rowing } from 'icons/rowing-oars.svg';
import { ReactComponent as Rugby } from 'icons/rugby-ball.svg';
import { ReactComponent as Skiing } from 'icons/skiing.svg';
import { ReactComponent as Softball } from 'icons/baseball.svg';
import { ReactComponent as SwimDive } from 'icons/swimming.svg';
import { ReactComponent as Soccer } from 'icons/soccer-ball.svg';
import { ReactComponent as Tennis } from 'icons/tennis-ball.svg';
import { ReactComponent as TrackField } from 'icons/athletics.svg';
import { ReactComponent as Volleyball } from 'icons/volleyball.svg';
import { ReactComponent as WaterPolo } from 'icons/water-polo-ball.svg';
import { ReactComponent as Wrestling } from 'icons/wrestler.svg';

import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import styles from './styles';
const useStyles = makeStyles(styles);

const SportsGridSelect = ({ sports, onSubmit, onBackClick, submitBtnText }) => {
	const theme = useTheme();
	const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();
	const { t } = useTranslation();
	const [selected, setSelected] = useState([]);
	
	const iconMap = {
		baseball: () => (<Baseball className={classes.sportIcon} />),
		basketball: () => (<Basketball className={classes.sportIcon} />),
		bowling: () => (<Bowling className={classes.sportIcon} />),
		cricket: () => (<Cricket className={classes.sportIcon} />),
		cross_country: () => (<CrossCountry className={classes.sportIcon} />),
		equestrian: () => (<Equestrian className={classes.sportIcon} />),
		fencing: () => (<Fencing className={classes.sportIcon} />),
		field_hockey: () => (<FieldHockey className={clsx(classes.sportIcon, classes.sportIconWithFill)} />),
		football: () => (<Football className={classes.sportIcon} />),
		golf: () => (<Golf className={classes.sportIcon} />),
		gymnastics: () => (<Gymnastics className={classes.sportIcon} />),
		ice_hockey: () => (<IceHockey className={classes.sportIcon} />),
		lacrosse: () => (<Lacrosse className={clsx(classes.sportIcon, classes.sportIconWithFill)} />),
		rifle: () => (<Rifle className={clsx(classes.sportIcon, classes.sportIconWithFill)} />),
		rowing: () => (<Rowing className={classes.sportIcon} />),
		rugby: () => (<Rugby className={classes.sportIcon} />),
		skiing: () => (<Skiing className={classes.sportIcon} />),
		soccer: () => (<Soccer className={classes.sportIcon} />),
		softball: () => (<Softball className={classes.sportIcon} />),
		swim_dive: () => (<SwimDive className={classes.sportIcon} />),
		tennis: () => (<Tennis className={classes.sportIcon} />),
		track_field: () => (<TrackField className={classes.sportIcon} />),
		volleyball: () => (<Volleyball className={classes.sportIcon} />),
		water_polo: () => (<WaterPolo className={classes.sportIcon} />),
		wrestling: () => (<Wrestling className={clsx(classes.sportIcon, classes.sportIconWithFill)} />)
	};
	const handleOnButtonClick = (sport) => {
		if (selected.includes(sport)) {
			setSelected(selected.filter((s) => s !== sport));
		} else {
			setSelected([...selected, sport]);
		}
	};
	const handleOnSubmitClick = () => {
		onSubmit(selected);
	};
	return (
		<div className={classes.sportListWrapper}>
			<div className={classes.gridList}>
				{sports.map((sport) => (
					<ButtonBase
						focusVisibleClassName={classes.selected}
						className={clsx({ [classes.selected]: selected.includes(sport) })}
						classes={{ root: classes.sportsButton }}
						key={sport}
						onClick={() => { handleOnButtonClick(sport); }}
					>
						{iconMap[sport] && iconMap[sport]()}
						<Typography variant="body2" component="span" className={classes.buttonText}>{t(sport)}</Typography>
					</ButtonBase>
				))}
			</div>
			<div className={classes.actionWrapper}>
				<Button variant="contained" color="primary" fullWidth={matchesSm} className={classes.finishButton} onClick={handleOnSubmitClick}>{submitBtnText}</Button>
				<Button color="primary" startIcon={<ChevronLeftIcon />} className={classes.backButton} onClick={onBackClick}>{t('previous')}</Button>
			</div>
		</div>
	);
};

export default SportsGridSelect;
