export default () => ({
	helperTextWrapper: {
		display: 'flex',
		height: '31px',
	},
	errorText: {
		marginRight: '0px',
		fontSize: '16px',
		color: '#ff0045'
	},
	asterisk: {
		lineHeight: 1.4
	},
	inputWrapper: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		'& .MuiInputBase-root': {
			width: '100%'
		}
	},
	input: {
		width:'100%'
	},
	suffixLabel: {
		marginLeft: '8px'
	}
});
