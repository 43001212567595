export default () => ({
	root: {
		display: 'flex',
		alignItems: 'center'
	},
	logo: props => ({
		width: props.logoSize,
		height: props.logoSize
	}),
	logoText:props => ({
		marginLeft: '10px',
		fontFamily: 'Gira Sans',
		fontSize:  props.fontSize,
		letterSpacing: '0.1px',
		fontWeight: 600
	})
});
