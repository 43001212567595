import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
const useStyles = makeStyles(styles);

const FormInput = ({
	label,
	suffixLabel,
	classes,
	required,
	id,
	select,
	selectOptions,
	error,
	errorText,
	helperText,
	dense,
	...inputProps
}) => {
	const inClasses = useStyles();
	return (
		<FormControl fullWidth required={required} variant="outlined" className={classes.formControl}>
			{label && <FormLabel required={required} htmlFor={id}>{label}</FormLabel>}
			<div className={inClasses.inputWrapper}>
				{select ?
					<Select
						IconComponent={KeyboardArrowDownIcon}
						variant="outlined"
						required={required}
						error={error}
						{...inputProps}
					>{selectOptions}</Select>
					:
					<OutlinedInput error={error} required={required} {...inputProps} />
				}
				{suffixLabel && <FormLabel htmlFor={id} className={inClasses.suffixLabel} >{suffixLabel}</FormLabel>}
			</div>
			{!dense ?
				<div className={inClasses.helperTextWrapper}>
					{helperText && <FormHelperText required={required}>{helperText}</FormHelperText>}
					{(error && errorText) &&
						<FormHelperText className={inClasses.errorText} error={error}>
							<>
								<span className={inClasses.asterisk}>*</span>
								{errorText}
							</>
						</FormHelperText>
					}
				</div>
				: null
			}
		</FormControl>
	);
};
FormInput.defaultProps = {
	classes: {
		formControl: null
	},
	dense: false
};
export default FormInput;
