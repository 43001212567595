import React from 'react';
import Button from '@material-ui/core/Button';
import FormInput from 'components/FormInput';
import SignUpOption from 'components/SignUpOption';

const PasswordForm = ({
	t,
	classes,
	onPasswordInputChange,
	onResetPasswordClick
}) => (
	<form className={classes.resetForm} noValidate>
		<FormInput
			label={t('password')}
			helperText={t('minimum_characters')}
			id="reset-password"
			fullWidth
			type="password"
			onChange={onPasswordInputChange}
			classes={{formControl: classes.passwordControl}}
		/>
		<Button
			variant="contained"
			color="primary"
			fullWidth
			className={classes.passwordBtn}
			onClick={onResetPasswordClick}
		>{t('reset_password')}</Button>
		<SignUpOption  className={classes.signupWrapper} />
	</form>
);

export default PasswordForm;
