import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Fade from '@material-ui/core/Fade';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as GearIcon } from 'icons/Gear_icon.svg';
import { ReactComponent as BackArrowIcon } from 'icons/Back_arrow_icon.svg';
import clsx from 'clsx';

import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import {
	Link as RouterLink,
} from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import styles from './styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
const useStyles = makeStyles(styles);

const ProfileLayout = ({
	backgroundImageUrl,
	defaultBackgroundImg,
	profileImageUrl,
	profileImageAlt,
	profileHeader,
	activeTab,
	onTabChange,
	tabs,
	title,
	settingsButtonRef,
	onSettingsButtonClick,
	onActionButtonClick,
	actionButtonText,
	backButtonText,
	backButtonTo
}) => {
	const theme = useTheme();
	const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles({ defaultBackgroundImg });
	const { t } = useTranslation();
	const [showScrollTabs, setShowScrollTabs] = useState(false);
	const [scrollTabPos, setScrollTabPos] = useState(0);
	useScrollPosition(
		({ currPos }) => {
			if (currPos.y < -225) {
				setShowScrollTabs(true);
				const topPos = -225 - currPos.y;
				if (topPos < 80) {
					setScrollTabPos(topPos);
				} else {
					setScrollTabPos(80);
				}
			} else {
				setShowScrollTabs(false);
			}
		}, [showScrollTabs]);
	const getTabs = () => tabs.filter(tab => !tab.hidden).map((tab) => (
		<Tab
			key={tab.value}
			value={tab.value}
			label={t(tab.value)}
			disableRipple
			classes={{
				root: classes.profileTab,
				selected: classes.profileTabSelected
			}}
		/>
	));
	const getTabContent = () => tabs.map((tab) => (
		<Fade in={activeTab === tab.value} timeout={500} key={tab.value}>
			<div className={clsx({ [classes.hiddenPanel]: (activeTab !== tab.value) })}>
				{tab.component}
			</div>
		</Fade>
	));
	const getAboutTab = () => {
		const tab = tabs.filter((tab) => tab.value === 'about' || tab.value === 'bio');
		if (tab.length) {
			return tab[0].component;
		}
	};
	const hideTabs = tabs.some((tab) => tab.value === activeTab && tab.hidden);
	const settingsIconButton = (
		<IconButton aria-label="settings" onClick={onSettingsButtonClick} className={classes.settingsButton} ref={settingsButtonRef}>
			<GearIcon />
		</IconButton>
	);
	return (
		<div className={classes.root}>
			<div className={clsx(classes.backgroundImgWrapper, { [classes.withImage]: Boolean(backgroundImageUrl) })}>
				{backgroundImageUrl &&
					<img alt="profile background" src={backgroundImageUrl} className={classes.heroImage}></img>
				}
			</div>
			<div className={classes.mainContainer}>
				{!matchesSm && <Paper className={classes.sidebarContainerBg} />}
				<div className={classes.sidebarContainer}>
					<Avatar alt={profileImageAlt} src={profileImageUrl} className={classes.avatar} />
					{profileHeader}
					{!matchesSm && getAboutTab()}
					{matchesSm && settingsIconButton}
				</div>
				{!matchesSm &&
					<div className={clsx(classes.scrollTabContainer, { [classes.showScrollTab]: showScrollTabs })} style={{ top: scrollTabPos }}>
						<div className={classes.scrollTabAvatarWrapper}>
							<Avatar alt={profileImageAlt} src={profileImageUrl} className={classes.scrollTabAvatar} />
							<Typography variant="h6" className={classes.scrollTabTitle}>{title}</Typography>
						</div>
						{!hideTabs &&
							<Paper className={clsx(classes.tabWrapper, classes.scrollTabWrapper)} elevation={0}>
								<Tabs
									value={activeTab}
									onChange={onTabChange}
									indicatorColor="primary"
									textColor="primary"
									className={classes.tabs}
								>
									{tabs && getTabs()}
								</Tabs>
							</Paper>
						}
					</div>
				}
				<div className={classes.tabContainer}>
					{!hideTabs &&
						<Paper className={classes.tabWrapper} elevation={0}>
							<Tabs
								value={activeTab}
								onChange={onTabChange}
								indicatorColor="primary"
								textColor="primary"
								className={classes.tabs}
								variant="scrollable"
							>
								{tabs && getTabs()}
							</Tabs>
							{!matchesSm &&
								<div className={classes.rightSide}>
									{backButtonTo &&
										<Button
											className={classes.backButton}
											component={RouterLink}
											color="primary"
											startIcon={<BackArrowIcon />}
											to={backButtonTo}
										>{backButtonText}</Button>
									}
									{onSettingsButtonClick && settingsIconButton}
									{onActionButtonClick &&
										<Button variant="outlined" color="primary" onClick={onActionButtonClick} className={classes.actionButton}>{actionButtonText}</Button>
									}
								</div>
							}
						</Paper>
					}
					<div className={classes.tabContent}>
						{tabs && getTabContent()}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProfileLayout;
