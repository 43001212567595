import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import SourcsansproTtf from './fonts/SourceSansPro-Regular.ttf';
import SourcsansproLightTtf from './fonts/SourceSansPro-Light.ttf';
import SourcsansproSemiBoldTtf from './fonts/SourceSansPro-SemiBold.ttf';
import GiraSansOtf from './fonts/GiraSans.otf';

const Sourcsanspro = {
	fontFamily: 'Source Sans Pro',
	fontStyle: 'normal',
	fontWeight: 400,
	src: `url(${SourcsansproTtf})`
};
const SourcsansproLight = {
	fontFamily: 'Source Sans Pro Light',
	fontStyle: 'normal',
	fontWeight: 300,
	src: `url(${SourcsansproLightTtf})`
};
const SourcsansproSemiBold = {
	fontFamily: 'Source Sans Pro Semi Bold',
	fontStyle: 'normal',
	fontWeight: 600,
	src: `url(${SourcsansproSemiBoldTtf})`
};
const GiraSans = {
	fontFamily: 'Gira Sans',
	fontStyle: 'normal',
	fontWeight: 500,
	src: `url(${GiraSansOtf})`
};
export default responsiveFontSizes(createMuiTheme({
	typography: {
		// In Chinese and Japanese the characters are usually larger,
		// so a smaller fontsize may be appropriate.
		fontSize: 14,
		fontFamily: 'Source Sans Pro',
		subtitle1: {
			fontFamily: 'Source Sans Pro Light',
			lineHeight: '1.28',
			letterSpacing: ' -0.36px',
			fontSize: '18px'
		},
		subtitle2: {
			fontSize: '18px',
			lineHeight: '1.28',
			letterSpacing: ' -0.36px'
		},
		h1: {
			fontFamily: 'Source Sans Pro Semi Bold'
		},
		h2: {
			fontFamily: 'Source Sans Pro Semi Bold',
			letterSpacing: '-1.2px'

		},
		h3: {
			fontFamily: 'Source Sans Pro Semi Bold'
		},
		h4: {
			fontFamily: 'Source Sans Pro Semi Bold',
			fontSize: '32px',
			letterSpacing: '-0.64px'
		},
		h5: {
			fontFamily: 'Source Sans Pro Semi Bold',
			letterSpacing: '-0.52px',
		},
		h6: {
			fontFamily: 'Source Sans Pro Semi Bold'
		},
		button: {
			fontFamily: 'Source Sans Pro Semi Bold'
		}
	},
	props: {
		// Name of the component ⚛️
		MuiButtonBase: {
			// The default props to change
			disableRipple: true
		},
		MuiButton: {
			disableElevation: true,
		}
	},
	overrides: {
		MuiCssBaseline: {
			'@global': {
				'@font-face': [Sourcsanspro, SourcsansproLight, SourcsansproSemiBold, GiraSans],
			},
		},
		MuiAvatar: {
			root: {
				border: ' 4px solid #ffffff',
				boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)'
			},
			colorDefault: {
				color: '#f2f2f2',
				backgroundColor: '#fff',
			}
		},
		MuiButton: {
			root: {
				fontSize: '16px',
				textTransform: 'none',
				padding: '11px 16px',
				minWidth: '167px'
			},
			containedPrimary: {
				'&:hover': {
					backgroundColor: '#0043bf'
				},
				'&.Mui-disabled': {
					backgroundColor: '#5d8cf7',
					color: '#fff'
				}
			},
			outlinedPrimary: {
				'&:hover': {
					color: '#0059ff'
				}
			},
			outlined: {
				color: '#7c7c7c',
				padding: '11px 16px',
				borderColor: '#d6d6d6',
				backgroundColor: '#fff',
				'&:hover': {
					backgroundColor: '#fff',
					borderColor: '#7c7c7c',
					color: '#7c7c7c'
				},
				'&.Mui-disabled': {
					borderColor: '#e6e6e6',
					color: '#d6d6d6'
				}
			}
		},
		MuiFormLabel: {
			root: {
				fontFamily: 'Source Sans Pro Semi Bold',
				color: '#00171f',
				marginBottom: '10px',
				'&$focused': {
					color: '#00171f'
				}
			}
		},
		MuiFormControlLabel: {
			label: {
				fontFamily: 'Source Sans Pro Semi Bold',
			}
		},
		MuiOutlinedInput: {
			root: {
				backgroundColor: '#FFF',
				'&.Mui-error': {
					'& .MuiOutlinedInput-notchedOutline': {
						borderColor: '#ff0045',
					}
				}
			},
			input: {
				padding: '15.5px 16px'
			},
			notchedOutline: {
				borderWidth: '1px !important',
				borderColor: '#d6d6d6',
			},
		},
		MuiSelect: {
			iconOutlined: {
				color: '#3a3e4b'
			},
			select: {
				'&:focus': {
					backgroundColor: '#fff'
				}
			}
		},
		MuiFormHelperText: {
			contained: {
				margin: '5px 0px 0'
			}
		},
		MuiPaper: {
			elevation1: {
				boxShadow: '0 0 3px 0 #00000029'
			}
		},
		MuiAlert: {
			root: {
				padding: '3px 16px;'
			},
			standardError: {
				color: '#ff0045',
				border: '1px solid #ff0045',
				backgroundColor: 'rgba(255, 0, 69, 0.07)'
			},
			standardSuccess: {
				color: '#11d187',
				border: '1px solid #11d187',
				backgroundColor: 'rgba(17, 209, 135, 0.07)'
			},
			message: {
				flex: 1,
				textAlign: 'center',
				fontSize: '18px',
				fontFamily: 'Source Sans Pro Semi Bold',
				'& .MuiLink-root': {
					fontSize: '18px',
					fontFamily: 'Source Sans Pro',
					verticalAlign: 'inherit'
				}
			}
		},
		MuiTabs: {
			indicator: {
				display: 'none'
			},
			root: {
				minHeight: '0px'
			}
		},
		MuiTab: {
			root: {
				'@media (min-width: 600px)': {
					minWidth: '0px'
				},
				'&.Mui-selected .MuiTab-wrapper': {
					borderBottom: ' 4px solid'
				},
				padding: '0px',
				minHeight: '35px',
				marginRight: '30px',
				'&:last-child': {
					marginRight: '0px'
				}
			},
			wrapper: {
				textTransform: 'none',
				fontSize: '18px',
				borderBottom: ' 4px solid transparent',
			}
		},
		MuiDialogTitle: {
			root: {
				padding: '32px 16px 36px 36px'
			}
		},
		MuiDialogActions: {
			root: {
				justifyContent: 'center'
			},
		}
	},
	palette: {
		primary: {
			light: '#337aff',
			main: '#0059ff',
			dark: '#003eb2',
			contrastText: '#fff',
		},
		secondary: {
			light: '#8845f4',
			main: '#6b17f2',
			dark: '#4a10a9',
			contrastText: '#000',
		},
		error: {
			main: '#ff0045',
			dark: '#e8003f'
		},
		success: {
			main: '#11d187'
		},
		background: {
			default: '#f8f8f8'
		},
		text: {
			primary: '#00171f',
			secondary: '#7c7c7c'
		}
	},
}));
