import React from 'react';
import KList from 'components/KList';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
const useStyles = makeStyles(styles);

const Teams = ({
	organizations,
	teams,
	onCreateOrgClick
}) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const translateTeamSport = (teams) => teams.map((team) => ({ ...team, secondaryText: t(team.secondaryText) }));
	return (
		<div className={classes.teams}>
			<div className={classes.section}>
				<div className={classes.header}>
					<Typography variant="h6" className={classes.title}>{t('organizations')}</Typography>
					<Divider />
				</div>
				<Paper className={classes.listWrapper}>
					<KList
						items={organizations}
						links
						onEmptyActionButtonClick={onCreateOrgClick}
						emptyListText={t('you_have_no_organizations')}
						emptyListButtonText={t('create_organization')}
					/>
				</Paper>
			</div>
			<div className={classes.section}>
				<div className={classes.header}>
					<Typography variant="h6" className={classes.title}>{t('teams')}</Typography>
					<Divider />
				</div>
				<Paper className={classes.listWrapper}>
					<KList
						items={translateTeamSport(teams)}
						links
						emptyListText={t('you_have_no_teams')}
					/>
				</Paper>
			</div>
		</div>
	);
};

export default Teams;
