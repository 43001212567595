import { connect } from 'react-redux';
import flow from 'lodash.flow';
import AccountSettings from './AccountSettings';
import { updateEmail, updatePassword, deleteAccount, resetSuccess } from 'reactive-core';
export default flow(
	connect(
		({
			authevents: {
				authErrorCode,
				emailUpdateSuccess,
				updatingEmail,
				passwordUpdateSuccess,
				updatingPassword,
			}
		}, {
			user
		}) => ({
			user,
			authErrorCode,
			emailUpdateSuccess,
			updatingEmail,
			passwordUpdateSuccess,
			updatingPassword,

		}),
		(dispatch) => ({
			onSubmitChangeEmail: (udpateEmailData) => dispatch(updateEmail(udpateEmailData)),
			onSubmitChangePassword: (passwordData) => dispatch(updatePassword(passwordData)),
			onDeleteAccount: (deleteData) => dispatch(deleteAccount(deleteData)),
			resetSuccess: () => dispatch(resetSuccess())
		}))
)(AccountSettings);
