import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import KList from 'components/KList';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import RemoveEntityModal from 'components/RemoveEntityModal';
import AddOrgMemberModal from './AddOrgMemberModal';
import Toast from 'components/Toast';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { INVITATION_PENDING } from 'utils/constants';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styles from './styles';
const useStyles = makeStyles(styles);
const OrgMembers = ({
	members,
	fetchOrgMembers,
	onCancel,
	removeOrgAdmin,
	cancelOrgAdminInvite,
	resendOrgAdminInvite,
	sendOrgAdminInviteError,
	clearTeamOrgSuccess,
	sendOrgAdminInviteSuccess,
	sendOrgAdminInvite,
	removeOrgAdminSuccess,
	cancelOrgAdminInviteSuccess,
	resendOrgAdminInviteSuccess
}) => {
	const theme = useTheme();
	const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();
	const history = useHistory();
	const { t } = useTranslation();
	let { orgId } = useParams();
	const [showAddOrgMember, setShowAddOrgMember] = useState(false);
	const [showRemoveOrgAdmin, setShowRemoveOrgAdmin] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);
	const [inviteError, setInivteError] = useState(null);
	const [toastProps, setToastProps] = useState({
		open: false,
		message: '',
		severity: ''
	});


	useEffect(() => {
		fetchOrgMembers({
			orgId, filters: {
				isAdmin: true,
				inviteStatus: 'invitation_pending'
			}
		});
	}, []);
	useEffect(() => {
		if (sendOrgAdminInviteSuccess) {
			setShowAddOrgMember(false);
			setToastProps({
				open: true,
				message: t('org_admin_invite_sent'),
				severity: 'success'
			});
			clearTeamOrgSuccess();
		} else if (sendOrgAdminInviteError) {
			setInivteError(sendOrgAdminInviteError);
			clearTeamOrgSuccess();
		}
		
	}, [sendOrgAdminInviteError, sendOrgAdminInviteSuccess]);
	useEffect(() => {
		if (removeOrgAdminSuccess) {
			setShowRemoveOrgAdmin(false);
			setSelectedUser(null);
			setToastProps({
				open: true,
				message: t('org_admin_removed'),
				severity: 'success'
			});
			clearTeamOrgSuccess();
		}
	}, [removeOrgAdminSuccess]);
	useEffect(() => {
		if (cancelOrgAdminInviteSuccess) {
			setToastProps({
				open: true,
				message: t('org_admin_invite_canceled'),
				severity: 'success'
			});
			clearTeamOrgSuccess();
		}
	}, [cancelOrgAdminInviteSuccess]);
	useEffect(() => {
		if (resendOrgAdminInviteSuccess) {
			setToastProps({
				open: true,
				message: t('org_admin_invite_resent'),
				severity: 'success'
			});
			clearTeamOrgSuccess();
		}
	}, [resendOrgAdminInviteSuccess]);

	const handleOnAddOrgMember = (inviteData) => {
		sendOrgAdminInvite({
			...inviteData,
			entityId: orgId
		});
	};
	const handleOnRemoveOrgAdmin = () => {
		removeOrgAdmin({orgId, id: selectedUser.id});
	};
	const handleOnCloseAddOrgMemberModal = () => {
		setShowAddOrgMember(false);
		setInivteError(null);
	};
	const handleCloseRemoveOrgAdmin = () => {
		setShowRemoveOrgAdmin(false);
		setSelectedUser(null);
	};
	const handleOnremoveFromAdmin = (member) => {
		setSelectedUser(member);
		setShowRemoveOrgAdmin(true);
	};
	const handleCancelInvitation = (member) => {
		cancelOrgAdminInvite(member.id);
	};
	const handleResendInvitation = (member) => {
		resendOrgAdminInvite(member.id);
	};
	const handleCopyInvitation = (member) => {
		copy(`${window.location.origin}/invite/${member.id}`);
		setToastProps({
			open: true,
			message: t('invite_link_copied'),
			severity: 'info'
		});
	};
	const onToastClose = (e, reason) => {
		if (reason !== 'clickaway') {
			setToastProps({
				open: false,
				message: '',
				severity: ''
			});
		}
	};
	const getMembers = () =>
		members && members.map((member) => member.inviteStatus ?
			({
				id: member.userId,
				primaryText: member.email,
				avatar: null,
				showBadge: member.inviteStatus === INVITATION_PENDING,
				disabled: true,
				status: t(member.inviteStatus),
				menuItems: [
					{
						onClick: () => { handleCopyInvitation(member); },
						label: t('copy_invitation_link')
					},
					{
						onClick: () => { handleResendInvitation(member); },
						label: t('resend_invitation')
					},
					{
						onClick: () => { handleCancelInvitation(member); },
						label: t('cancel_invitation')
					},
				]
			})
			: ({
				id: member.userId,
				primaryText: `${member.firstName} ${member.lastName}`,
				secondaryText: member.email,
				onClick: (userId) => { history.push(`/user/${userId}`); },
				menuItems: [
					{
						onClick: () => { handleOnremoveFromAdmin(member); },
						label: t('remove_from_admin')
					},
				]
			})
		);
	const actionButtons = (
		<div className={classes.actionButtons}>
			<Button onClick={onCancel} variant="outlined" className={classes.cancelBtn}>
				{t('cancel')}
			</Button>
			<Button onClick={() => { setShowAddOrgMember(true); }} color="primary" variant="contained" className={classes.addTeamMemberButton}>
				{t('add_admin')}
			</Button>
		</div>
	);

	return (
		<div className={classes.orgMembers}>
			<Typography variant="h5">{t('admins')}</Typography>
			{actionButtons}
			<Paper className={classes.listWrapper}>
				<KList
					items={getMembers()}
				/>
			</Paper>
			<AddOrgMemberModal
				show={showAddOrgMember}
				onClose={handleOnCloseAddOrgMemberModal}
				onSubmit={handleOnAddOrgMember}
				error={inviteError}
			/>
			<RemoveEntityModal
				onClose={handleCloseRemoveOrgAdmin}
				show={showRemoveOrgAdmin}
				entity={selectedUser && { ...selectedUser, name: `${selectedUser.firstName} ${selectedUser.lastName}` }}
				onSubmit={handleOnRemoveOrgAdmin}
				title={t('remove_admin_from_organization')}
				isWarning={members && members.length === 1}
				warningText={t('before_removing_yourself_as_an_admin')}
				areYouSureText={t('are_you_sure_you_want_remove_this_user_admin')}
				submitButtonText={matchesSm ? t('remove') : t('remove_admin')}
				submitDisabled={members && members.length === 1}
			/>
			<Toast
				{...toastProps}
				onClose={onToastClose}
			/>
		</div>
	);
};

export default OrgMembers;
