import React, { useEffect } from 'react';
import AuthSplashForm from 'components/AuthSplashForm';
import splash from 'img/signup-splash.png';
import { useQuery } from 'utils/hooks/customHooks';
import Button from '@material-ui/core/Button';
import { FB_AUTH_USER_EMAIL_NOT_VERIFIED } from 'utils/constants';
import SignUpForm from './SignUpForm';
import { Trans } from 'react-i18next';
import {
	Link as RouterLink,
	useHistory
} from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
const useStyles = makeStyles(styles);

const SignUp = ({
	onSubmitSignUp,
	resetSuccess,
	signUpUserSuccess,
	signInUserSuccess,
	emailUpdateSuccess,
	authErrorCode,
	emailCodeVerified,
	verifyEmailActionCode,
	isVerify,
	isSignedIn
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const query = useQuery();
	const history = useHistory();
	const inviteId = query.get('invite');
	const isParent = query.get('isParent');
	useEffect(() => {
		resetSuccess();
		if (isSignedIn) {
			history.replace(`/${history.location.search}`);
		}
		if (isVerify) {
			verifyEmailActionCode(query.get('oobCode'));
		}
	}, []);
	useEffect(() => {
		if (signInUserSuccess) {
			history.push(`/${history.location.search}`);
		}
	}, [signInUserSuccess]);
	useEffect(() => {
		if (isVerify && authErrorCode) {
			history.replace(`/sign-in${history.location.search}`);
		}
	}, [isVerify, authErrorCode]);
	let formTitle = '';
	let formSubTitle = '';
	let rightSide = null;
	const showSent = signUpUserSuccess
		|| emailUpdateSuccess
		|| (history.location.state && history.location.state.reason && history.location.state.reason === FB_AUTH_USER_EMAIL_NOT_VERIFIED);

	const onSignUpClick = (signUpData) => {
		if (inviteId) {
			signUpData['inviteId'] = inviteId;
		}
		if (isParent === 'true') {
			signUpData['isParent'] = true;
		}
		onSubmitSignUp(signUpData);
	};
	if ((isVerify && emailCodeVerified) || showSent) {
		formTitle = showSent ? t('activation_link_sent') : t('email_confirmed');
		formSubTitle = showSent ? t('check_email_to_activate') : t('welcome_to_kinectem');
		rightSide = (
			<Button
				component={RouterLink}
				to={`/sign-in${history.location.search}`}
				variant="contained"
				color="primary"
				className={classes.loginLinkBtn}
			>{t('log_in')}</Button>
		);
		window.history.pushState(null, '');
	} else if (!isVerify) {
		formTitle = !inviteId ? t('sign_up_your_org') : (isParent ? t('parent_sign_up') : t('sign_up'));
		formSubTitle = !inviteId ?
			(
				<Trans t={t} i18nKey="kinectem_is_an_exclusive_invite_only">
					Kinectem is an <span className={classes.boldText}>exclusive, invite-only platform.</span> Only an organization admin can sign up first. If you are not your organization’s admin, please ask them to sign up and then they will invite you to join.
				</Trans>
			)
			:
			(isParent ? t('first_you_create_your_account') : t('create_your_account_and_join'));
		rightSide = (
			<SignUpForm
				t={t}
				classes={classes}
				onSubmitClick={onSignUpClick}
			/>
		);
	}
	return (
		<AuthSplashForm
			formTitle={formTitle}
			formSubTitle={formSubTitle}
			splashImg={splash}
			classes={(showSent || isVerify) && classes}
			message={authErrorCode && t(authErrorCode)}
		>
			{isParent &&
				<Typography className={classes.notParentText} variant="subtitle1">
					<Trans t={t} i18nKey="not_a_parent">
						Not a parent? <Link component={RouterLink} to={`/sign-in${inviteId ? `?invite=${inviteId}` : ''}`} color="primary" className={classes.loginLink}>Sign up as a coach or player.</Link>
					</Trans>
				</Typography>
			}
			{rightSide}
		</AuthSplashForm>
	);
};

export default SignUp;
