export default (theme) => ({
	mediaCollection: {
		marginTop: '24px',
		marginBottom: '24px',
		width: '100%'
	},
	header: {
		marginBottom: '24px'
	},
	title: {
		fontSize: '18px',
		letterSpacing: '-0.32px',
		marginBottom: '11px'
	},
	skeletonWrapper: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	emptyText: {
		color: theme.palette.text.secondary
	},
	emptyButton: {
		marginTop: '10px',
		minWidth: '167px'
	},
	hiddenPanel: {
		display: 'none !important'
	},
	emptyWrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		minHeight: '190px'
	},
	skeletonRect: {
		marginRight: '16px',
		'&:last-child': {
			marginRight: '0px'
		}
	},
	itemList: {
		display: 'flex',
		flexWrap: 'wrap',
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center'
		}
	},
	itemWrapper: {
		width: '330px',
		height: '216px',
		marginRight: '16px',
		marginBottom: '16px',
		
		textAlign: 'left',
		'&:first-child': {
			marginLeft: '0px'
		},
		'& .MuiIconButton-root': {
			display: 'none'	
		},
		'&:hover': {
			'& .MuiCard-root': {
				backgroundColor: '#f2f2f2',
				'& .MuiIconButton-root': {
					display: 'inline-flex'
				}
			}
		},
		[theme.breakpoints.down('sm')]: {
			marginRight: '0px',
			'& .MuiIconButton-root': {
				display: 'inline-flex'
			}
		}
	},
	itemCard: {
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	itemHeader: {
		height: '59px',
		paddingBottom: '7px'
	},
	editItemButton: {
		height: '40px',
		width: '40px',
		backgroundColor: theme.palette.background.paper,
		'&:hover': {
			backgroundColor: theme.palette.background.paper
		}
	},
	itemContent: {
		paddingTop: '0px',
		paddingBottom: '0px',
		flex: 1
	},
	itemBody: {
		fontSize: '16px',
		lineHeight: '20px',
		letterSpacing: '-0.32px'
	},
	itemActions: {
		padding: '16px'
	},
	itemDate: {
		fontSize: '14px'
	}
});
