import React, { useState, useRef } from 'react';
import Button from '@material-ui/core/Button';
import FormInput from 'components/FormInput';
import ProfileImageEdit from 'components/ProfileImageEdit';
import {
	teamGenderOptions,
	stateOptions,
	ageGroupOptions,
	sportOptions
} from 'utils/selectOptions';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import { Typography } from '@material-ui/core';
const useStyles = makeStyles(styles);

const TeamSettings = ({
	team,
	onSubmit,
	onCancel,
}) => {
	const theme = useTheme();
	const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();
	const { t } = useTranslation();

	const refs = {
		name: useRef(null),
		gender: useRef(null),
		ageGroup: useRef(null),
		websiteUrl: useRef(null),
		sport: useRef(null),
		city: useRef(null),
		state: useRef(null),
		zipcode: useRef(null),
		about: useRef(null)
	};
	const [profileImg, setProfileImg] = useState(null);
	const [validateError, setValidateError] = useState(false);

	const optionalFields = ['about', 'websiteUrl'];
	const getUpdatedData = () => {
		const updated = { id: team.id };
		Object.keys(refs).forEach((key) => {
			if (refs[key].current.value !== team[key] && team[key] !== null || team[key] === null && refs[key].current.value !== '') {
				updated[key] = refs[key].current.value;
			}
		});
		if (profileImg) {
			updated.profileImg = profileImg;
		}
		return updated;
	};
	const handleOnSubmit = () => {
		const required = Object.keys(refs).filter((key) => !optionalFields.includes(key));
		if (required.some((key) => !refs[key].current.value)) {
			setValidateError(true);
		} else {
			onSubmit(getUpdatedData());
		}
	};
	return (
		<div className={classes.teamSettings}>
			<Typography variant="h5">{t('team_settings')}</Typography>
			<div className={classes.actionButtons}>
				<Button onClick={onCancel} variant="outlined" className={classes.cancelBtn}>
					{t('cancel')}
				</Button>
				<Button onClick={handleOnSubmit} color="primary" variant="contained" className={classes.submitBtn}>
					{t('save')}
				</Button>
			</div>
			<div className={classes.settingsWrapper}>
				<div className={classes.pofileImgWrapper}>
					<ProfileImageEdit
						size={matchesSm ? '145' : '190'}
						showIcon
						onUpdateAvatar={(imgObj) => { setProfileImg(imgObj.file); }}
						avatarImageUrl={team.profileImgUrl}
						buttonLabel={t('choose_logo')}
						uploadTitle={t('add_team_logo')}
					/>
				</div>
				<div className={classes.formWrapper}>
					<FormInput
						label={t('team_name')}
						required
						id="team_name"
						errorText={t('field_required')}
						type="text"
						inputRef={refs.name}
						defaultValue={team.name}
						error={validateError && (refs.name && !refs.name.current.value)}
						classes={{ formControl: classes.name }}
					/>
					<div className={matchesSm ? classes.mobileFormRow : classes.formRow}>
						<FormInput
							label={t('gender')}
							select
							required
							selectOptions={teamGenderOptions(t, true)}
							native
							inputRef={refs.gender}
							defaultValue={team.gender}
							errorText={t('field_required')}
							classes={{ formControl: classes.gender }}
						/>
						<FormInput
							label={t('age_group')}
							select
							required
							selectOptions={ageGroupOptions(t, true)}
							native
							inputRef={refs.ageGroup}
							defaultValue={team.ageGroup}
							errorText={t('field_required')}
							classes={{ formControl: classes.ageGroup }}
						/>
					</div>
					<div className={classes.formRow}>
						<FormInput
							label={t('team_website')}
							id="team_website"
							defaultValue={team.websiteUrl}
							type="text"
							inputRef={refs.websiteUrl}
							classes={{ formControl: classes.websiteUrl }}
						/>
						<FormInput
							label={t('sport')}
							select
							required
							selectOptions={sportOptions(t, true)}
							native
							inputRef={refs.sport}
							defaultValue={team.sport}
							errorText={t('field_required')}
							fullWidth={false}
							classes={{ formControl: classes.sport }}
						/>
					</div>
					<div className={classes.formRow}>
						<FormInput
							label={t('city')}
							required
							id="team-city"
							errorText={t('field_required')}
							type="text"
							inputRef={refs.city}
							defaultValue={team.city}
							error={validateError && (refs.city && !refs.city.current.value)}
							classes={{ formControl: classes.city }}
						/>
						<div className={classes.mobileFormRow}>
							<FormInput
								label={t('state')}
								select
								required
								id="team-state"
								selectOptions={stateOptions(t, true)}
								native
								defaultValue={team.state}
								inputRef={refs.state}
								errorText={t('field_required')}
								classes={{ formControl: classes.stateZipFields }}
							/>
							<FormInput
								label={t('zip_code')}
								required
								id="team-zip-code"
								error={validateError && (refs.zipcode && !refs.zipcode.current.value)}
								errorText={t('field_required')}
								type="text"
								inputRef={refs.zipcode}
								defaultValue={team.zipcode}
								classes={{ formControl: classes.stateZipFields }}
							/>
						</div>
					</div>
					<FormInput
						label={t('about')}
						id="team-about"
						type="text"
						multiline
						rows={6}
						rowsMax={1000}
						inputRef={refs.about}
						defaultValue={team.about}
						classes={{ formControl: classes.missionStatement }}
					/>
				</div>
			</div>
		</div>
	);
};

export default TeamSettings;
