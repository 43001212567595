import {createSelector} from 'reselect';

const getAuthUserFromState = state => state.authUser;
const getOrganizationFromState = state => state.organization;
const getOrgMembersFromState = state => state.orgMembers;
const getOrgAdminInivtesFromState = state => state.adminInvites;

export const getIsOrgAdmin = createSelector(
	[getAuthUserFromState, getOrganizationFromState],
	(authUser, org) => org && org.orgMembers.some((member) => member.id === authUser.id));

export const getOrgMembers = createSelector(
	[getOrgAdminInivtesFromState, getOrgMembersFromState],
	(invites, orgMembers) => {
		if(invites && orgMembers) {
			return [
				...invites,
				...orgMembers
			];
		}
		return null;
	}
);
