export default (theme) => ({
	paperWidthMd: {
		[theme.breakpoints.up('sm')]: {
			maxWidth: '824px',
			minWidth: '558px'
		}
	},
	dialogTitle: {
		[theme.breakpoints.down('sm')]: {
			padding: '16px'
		}
	},
	contentWrapper: {
		paddingTop: '20px',
		paddingLeft: '36px',
		paddingRight: '36px',
		[theme.breakpoints.down('sm')]: {
			padding: '16px'
		}
	},
	passwordContent: {
		[theme.breakpoints.down('sm')]: {
			marginTop: '200px'
		}
	},
	actionsWrapper: {
		padding: '16px 36px',
		paddingBottom: '48px',
		[theme.breakpoints.down('sm')]: {
			padding: '0px',
			paddingBottom: '48px'
		}
	},
	cancelBtn: {
		width: '141px',
		marginRight: '6px'
	},
	error: {
		backgroundColor: theme.palette.error.main,
		color: '#fff',
		'&:hover': {
			backgroundColor: theme.palette.error.dark
		}
	},
	closeAccountBtns: {
		width: '167px',
		'&:first-child': {
			marginRight: '17px'
		}
	},
	closeAccountContent: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: '0px 42px',
		[theme.breakpoints.down('sm')]: {
			padding: '0px'
		}
	},
	whistleIcon: {
		marginBottom: '30px',
		marginTop: '60px'
	},
	closeAccountMsg: {
		textAlign: 'center',
		fontSize: '24px',
		marginTop: '16px',
		marginBottom: '46px'
	},
	clostAccountQuest: {
		fontSize: '32px',
		marginBottom: '28px',
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
			fontSize: theme.typography.h4.fontSize
		}
	}
});
