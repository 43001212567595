import { connect } from 'react-redux';
import flow from 'lodash.flow';
import SignOut from './SignOut';
import { signOutAuthUser } from 'reactive-core';

export default flow(
	connect(
		({
			authevents: { signOutAuthUserSuccess }
		}) => ({
			signOutAuthUserSuccess,
		}),
		(dispatch) => ({
			signOutAuthUser: () => dispatch(signOutAuthUser())
		})
	),
)(SignOut);
