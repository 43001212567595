import { connect } from 'react-redux';
import flow from 'lodash.flow';
import EditProfile from './EditProfile';
import {updateUserProfile, updateChildProfile} from 'reactive-core';
export default flow(
	connect(
		({
			authUser: { user: authUser },
			authevents: {updatingProfile, updateProfileSuccess}
		}, {
			user
		}) => ({
			user,
			authUser,
			updateProfileSuccess,
			updatingProfile
		}),
		(dispatch) => ({
			onSubmitClick: (profileData) => dispatch(updateUserProfile(profileData)),
			onSubmitChildClick: (profileData) => dispatch(updateChildProfile(profileData)),
		}))
)(EditProfile);
