export default (theme) => ({
	root: {
		display: 'flex'
	},
	selectedList: {
		alignItems: 'center',
		display: 'flex',
		flexWrap: 'wrap',
		listStyle: 'none',
		padding: '0px',
		margin: 0,
		'& li': {
			marginTop: '10px',
			marginRight: '10px'
		}
	},
	chip: {
		backgroundColor:'rgba(107, 23, 242, 0.1)',
		borderRadius: '4px',
		padding: '7px 6px 7px 0px',
		height: 'auto',
		'&:focus': {
			backgroundColor: 'rgba(136, 69, 244, 0.23)'
		}
	},
	chipLabel: {
		fontSize: '18px',
		color: theme.palette.secondary.main
	},
	deleteIcon: {
		width: '14px',
		height: '14px',
		marginLeft: '5px',
		'& path': {
			fill: theme.palette.primary.main,
		}
	},
	addSportSelect: {
		width: 'auto',
		flexDirection: 'row',
		height: '40px',
		'& .MuiOutlinedInput-root': {
			height: '40px'
		}
	}
});
