export default (theme) => ({
	formWrapper: {
		marginTop: '127px',
		minWidth: '542px',
		textAlign: 'center',
		maxWidth: '520px',
	},
	loginLinkBtn: {
		width: '230px',
		marginTop: '81px'
	},
	loginForm: {
		marginTop: '38px',
		maxWidth: '500px',
		[theme.breakpoints.down('sm')]: {
			maxWidth: '100%'
		}
	},
	formControl: {
		marginTop: '2px'
	},
	notParentText: {
		marginTop: '10px'
	},
	formRow: {
		marginTop: '20px',
		[theme.breakpoints.up('sm')]: {
			display: 'flex',
			width: '100%'
		}
	},
	firstName: {
		width: '40%',
		marginRight: '18px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	lastName: {
		flex: 1,
		[theme.breakpoints.down('sm')]: {
			flex: 'none',
			width: '100%'
		}
	},
	termsOfService: {
		fontSize: '14px',
		letterSpacing: '-0.28px',
		marginTop: '26px',
		marginBottom: '14px'
	},
	loginLinkText: {
		marginTop: '20px',
		textAlign: 'center'
	},
	loginLink: {
		fontFamily: 'Source Sans Pro'
	},
	boldText: {
		fontFamily: 'Source Sans Pro Semi Bold',
		lineHeight: '1.28',
		letterSpacing: ' -0.36px',
		fontSize: '18px'
	}
});
