import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import KList from 'components/KList';
import Paper from '@material-ui/core/Paper';
import Toast from 'components/Toast';
import RemoveEntityModal from 'components/RemoveEntityModal';
import { usePrevious } from 'utils/hooks/customHooks';
import CreateTeamModal from '../CreateTeamModal';
import {
	useHistory
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styles from './styles';
const useStyles = makeStyles(styles);

const OrgTeams = ({
	teams,
	org,
	user,
	isOrgAdmin,
	onSubmitTeam,
	creatingTeam,
	createTeamSuccess,
	fetchOrgTeams,
	removeTeam,
	removeTeamSuccess,
	removingTeam
}) => {
	const theme = useTheme();
	const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();
	const { t } = useTranslation();
	const history = useHistory();
	const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);
	const [showRemoveTeam, setShowRemoveTeam] = useState(false);
	const prevRemovingTeam = usePrevious(removingTeam);
	const prevCreatingTeam = usePrevious(creatingTeam);
	const [selectedTeam, setSelectedTeam] = useState(null);
	const [toastProps, setToastProps] = useState({
		open: false,
		message: '',
		severity: null
	});

	useEffect(() => {
		if (org && !teams) {
			fetchOrgTeams({
				orgId: org.id,
				filters: { status: 'active' }
			});
		}
	}, [org, teams]);
	useEffect(() => {
		if (createTeamSuccess && prevCreatingTeam && !creatingTeam) {
			setShowCreateTeamModal(false);
			setToastProps({
				open: true,
				message: t('team_created'),
				severity: 'success'
			});
		}
	}, [createTeamSuccess, prevCreatingTeam, creatingTeam]);
	useEffect(() => {
		if (removeTeamSuccess && prevRemovingTeam && !removingTeam) {
			setShowRemoveTeam(false);
			setSelectedTeam(null);
			setToastProps({
				open: true,
				message: t('team_removed'),
				severity: 'success'
			});
		}
	}, [prevRemovingTeam, removingTeam, removeTeamSuccess]);
	const onToastClose = () => {
		setToastProps({
			open: false,
			message: '',
			severity: ''
		});
	};

	const handleOnAddTeam = () => {
		setShowCreateTeamModal(true);
	};
	const handleOnCreateTeam = (teamData) => {
		onSubmitTeam({
			...teamData,
			orgId: org.id
		});
	};
	const handleOnRemoveTeamMember = () => {
		removeTeam(selectedTeam.id);
	};
	const handleCloseRemoveTeam = () => {
		setShowRemoveTeam(false);
		setSelectedTeam(null);
	};
	const handleOnRemoveTeamItemClick = (team) => {
		setSelectedTeam(team);
		setShowRemoveTeam(true);
	};
	const getTeams = () =>
		teams.map((team) => ({
			id: team.id,
			primaryText: team.name,
			secondaryText: `${t(team.gender)} | ${t(team.ageGroup)}`,
			avatar: team.profileImgUrl,
			onClick: (teamId) => { history.push(`/team/${teamId}`); },
			menuItems: isOrgAdmin ?
				[
					{
						onClick: () => { handleOnRemoveTeamItemClick(team); },
						label: t('remove_from_organization')
					}
				]
				: null
		}));
	return (
		<div className={classes.orgTeams}>
			<div className={classes.header}>
				{isOrgAdmin &&
					<Button onClick={handleOnAddTeam} color="primary" variant="contained" className={classes.addTeamButton}>
						{t('add_team')}
					</Button>
				}
			</div>
			<Paper className={clsx(classes.listWrapper, { [classes.emptyListWrapper]: teams && teams.length === 0 })}>
				<KList
					items={teams && getTeams()}
					onEmptyActionButtonClick={!matchesSm && handleOnAddTeam}
					emptyListText={t('you_currently_have_no_teams')}
					emptyListButtonText={t('add_team')}
					skeletonCount={4}
				/>
			</Paper>
			<CreateTeamModal
				show={showCreateTeamModal}
				onClose={() => { setShowCreateTeamModal(false); }}
				onSubmit={handleOnCreateTeam}
				org={org}
				user={user}
			/>
			<RemoveEntityModal
				onClose={handleCloseRemoveTeam}
				show={showRemoveTeam}
				entity={selectedTeam}
				onSubmit={handleOnRemoveTeamMember}
				title={t('remove_from_organization')}
				areYouSureText={t('are_you_sure_you_want_remove_this_team')}
				submitButtonText={matchesSm ? t('remove') : t('remove_team')}
			/>
			<Toast
				{...toastProps}
				onClose={onToastClose}
			/>
		</div>

	);
};

export default OrgTeams;
