import React, { useEffect, useRef, useState } from 'react';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import FormInput from 'components/FormInput';
import { useParams, useHistory } from 'react-router-dom';
import RemoveEntityModal from 'components/RemoveEntityModal';
import {ORG, TEAM} from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {DateTime} from 'luxon';
import styles from './styles';

const useStyles = makeStyles(styles);
const CreateEditArticle = ({
	article,
	createArticle,
	editArticle,
	deleteArticle,
	createArticleSuccess,
	updateArticleSuccess,
	clearArticleSuccess,
	isEdit
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	let { teamId, orgId } = useParams();
	const history = useHistory();
	const [showRemoveArticle, setShowRemoveArticle] = useState(false);
	const [validateError, setValidateError] = useState(false);
	const refs = {
		title: useRef(null),
		date: useRef(null),
		body: useRef(null)
	};
	useEffect(() => {
		clearArticleSuccess();
	}, []);
	useEffect(() => {
		if((updateArticleSuccess || createArticleSuccess) && article) {
			history.replace(`/${teamId ? `team/${teamId}` : `organization/${orgId}`}/article/${article.id}`);
		}
	}, [createArticleSuccess, updateArticleSuccess, article]);

	const getUpdatedData = () => {
		if(isEdit && article) {
			const updated = { id: article.id };
			Object.keys(refs).forEach((key) => {
				if (refs[key].current.value !== article[key] && article[key] !== null || article[key] === null && article[key].current.value !== '') {
					updated[key] = refs[key].current.value;
				}
			});
			return updated;
		}
		return {
			entityId: teamId || orgId,
			type: teamId ? TEAM : ORG,
			title: refs.title.current.value,
			date: refs.date.current.value,
			body: refs.body.current.value
		};
	};
	const handleOnSubmit = () => {
		const keys = Object.keys(refs);
		if (keys.some((key) => !refs[key].current.value)) {
			setValidateError(true);
		} else {
			const data = getUpdatedData();
			if (isEdit) {
				editArticle(data);
			} else {
				createArticle(data);
			}
		}
	};
	const handelOnCancel = () => {
		if(article) {
			history.push(`/${article.type}/${article.entityId}/article/${article.id}`);
		} else {
			history.push(`/${teamId ? `team/${teamId}` : `organization/${orgId}`}/highlights`);
		}
	};

	const handleOnDelete = () => {
		setShowRemoveArticle(true);
	};
	const handleCloseRemoveArticle = (e) => {
		setShowRemoveArticle(false);
		e.preventDefault();
	};
	const handleRemoveArticle = (article) => {
		setShowRemoveArticle(false);
		deleteArticle(article.id);
	};

	return (
		<div className={classes.createEditArticle}>
			<div className={classes.header}>
				<Typography variant="h5">{article ? t('edit_article') : t('create_article')}</Typography>
			</div>
			<div className={classes.formWrapper}>
				<FormInput
					label={t('title')}
					id="article_title"
					errorText={t('field_required')}
					type="text"
					defaultValue={(isEdit && article) ? article.title : ''}
					inputRef={refs.title}
					error={validateError && (refs.title && !refs.title.current.value)}
					classes={{ formControl: classes.title }}
				/>
				<FormInput
					label={t('date')}
					id="article_date"
					type="date"
					defaultValue={(isEdit && article) ? article.date : DateTime.local().toISODate()}
					inputRef={refs.date}
					classes={{ formControl: classes.date }}
				/>
				<FormInput
					label={t('body')}
					id="body"
					type="text"
					multiline
					rows={20}
					rowsMax={1000}
					inputRef={refs.body}
					error={validateError && (refs.body && !refs.body.current.value)}
					defaultValue={(isEdit && article) ? article.body : ''}
					classes={{ formControl: classes.body }}
				/>
				<Box display="flex">
					{article && 
						<Button onClick={handleOnDelete} color="primary" variant="contained" className={classes.deleteButton}>
							{t('delete_article')}
						</Button>
					}
					<div className={classes.actionButtons}>
						<Button onClick={handelOnCancel} variant="outlined" className={classes.actionButton}>
							{t('cancel')}
						</Button>
						<Button onClick={handleOnSubmit} color="primary" variant="contained" className={classes.actionButton}>
							{t('publish')}
						</Button>
					</div>
				</Box>
			</div>
			<RemoveEntityModal
				onClose={handleCloseRemoveArticle}
				show={showRemoveArticle}
				entity={article && {name: article.title, id: article.id}}
				onSubmit={handleRemoveArticle}
				title={t('delete_article')}
				areYouSureText={t('are_you_sure_you_want_to_delete_article')}
				submitButtonText={t('delete_article')}
			/>
		</div>
	);
};

export default CreateEditArticle;
