import React, { useEffect } from 'react';
import MediaCollection from 'components/MediaCollection';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import styles from './styles';
const useStyles = makeStyles(styles);
const UserHighlights = ({
	user,
	fetchUserArticles,
	articles
}) => {
	const classes = useStyles();
	const { t } = useTranslation();

	useEffect(() => {
		if (user && articles === null)
			fetchUserArticles(user.id);
	}, [user, articles]);

	return (
		<div className={classes.highlights}>
			<MediaCollection
				items={articles}
				emptyMsg={t('no_articles_yet')}
				title={t('articles')}
			/>
		</div>
	);
};

export default UserHighlights;
