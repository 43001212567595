import React, { useRef, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import {
	Link as RouterLink,
} from 'react-router-dom';
import LaunchIcon from '@material-ui/icons/Launch';
import IconButton from '@material-ui/core/IconButton';
import FormInput from 'components/FormInput';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CopyIcon } from 'icons/copy_icon2.svg';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import styles from './styles';
const useStyles = makeStyles(styles);

const InviteTeamMemberModal = ({
	onClose,
	show,
	onSubmit,
	teamInviteLink,
	teamName
}) => {
	const theme = useTheme();
	const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();
	const { t } = useTranslation();
	const emails = useRef(null);
	const [invalidEmail, setInvalidEmail] = useState(false);
	const handleCopyClick = () => {
		copy(`${t('kinectem_team_invite_copy', {team: teamName})} ${teamInviteLink}`);
	};
	const validEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
	const handleOnSubmit = () => {
		const emailsList = emails.current.value.split(',').filter((e) => e.trim() !== '').map((e) => e.trim());
		if(emailsList.length) {
			if(emailsList.some((e) => !validEmail(e))) {
				setInvalidEmail(true);
			} else {
				onSubmit(emails.current.value.split(','));
			}
		}
	};

	return (
		<Dialog
			onClose={onClose}
			aria-labelledby="add-team-member-title"
			maxWidth="sm"
			fullScreen={matchesSm}
			disableBackdropClick
			classes={{ paperWidthSm: classes.paperWidthSm }}
			open={show}
		>
			<DialogTitle id="add-team-member-title" disableTypography className={classes.titleWrapper}>
				<Typography variant="h4" component="h1" className={classes.title}>
					{t('invite_team_members')}
				</Typography>
			</DialogTitle>
			<DialogContent className={classes.contentWrapper}>
				<FormInput
					label={t('send_an_invite_email')}
					id="emails"
					placeholder={t('enter_each_team_member_email')}
					fullWidth
					multiline
					error={invalidEmail}
					errorText={t('invalid_email_address')}
					rows={matchesSm ? 10 : 4}
					type="text"
					inputRef={emails}
				/>
				<div className={classes.copyLinkWrapper}>
					<FormControl fullWidth variant="outlined" className={classes.copyLinkFormControl}>
						<FormLabel  htmlFor="invitelink">{t('or_invite_your_team_with_unique_link')}</FormLabel>
						<OutlinedInput
							id="invitelink"
							readOnly
							disabled
							value={teamInviteLink}
						/>
						<IconButton
							aria-label="copy"
							color="secondary"
							onClick={handleCopyClick}
							className={classes.copyButton}
						>
							<CopyIcon />
						</IconButton>
					</FormControl>
				</div>
				<Box mt={3}>
					<Typography variant="body1">
						<Link component={RouterLink} to="/settings/children" target="_blank" rel="noopener noreferrer" >
							{t('add_your_children')}
							<LaunchIcon style={{marginLeft: '5px', verticalAlign: 'bottom'}}/>
						</Link>
					</Typography>
				</Box>
			</DialogContent>
			<DialogActions className={classes.actionsWrapper} >
				<Button onClick={onClose} variant="outlined" className={classes.cancelBtn}>
					{t('cancel')}
				</Button>
				<Button onClick={handleOnSubmit} color="primary" variant="contained" className={classes.submitBtn}>
					{t('invite_team_members')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
export default InviteTeamMemberModal;
