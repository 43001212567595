import React, { useState, useRef } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import styles from './styles';
const useStyles = makeStyles(styles);

const KListItem = ({
	item
}) => {
	const theme = useTheme();
	const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();
	const [showMenu, setShowMenu] = useState(false);
	const menuBtn = useRef(null);

	return (
		<ListItem
			button={item.onClick}
			onClick={item.onClick && (() => { item.onClick(item.id); })}
			className={classes.listItem}
		>
			<ListItemAvatar >
				<Badge
					overlap="circle"
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					color="secondary"
					variant="dot"
					invisible={!item.showBadge}
					className={classes.avatarWrapper}
					classes={{
						badge: classes.badge,
						dot: classes.badgeDot
					}}
				>
					{item.avatar && React.isValidElement(item.avatar) ?
						<Avatar alt={item.primaryText} className={classes.avatar}>
							{item.avatar}
						</Avatar>
						:
						<Avatar alt={item.primaryText} src={item.avatar} className={classes.avatar} />
					}
				</Badge>
			</ListItemAvatar>
			<ListItemText
				primary={item.primaryText}
				secondary={item.secondaryText}
				primaryTypographyProps={{
					variant: 'h6',
					component: 'span',
					color: item.color || 'textPrimary'
				}}
				className={classes.primarySection}
				classes={{
					primary: classes.listItemText,
					secondary: classes.listItemText
				}}
			/>
			{item.secondarySectionPrimaryText &&
				<div className={classes.secondarySection}>
					{matchesSm ?
						<Typography variant="caption" color="textSecondary">{item.secondarySectionPrimaryText}</Typography>
						:
						<ListItemText
							primary={item.secondarySectionPrimaryText}
							secondary={item.secondarySectionSecondaryText}
							primaryTypographyProps={{
								variant: 'h6',
								component: 'span',
								color: item.color || 'textPrimary'
							}}
							classes={{
								primary: classes.listItemText,
								secondary: classes.listItemText
							}}
						/>
					}
				</div>
			}
		
			<ListItemSecondaryAction className={classes.actionWrapper}>
				{(!matchesSm && item.status) &&
					<div className={classes.statusWrapper}>
						<Typography variant="body1" component="span" className={classes.status}>{item.status}</Typography>
					</div>
				}
				{item.menuItems &&
					<>
						<IconButton
							ref={menuBtn}
							onClick={() => setShowMenu(!showMenu)}
							className={classes.menuButton}
						>
							<MoreHorizIcon fontSize="large" color="primary" />
						</IconButton>
						<Menu
							anchorEl={menuBtn.current}
							keepMounted
							open={showMenu}
							onClose={() => { setShowMenu(false); }}
						>
							{
								item.menuItems.map((menuItem) => (
									<MenuItem key={menuItem.label} onClick={() => { setShowMenu(false); menuItem.onClick(); }}>{menuItem.label}</MenuItem>
								))
							}
						</Menu>
					</>
				}
			</ListItemSecondaryAction>

		</ListItem>
	);
};

export default KListItem;
