import { createSelector } from 'reselect';

const organizationsFromState = state => state.user ? state.user.organizations : [];
const teamsFromState = state => state.user ? state.user.teams : [];
const getAuthUserFromState = state => state.authUser;
const getTeamFromState = state => state.team;
const getOrgFromState = state => state.organization;

export const getOrganizationsForListWidget = createSelector(
	[organizationsFromState], (orgs) => orgs.map((org) => ({
		avatar: org.profileImgUrl,
		primaryText: org.name,
		linkTo: `/organization/${org.id}`
	}))
);
export const getTeamsForListWidget = createSelector(
	[teamsFromState], (teams) => teams.map((team) => ({
		avatar: team.profileImgUrl,
		primaryText: team.name,
		secondaryText: team.sport,
		linkTo: `/team/${team.id}`
	}))
);

export const isOrgTeamAdmin = createSelector(
	[getAuthUserFromState, getTeamFromState],
	(authUser, team) => (authUser && team) ?
		authUser.organizations.some((userOrg) => userOrg.id === team.orgId && userOrg.isAdmin) ||
		authUser.teams.some((userTeam) => userTeam.id === team.id && userTeam.isAdmin)
		: false
);
export const isOrgAdmin = createSelector(
	[getAuthUserFromState, getOrgFromState],
	(authUser, org) => (authUser && org) ?
		authUser.organizations.some((userOrg) => userOrg.id === org.id && userOrg.isAdmin)
		: false
);

