import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import KList from 'components/KList';
import Paper from '@material-ui/core/Paper';
import Toast from 'components/Toast';
import InviteTeamMemberModal from '../InviteTeamMemberModal';
import RemoveEntityModal from 'components/RemoveEntityModal';
import EditTeamMemberModal from '../EditTeamMemberModal';
import copy from 'copy-to-clipboard';
import { INVITATION_PENDING } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styles from './styles';

const useStyles = makeStyles(styles);

const TeamRoster = ({
	team,
	roster,
	isOrgTeamAdmin,
	onSendInvites,
	clearTeamOrgSuccess,
	sendingTeamInvitesSuccess,
	fetchTeamMembers,
	fetchTeamInvites,
	updateTeamMemberSuccess,
	removeTeamMemberSuccess,
	cancelTeamInviteSuccess,
	resendTeamInviteSuccess,
	updateTeamMember,
	removeFromTeam,
	cancelTeamInvite,
	resendTeamInvite,
	teamInviteLink
}) => {
	const theme = useTheme();
	const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();
	const { t } = useTranslation();
	const history = useHistory();
	const [showAddTeamMember, setShowAddTeamMember] = useState(false);
	const [showRemoveTeamMember, setShowRemoveTeamMember] = useState(false);

	const [showEditTeamMember, setShowEditTeamMember] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);
	const [toastProps, setToastProps] = useState({
		open: false,
		message: '',
		severity: null
	});
	useEffect(() => {
		if (team && !team.teamMembers) {
			fetchTeamMembers({
				teamId: team.id,
				filters: {
					status: 'active'
				}
			});
			if (isOrgTeamAdmin && !team.teamInvites) {
				fetchTeamInvites({teamId: team.id, inviteStatus: INVITATION_PENDING});
			}
		}
	}, [team, isOrgTeamAdmin]);
	useEffect(() => {
		if (sendingTeamInvitesSuccess) {
			setShowAddTeamMember(false);
			setToastProps({
				open: true,
				message: t('team_member_invite_sent'),
				severity: 'success'
			});
			clearTeamOrgSuccess();
		}
	}, [sendingTeamInvitesSuccess]);
	useEffect(() => {
		if (removeTeamMemberSuccess) {
			setShowRemoveTeamMember(false);
			setSelectedUser(null);
			setToastProps({
				open: true,
				message: t('team_member_removed'),
				severity: 'success'
			});
			clearTeamOrgSuccess();
		}
	}, [removeTeamMemberSuccess]);
	useEffect(() => {
		if (cancelTeamInviteSuccess) {
			setToastProps({
				open: true,
				message: t('team_member_invite_canceled'),
				severity: 'success'
			});
			clearTeamOrgSuccess();
		}
	}, [cancelTeamInviteSuccess]);
	useEffect(() => {
		if (resendTeamInviteSuccess) {
			setToastProps({
				open: true,
				message: t('team_member_invite_resent'),
				severity: 'success'
			});
		}
		clearTeamOrgSuccess();
	}, [resendTeamInviteSuccess]);
	useEffect(() => {
		if (updateTeamMemberSuccess) {
			setShowEditTeamMember(false);
			setSelectedUser(null);
			setToastProps({
				open: true,
				message: t('team_member_updated_successfully'),
				severity: 'success'
			});
			clearTeamOrgSuccess();
		}
	}, [updateTeamMemberSuccess]);

	const handleOnAddTeamMember = () => {
		setShowAddTeamMember(true);
	};
	const handleOnSubmitInviteTeamMembers = (emails) => {
		if(emails.length) {
			onSendInvites({
				emails,
				entityId: team.id
			});
		} else{
			setShowAddTeamMember(false);
		}
	};
	const onToastClose = () => {
		setToastProps({
			open: false,
			message: '',
			severity: ''
		});
	};
	const handleOnRemoveTeamMember = () => {
		removeFromTeam({teamId: team.id, id: selectedUser.id});
	};
	const handleCloseRemoveTeamMember = () => {
		setShowRemoveTeamMember(false);
		setSelectedUser(null);
	};
	const handleOnRemoveTeamMemberItemClick = (member) => {
		setSelectedUser(member);
		setShowRemoveTeamMember(true);
	};
	const handleCancelInvitation = (member) => {
		cancelTeamInvite(member.id);
	};
	const handleResendInvitation = (member) => {
		resendTeamInvite(member.id);
	};
	const handleCopyInvitation = () => {
		copy(teamInviteLink);
		setToastProps({
			open: true,
			message: t('invite_link_copied'),
			severity: 'info'
		});
	};
	const handleOnEditRole = (member) => {
		setSelectedUser(member);
		setShowEditTeamMember(true);
	};
	const handleCloseEditRole = () => {
		setShowEditTeamMember(false);
		setSelectedUser(null);
	};
	const handleOnSubmitUpdateTeamMember = (member) => {
		if (roster.some((m) =>
			m.id === member.id &&
			(m.role !== member.role || m.isAdmin !== member.isAdmin)
		)) {
			updateTeamMember({...member, teamId: team.id});
		} else {
			setShowEditTeamMember(false);
			setSelectedUser(null);
			setToastProps({
				open: true,
				message: t('team_member_updated_successfully'),
				severity: 'success'
			});
		}

	};
	const getMemberSecondaryText = (member) => {
		if(member.isAdmin) {
			return member.role ? `${t(member.role)} - ${t('admin')}` : t('admin');
		} else {
			return t(member.role);
		}
	};

	const getMembers = () =>
		roster && roster.map((member) => isOrgTeamAdmin ?
			({
				id: member.userId,
				primaryText: !member.inviteStatus ? `${member.firstName} ${member.lastName}` : member.email,
				secondaryText: !member.inviteStatus ? member.email : null,
				secondarySectionPrimaryText: getMemberSecondaryText(member),
				avatar: member.profileImgUrl,
				showBadge: member.inviteStatus === INVITATION_PENDING,
				disabled: true,
				status: member.inviteStatus && t(member.inviteStatus),
				onClick: !member.inviteStatus ? (userId) => { history.push(`/user/${userId}`); } : null,
				menuItems:
					!member.inviteStatus ?
						[
							{
								onClick: () => { handleOnEditRole(member); },
								label: t('edit_role')
							},
							{
								onClick: () => { handleOnRemoveTeamMemberItemClick(member); },
								label: t('remove_from_team')
							}
						]
						:
						[
							{
								onClick: () => { handleCopyInvitation(member); },
								label: t('copy_invitation_link')
							},
							{
								onClick: () => { handleResendInvitation(member); },
								label: t('resend_invitation')
							},
							{
								onClick: () => { handleCancelInvitation(member); },
								label: t('cancel_invitation')
							},
						]

			})
			: ({
				id: member.userId,
				primaryText: `${member.firstName} ${member.lastName}`,
				secondaryText: matchesSm && t(member.role),
				secondarySectionPrimaryText: !matchesSm && t(member.role),
				onClick: (userId) => { history.push(`/user/${userId}`); },
			})
		);
	return (
		<div className={classes.teamRoster}>
			{isOrgTeamAdmin && <div className={classes.header}>
				<Button onClick={(handleOnAddTeamMember)} color="primary" variant="contained" className={classes.addTeamMemberButton}>
					{t('invite_team_members')}
				</Button>
			</div>
			}
			<Paper className={classes.listWrapper}>
				<KList
					items={getMembers()}
					onEmptyActionButtonClick={isOrgTeamAdmin && handleOnAddTeamMember}
					emptyListText={isOrgTeamAdmin ? t('you_currently_have_no_team_members') : t('this_team_currently_has_no_team_members')}
					emptyListButtonText={isOrgTeamAdmin && t('invite_team_members')}
				/>
			</Paper>
			<InviteTeamMemberModal
				show={showAddTeamMember}
				onClose={() => { setShowAddTeamMember(false); }}
				onSubmit={handleOnSubmitInviteTeamMembers}
				teamInviteLink={teamInviteLink}
				teamName={team.name}
			/>
			<RemoveEntityModal
				onClose={handleCloseRemoveTeamMember}
				show={showRemoveTeamMember}
				entity={selectedUser && { ...selectedUser, name: `${selectedUser.firstName} ${selectedUser.lastName}` }}
				onSubmit={handleOnRemoveTeamMember}
				title={t('remove_team_member')}
				areYouSureText={t('are_you_sure_you_want_remove_this_team_member')}
				submitButtonText={t('remove_team_member')}
			/>
			{selectedUser &&
				<EditTeamMemberModal
					show={showEditTeamMember}
					onClose={handleCloseEditRole}
					teamMember={selectedUser}
					onSubmit={handleOnSubmitUpdateTeamMember}
				/>
			}
			<Toast
				{...toastProps}
				onClose={onToastClose}
			/>
		</div>
	);

};

export default TeamRoster;
