import React, { useEffect, useState } from 'react';
import MediaCollection from 'components/MediaCollection';
import {
	Link as RouterLink,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import RemoveEntityModal from 'components/RemoveEntityModal';
import Toast from 'components/Toast';
import styles from './styles';
const useStyles = makeStyles(styles);
const TeamHighlights = ({
	team,
	fetchTeamArticles,
	articles,
	isOrgTeamAdmin,
	deleteArticle,
	deleteArticleSuccess,
	clearArticleSuccess
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [showRemoveArticle, setShowRemoveArticle] = useState(false);
	const [selectedArticle, setSelectedArticle] = useState(null);
	const [toastProps, setToastProps] = useState({
		open: false,
		message: '',
		severity: null
	});
	useEffect(() => {
		if (team && articles === null)
			fetchTeamArticles(team.id);
	}, [team, articles]);
	useEffect(() => {
		if(deleteArticleSuccess) {
			setToastProps({
				open: true,
				message: t('article_delete_success'),
				severity: 'success'
			});
		
		}
	}, [deleteArticleSuccess]);
	const handleShowRemoveArticle = (id) => {
		setSelectedArticle(articles.find((a) => a.id === id));
		setShowRemoveArticle(true);
	};
	const handleCloseRemoveArticle = (e) => {
		setShowRemoveArticle(false);
		setSelectedArticle(null);
		e.preventDefault();
	};
	const handleRemoveArticle = (article) => {
		setShowRemoveArticle(false);
		deleteArticle(article.id);
	};
	const onToastClose = () => {
		setToastProps({
			open: false,
			message: '',
			severity: ''
		});
		clearArticleSuccess();
	};
	return (
		<div className={classes.highlights}>
			{isOrgTeamAdmin && 
				<div className={classes.header}>
					<Button  component={RouterLink} to={`/team/${team.id}/article/create`} color="primary" variant="contained" className={classes.newArticleButton}>
						{t('new_article')}
					</Button>
				</div>
			}
			<MediaCollection
				items={articles}
				emptyMsg={t('no_articles_yet')}
				title={t('articles')}
				isAdmin={isOrgTeamAdmin}
				onRemoveItem={handleShowRemoveArticle}
			/>
			<RemoveEntityModal
				onClose={handleCloseRemoveArticle}
				show={showRemoveArticle}
				entity={selectedArticle && {name: selectedArticle.title, id: selectedArticle.id}}
				onSubmit={handleRemoveArticle}
				title={t('delete_article')}
				areYouSureText={t('are_you_sure_you_want_to_delete_article')}
				submitButtonText={t('delete_article')}
			/>
			<Toast
				{...toastProps}
				onClose={onToastClose}
			/>
		</div>
	);
};

export default TeamHighlights;
