import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
const useStyles = makeStyles(styles);

const FormHeading = ({ title, subTitle }) => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Typography variant="h3" className={classes.title}>{title}</Typography>
			<Typography variant="subtitle1" className={classes.subTitle}>{subTitle}</Typography>
		</div>
	);
};

export default FormHeading;
