import React, { useState, useEffect } from 'react';
import firebase from 'firebase';
import 'firebase/auth';

const WithAuth = (App) => {
	const WithAuthComponent = (props) => {
		const [isSignedIn, setIsSignedIn] = useState(false);
		const [checkingAuth, setCheckingAuth] = useState(true);

		useEffect(() => {

		}, [isSignedIn]);
		firebase.auth().onAuthStateChanged(
			(user) => {
				setIsSignedIn(!!user);
				setCheckingAuth(false);
			}
		);
		return (
			<>
				{!checkingAuth &&
					<>
						<App isSignedIn={isSignedIn} {...props} />
					</>
				}
			</>
		);
	};
	return WithAuthComponent;
};

export default WithAuth;
