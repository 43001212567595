export default () => ({
	
	pageNotFound: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '100px'
	},
	notFoundText: {
		fontSize: '26px'
	},
	notFoundSubText: {
		fontSize: '18px',
		marginBottom: '60px'
	}
});
