import { connect } from 'react-redux';
import flow from 'lodash.flow';
import Article from './Article';
import { fetchArticle, clearArticleSuccess } from 'reactive-core';
import {getArticle} from 'selectors/articleSelector';
import WithResourceNotFound from 'components/WithResourceNotFound';

export default flow(
	connect(
		({
			article: {article},
			uievents: {createArticleSuccess, updateArticleSuccess, deleteArticleSuccess},
			profile: {team, organization}
		}) => ({
			article: article && getArticle({article}),
			team,
			org: organization,
			createArticleSuccess,
			updateArticleSuccess,
			deleteArticleSuccess
		}),
		(dispatch) => ({
			fetchArticle: (id) => dispatch(fetchArticle(id)),
			clearArticleSuccess: () => dispatch(clearArticleSuccess())
		})
	)
)(WithResourceNotFound(Article));
