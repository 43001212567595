import { connect } from 'react-redux';
import flow from 'lodash.flow';
import TeamRoster from './TeamRoster';
import {
	sendTeamInvites,
	fetchTeamMembers,
	removeFromTeam,
	cancelTeamInvite,
	resendTeamInvite,
	updateTeamMember,
	fetchTeamInvites,
	clearTeamOrgSuccess
} from 'reactive-core';
import { getRoster, getTeamInviteLink } from 'selectors/teamSelectors';

export default flow(
	connect(
		({
			uievents: {
				sendingTeamInvites,
				sendingTeamInvitesSuccess,
				sendingTeamInvitesError,
				updatingTeamMember,
				updateTeamMemberSuccess,
				removingTeamMember,
				removeTeamMemberSuccess,
				cancelingTeamInvite,
				cancelTeamInviteSuccess,
				resendingTeamInvite,
				resendTeamInviteSuccess
			},
			profile: { team }
		}) => ({
			team,
			roster: team && getRoster({ team }),
			teamInviteLink: getTeamInviteLink({team}),
			sendingTeamInvites,
			sendingTeamInvitesSuccess,
			sendingTeamInvitesError,
			updatingTeamMember,
			updateTeamMemberSuccess,
			removingTeamMember,
			removeTeamMemberSuccess,
			cancelingTeamInvite,
			cancelTeamInviteSuccess,
			resendingTeamInvite,
			resendTeamInviteSuccess,
		}),
		(dispatch) => ({
			onSendInvites: (inviteData) => dispatch(sendTeamInvites(inviteData)),
			fetchTeamMembers: (fetchObj) => dispatch(fetchTeamMembers(fetchObj)),
			fetchTeamInvites: (fetchObj) => dispatch(fetchTeamInvites(fetchObj)),
			updateTeamMember: (teamMemberData) => dispatch(updateTeamMember(teamMemberData)),
			removeFromTeam: (teamMemberData) => dispatch(removeFromTeam(teamMemberData)),
			cancelTeamInvite: (id) => dispatch(cancelTeamInvite(id)),
			resendTeamInvite: (id) => dispatch(resendTeamInvite(id)),
			clearTeamOrgSuccess: () => dispatch(clearTeamOrgSuccess())
		})
	)
)(TeamRoster);

