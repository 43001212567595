import React from 'react';
import Button from '@material-ui/core/Button';
import FormInput from 'components/FormInput';
import { Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import Link from '@material-ui/core/Link';
const EmailForm = ({
	t,
	classes,
	onEmailInputChange,
	onSendClick
}) => (
	<form className={classes.resetForm} noValidate>
		<FormInput
			label={t('email')}
			id="reset-email"
			fullWidth
			type="email"
			onChange={onEmailInputChange}
			classes={{formControl: classes.emailControl}}
		/>
		<Button variant="contained" color="primary" fullWidth onClick={onSendClick}>{t('send')}</Button>
		<Link component={RouterLink} color="primary" to="/login">
			<Typography variant="body1" className={classes.loginLink}>
				<ChevronLeftIcon />
				{t('back_to_login')}
			</Typography>
		</Link>
	</form>
);

export default EmailForm;
