import React, { useState, useRef } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormInput from 'components/FormInput';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import styles from './styles';
const useStyles = makeStyles(styles);

const AddOrgMemberModal = ({
	onClose,
	show,
	onSubmit,
	error
}) => {
	const theme = useTheme();
	const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();
	const { t } = useTranslation();
	const [validateError, setValidateError] = useState(false);
	const [invalidEmail, setInvalidEmail] = useState(false);
	const refs = {
		emails: useRef(null)
	};

	const validEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
	const handleOnSubmit = () => {
		if (Object.keys(refs).some((key) => !refs[key].current.value)) {
			setValidateError(true);
		} else {
			if (validEmail(refs.emails.current.value)) {
				onSubmit({ emails: [refs.emails.current.value] });
			} else {
				setValidateError(true);
				setInvalidEmail(true);
			}
		}
	};
	const getEmailErrorText = () => {
		if (validateError && invalidEmail) {
			return t('invalid_email_address');
		} else if (validateError && !refs.email.curent.value) {
			return t('field_required');
		} else if (error) {
			return t(error);
		}
	};
	return (
		<Dialog
			onClose={onClose}
			aria-labelledby="add-org-member-title"
			maxWidth="sm"
			fullScreen={matchesSm}
			disableBackdropClick
			classes={{ paperWidthSm: classes.paperWidthSm }}
			open={show}
		>
			<DialogTitle id="add-org-member-title" disableTypography className={classes.titleWrapper}>
				<Typography variant="h5" component="h1" className={classes.title}>
					{t('add_admin_to_org')}
				</Typography>
				<Typography variant="body1" color="textSecondary" className={classes.subText}>
					{t('add_org_admin_invite_msg')}
				</Typography>
			</DialogTitle>
			<DialogContent className={classes.contentWrapper}>
				<div className={classes.formWrapper} >
					<FormInput
						label={t('email')}
						id="email"
						required
						dense={!validateError && !error}
						error={error || (validateError && (refs.emails && !refs.emails.current.value))}
						errorText={getEmailErrorText()}
						fullWidth
						type="email"
						inputRef={refs.emails}
						classes={{ formControl: classes.email }}
					/>
				</div>
			</DialogContent>
			<DialogActions className={classes.actionsWrapper} >
				<Button onClick={onClose} variant="outlined" className={classes.cancelBtn}>
					{t('cancel')}
				</Button>
				<Button onClick={handleOnSubmit} color="primary" variant="contained" className={classes.submitBtn}>
					{t('add_admin')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AddOrgMemberModal;
