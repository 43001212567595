export default (theme) => ({
	about: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		paddingTop: '20px',
		paddingLeft: '24px',
		paddingRight: '24px',
		paddingBottom: '24px',
		[theme.breakpoints.down('sm')]: {
			padding: '16px',
			alignItems: 'inherit',
		}
	},
	sectionLabel: {
		fontSize: '18px',
		textTransform: 'uppercase',
		letterSpacing: '-0.32px',
		marginBottom: '11px'
	},
	infoSection: {
		marginTop: '22px'
	},
	bioText: {
		fontSize: '18px',
		whiteSpace: 'pre-wrap'
	}
});
