export default (theme) => ({
	userProfileHeader: {
		display: 'flex',
		alignItems:'center',
		flexDirection: 'column',
		width: '100%',
		paddingTop: '100px',
		paddingLeft: '24px',
		paddingRight: '24px',
		paddingBottom: '32px',
		backgroundColor: theme.palette.background.paper,
		[theme.breakpoints.down('sm')]: {
			paddingTop: '75px',
			paddingBottom: '0px',
		}
	},
	name: {
		textAlign: 'center'
	},
	kinectionsWrapper: {
		display: 'flex',
		alignItems: 'center',
		marginTop: '8px'
	},
	kinectionsCount: {
		color: '#fff',
		width: '35px',
		height: '35px',
		borderRadius: '50%',
		fontSize: '14px',
		fontFamily: 'Source Sans Pro Semi Bold',
		marginRight: '5px',
	},
	kinectionsLabel: {
		fontSize: '16px',
		textTransform: 'uppercase',
		letterSpacing: '-0.32px'
	},

	editProfileBtn: {
		marginTop: '25px',
		fontSize: '16px',
		fontFamily: 'Source Sans Pro',
		'& .MuiButton-startIcon': {
			marginRight: '3px'
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '7px',
		}
	},
	
});
