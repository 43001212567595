import React from 'react';
import {
	BrowserRouter as Router,
	Route,
	Redirect,
	Switch,
} from 'react-router-dom';
import Root from 'Root';
import CreateProfile from 'containers/CreateProfile';
import SignUp from 'containers/SignUp';
import SignIn from 'containers/SignIn';
import SignOut from 'containers/SignOut';
import PasswordReset from 'containers/PasswordReset';
import InvitationHandler from 'containers/InvitationHandler';

const Routes = ({
	isSignedIn,
	showCreateUserProfile,
}) => {
	const handleEmailAuthRender = (routeProps) => {
		const query = new URLSearchParams(routeProps.location.search);

		if (query.get('mode') === 'resetPassword') {
			return (<PasswordReset isReset />);
		} else if (query.get('mode') === 'verifyEmail') {
			return (<SignUp isVerify />);
		} else if (query.get('mode') === 'recoverEmail') {
			return (<SignIn isRecoverEmail />);
		}
	};
	const handleCreateProfileRender = (routeProps) => {
		if (isSignedIn && showCreateUserProfile) {
			return <CreateProfile />;
		} else if (!isSignedIn) {
			return <Redirect to="/sign-in" />;
		}
		return <Redirect to={`/${routeProps.location.search}`} />;
	};
	return (
		<>
			<Router>
				<Switch>
					<Route path={['/signin', '/sign-in', '/login']} component={SignIn} />
					<Route path={['/signup', '/sign-up', '/register']} component={SignUp} />
					<Route path={['/signout', '/sign-out']}>
						<SignOut />
					</Route>
					<Route path={['/reset-password', '/password-reset']} component={PasswordReset} />
					<Route path="/create-profile" render={handleCreateProfileRender} />
					<Route path="/log-out" render={() => (<Redirect to="/sign-in" />)} />
					<Route
						path="/email-auth"
						render={handleEmailAuthRender}
					/>
					<Route exact path={['/invite/:inviteId']} component={InvitationHandler} />

					<Route path="/" component={Root} />
				</Switch>
			</Router>

		</>
	);
};

export default Routes;
