export default () => ({
	root: {
		width: '100%',
		marginTop: '13px'
	},
	divderWrapper: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		marginBottom: '17px'
	},
	orText: {
		marginLeft: '15px',
		marginRight: '15px',
		fontSize: '18px'
	},
	divider: {
		flex: 1
	},
	googleButtonWrapper: {
		position: 'relative'
	},
	googleButton: {
		marginBottom: '11px',
		backgroundColor: '#4184f3'
	},
	googleLogoWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		position: 'absolute',
		left: '1px',
		top: '1px',
		zIndex: 2,
		background: '#FFF',
		width: '48px',
		height: '48px'
	},
	googleLogo: {
		width: '30px',
		height: '30px'
	},
	facebookButton: {
		backgroundColor: '#4267b2'
	}
});
