import React from 'react';
import ReactDOM from 'react-dom';
import 'utils/pollyfills';
import './index.css';
import './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
import firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/functions';
import { firebaseConfig } from './config';

firebase.initializeApp(firebaseConfig);

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === 'development') {
	firebase.firestore().useEmulator('localhost', 8080);
	firebase.auth().useEmulator('http://localhost:9099/');
	firebase.functions().useEmulator('localhost', 5001);
}


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
