export default (theme) => ({
	orgProfileHeader: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		width: '100%',
		paddingTop: '100px',
		paddingLeft: '24px',
		paddingRight: '24px',
		paddingBottom: '24px',
		backgroundColor: theme.palette.background.paper,
		[theme.breakpoints.down('sm')]: {
			paddingTop: '75px',
			paddingBottom: '0px',
		}
	},
	sidebarHeader: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginBottom: '32px'
	},
	name: {
		textAlign: 'center'
	},
	location: {
		color: theme.palette.text.secondary,
		fontSize: '18px'
	},
	editProfileBtn: {
		marginTop: '21px',
		fontSize: '16px',
		fontFamily: 'Source Sans Pro',
		'& .MuiButton-startIcon': {
			marginRight: '3px'
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '7px',
		}
	}
});
