import { connect } from 'react-redux';
import flow from 'lodash.flow';
import PasswordReset from './PasswordReset';
import WithAuth from 'WithAuth';
import {sendPasswordReset, resetSuccess, confirmPasswordReset, verifyPasswordResetCode} from 'reactive-core';

export default flow(
	connect(
		({
			authevents: { 
				sendPasswordResetSuccess,
				verifyingPasswordResetCode,
				passwordResetCodeVerified,
				passwordResetSuccess,
				resetingPassword,
				authErroCode
			},
		}) => ({
			sendPasswordResetSuccess,
			verifyingPasswordResetCode,
			passwordResetCodeVerified,
			passwordResetSuccess,
			resetingPassword,
			authErroCode
		}),
		(dispatch) => ({
			onSubmitSend: (resetEmail) => dispatch(sendPasswordReset(resetEmail)),
			confirmPasswordReset: (resetData) =>dispatch(confirmPasswordReset(resetData)),
			verifyPasswordResetCode: (actionCode) => dispatch(verifyPasswordResetCode({actionCode})),
			resetSuccess: () => dispatch(resetSuccess())
		})
	),
	WithAuth
)(PasswordReset);
