import { connect } from 'react-redux';
import flow from 'lodash.flow';
import SignUp from './SignUp';
import WithAuth from 'WithAuth';
import { signUpUser, resetSuccess, verifyEmailActionCode } from 'reactive-core';

export default flow(
	connect(
		({
			authevents: { signUpUserSuccess, authErrorCode, emailCodeVerified, verifyingEmailCode, emailUpdateSuccess, signInUserSuccess },
		}) => ({
			authErrorCode,
			signUpUserSuccess,
			verifyingEmailCode,
			emailCodeVerified,
			emailUpdateSuccess,
			signInUserSuccess
		}),
		(dispatch) => ({
			onSubmitSignUp: (signUpData) => dispatch(signUpUser(signUpData)),
			resetSuccess: () => dispatch(resetSuccess()),
			verifyEmailActionCode: (actionCode) => dispatch(verifyEmailActionCode(actionCode))
		})
	),
	WithAuth
)(SignUp);
