import React from 'react';
import List from '@material-ui/core/List';
import KListItem from  'components/KListItem';
import KListLinkItem from  'components/KListLinkItem';
import Divider from '@material-ui/core/Divider';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
const useStyles = makeStyles(styles);

const KList = ({
	items,
	links,
	onEmptyActionButtonClick,
	emptyListText,
	emptyListButtonText,
	skeletonCount
}) => {
	const classes = useStyles();
	const getSkeletonList = () => {
		const skelList = [];
		for(let i = 0; i <skeletonCount; i++) {
			skelList.push(
				<li className={classes.itemWrapper}>
					<Skeleton variant="circle" width={60} height={60} animation="wave" />
					<div className={classes.itemContentWrapper}>
						<div className={classes.itemInfoWrapper}>
							<Skeleton width="60%" height={30} animation="wave"/>
							<Skeleton width="40%" animation="wave" />
						</div>
					</div>
				</li>
			);
		}
		return (
			<ul className={classes.itemList}>{skelList}</ul>
		);
	};
	const ListItemComponent = links ? KListLinkItem : KListItem;
	return (
		<>
			<Fade in={items === null} timeout={1000}>
				<div className={clsx({[classes.hiddenPanel]: items})}>
					{getSkeletonList()}
				</div>
			</Fade>
			<Fade in={items && items.length} timeout={1000}>
				<List disablePadding className={clsx({[classes.hiddenPanel]: (!items || items.length === 0)})}>
					{items ? 
						items.map((item, idx) => (
							<>
								<ListItemComponent item={item} />
								{idx < item.length - 1 &&
							<Divider className={classes.listItemDivider} />
								}
							</>
						))
						: null}
				</List>
			</Fade>
			<Fade in={items && items.length === 0} timeout={1000}>
				<div className={clsx(classes.emptyWrapper, {[classes.hiddenPanel]: (!items || items.length !== 0)})} >
					<Typography variant="body1" className={classes.emptyText}>{emptyListText}</Typography>
					{onEmptyActionButtonClick && 
						<Button variant="contained" color="primary" className={classes.emptyButton} onClick={onEmptyActionButtonClick}>{emptyListButtonText}</Button>
					}
				</div>
			</Fade>
		</>
	);
};

export default KList;
