import React from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
const useStyles = makeStyles(styles);

const SignUpOption = ({className}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<div className={clsx(classes.root, className)}>
			<Divider className={classes.divider} variant="fullWidth"/>
			<Typography variant="subtitle1" className={classes.subText}>{t('dont_have_an_account')}</Typography>
			<Button variant="outlined" fullWidth component={RouterLink} to="/sign-up">{t('join_kinectem')}</Button>
		</div>
	);
};

export default SignUpOption;
