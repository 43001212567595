import React, { useState, useRef } from 'react';
import Button from '@material-ui/core/Button';
import FormInput from 'components/FormInput';
import ProfileImageEdit from 'components/ProfileImageEdit';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import { Typography } from '@material-ui/core';
import { stateOptions } from 'utils/selectOptions';
const useStyles = makeStyles(styles);

const OrgSettings = ({
	organization: org,
	onSubmit,
	onCancel
}) => {
	const theme = useTheme();
	const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();
	const { t } = useTranslation();

	const refs = {
		name: useRef(null),
		websiteUrl: useRef(null),
		city: useRef(null),
		state: useRef(null),
		zipcode: useRef(null),
		missionStatement: useRef(null)
	};
	const [profileImg, setProfileImg] = useState(null);
	const [validateError, setValidateError] = useState(false);

	const optionalFields = ['websiteUrl', 'missionStatement'];
	const getUpdatedData = () => {
		const updated = { id: org.id };
		Object.keys(refs).forEach((key) => {
			if (refs[key].current.value !== org[key] && org[key] !== null || org[key] === null && refs[key].current.value !== '') {
				updated[key] = refs[key].current.value;
			}
		});
		if (profileImg) {
			updated.profileImg = profileImg;
		}
		return updated;
	};
	const handleOnSubmit = () => {
		const required = Object.keys(refs).filter((key) => !optionalFields.includes(key));
		if (required.some((key) => !refs[key].current.value)) {
			setValidateError(true);
		} else {
			onSubmit(getUpdatedData());
		}
	};

	return (
		<div className={classes.orgSettings}>
			<Typography variant="h5">{t('organization_settings')}</Typography>
			<div className={classes.actionButtons}>
				<Button onClick={onCancel} variant="outlined" className={classes.cancelBtn}>
					{t('cancel')}
				</Button>
				<Button onClick={handleOnSubmit} color="primary" variant="contained" className={classes.submitBtn}>
					{t('save')}
				</Button>
			</div>
			<div className={classes.settingsWrapper}>
				<div className={classes.pofileImgWrapper}>
					<ProfileImageEdit
						size={matchesSm ? '145' : '190'}
						showIcon
						onUpdateAvatar={(imgObj) => { setProfileImg(imgObj.file); }}
						avatarImageUrl={org.profileImgUrl}
						buttonLabel={t('choose_logo')}
						uploadTitle={t('add_organization_logo')}
					/>
				</div>
				<div className={classes.formWrapper}>
					<FormInput
						label={t('name_of_organization')}
						required
						id="org_name"
						errorText={t('field_required')}
						type="text"
						inputRef={refs.name}
						defaultValue={org.name}
						error={validateError && (refs.name && !refs.name.current.value)}
						classes={{ formControl: classes.name }}
					/>
					<FormInput
						label={t('organization_website')}
						id="org_website"
						defaultValue={org.websiteUrl}
						type="text"
						inputRef={refs.websiteUrl}
						classes={{ formControl: classes.name }}
					/>
					<div className={classes.formRow}>
						<FormInput
							label={t('city')}
							required
							id="org-city"
							errorText={t('field_required')}
							type="text"
							inputRef={refs.city}
							defaultValue={org.city}
							error={validateError && (refs.city && !refs.city.current.value)}
							classes={{ formControl: classes.city }}
						/>
						<div className={classes.mobileFormRow}>
							<FormInput
								label={t('state')}
								select
								required
								id="org-state"
								selectOptions={stateOptions(t, true)}
								native
								inputRef={refs.state}
								defaultValue={org.state}
								error={validateError && (refs.state && !refs.state.current.value)}
								errorText={t('field_required')}
								classes={{ formControl: classes.stateZipFields }}
							/>
							<FormInput
								label={t('zip_code')}
								required
								id="org-zip-code"
								error={validateError && (refs.zipcode && !refs.zipcode.current.value)}
								errorText={t('field_required')}
								type="text"
								inputRef={refs.zipcode}
								defaultValue={org.zipcode}
								classes={{ formControl: classes.stateZipFields }}
							/>
						</div>
					</div>
					<FormInput
						label={t('mission_statement')}
						id="org-mission-statement"
						type="text"
						multiline
						rows={6}
						rowsMax={1000}
						inputRef={refs.missionStatement}
						defaultValue={org.missionStatement}
						classes={{ formControl: classes.missionStatement }}
					/>
				</div>
			</div>

		</div>
	);
};

export default OrgSettings;
