import React, { useEffect } from 'react';
import clsx from 'clsx';
import LogoHeading from 'components/LogoHeading';
import FormHeading from 'components/FormHeading';
import Alert from '@material-ui/lab/Alert';
import Fade from '@material-ui/core/Fade';


import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styles from './styles';
const useStyles = makeStyles(styles);
const AuthSplashForm = ({
	formTitle,
	formSubTitle,
	children,
	splashImg,
	messageSeverity,
	message,
	classes: classesOverrides
}) => {

	const theme = useTheme();
	const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles({ splashImg, matchesSm });
	const [openAlert, setOpenAlert] = React.useState(false);
	useEffect(() => {
		if (message) {
			setOpenAlert(true);
		} else {
			setOpenAlert(false);
		}
	}, [message]);

	const formOverride = classesOverrides && classesOverrides.formWrapper;
	return (
		<div className={classes.root}>
			{!matchesSm &&
				<div className={classes.leftSide}>
					<div className={classes.leftOverlay}></div>
				</div>
			}
			<div className={classes.rightSide}>
				<div className={classes.logoWrapper}>
					<LogoHeading size="md" />
				</div>
				<div className={clsx(formOverride, classes.formWrapper)}>
					{openAlert &&
						<div className={classes.errorMsgWrapper}>
							<Fade in={openAlert}>
								<Alert
									severity={messageSeverity}
									icon={false}
								>
									{message}
								</Alert>
							</Fade>
						</div>
					}
					<FormHeading title={formTitle} subTitle={formSubTitle} />
					<>{children}</>
				</div>
			</div>
		</div>
	);
};
AuthSplashForm.defaultProps = {
	classes: {
		formWrapper: ''
	}
};

export default AuthSplashForm;
