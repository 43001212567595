import { connect } from 'react-redux';
import flow from 'lodash.flow';
import OrgMembers from './OrgMembers';
import { getOrgMembers } from '../selectors';
import {
	fetchOrgMembers,
	sendOrgAdminInvite,
	removeOrgAdmin,
	cancelOrgAdminInvite,
	resendOrgAdminInvite,
	clearTeamOrgSuccess
} from 'reactive-core';

export default flow(
	connect(
		({
			authUser: { user },
			uievents: {
				sendOrgAdminInviteError,
				sendingOrgAdminInvite,
				sendOrgAdminInviteSuccess,
				removingOrgAdmin,
				removeOrgAdminSuccess,
				cancelingOrgAdminInvite,
				cancelOrgAdminInviteSuccess,
				resendingOrgAdminInvite,
				resendOrgAdminInviteSuccess
			},
			profile: { organization }
		}) => ({
			authUser: user,
			sendOrgAdminInviteError,
			sendingOrgAdminInvite,
			sendOrgAdminInviteSuccess,
			removingOrgAdmin,
			removeOrgAdminSuccess,
			cancelingOrgAdminInvite,
			cancelOrgAdminInviteSuccess,
			resendingOrgAdminInvite,
			resendOrgAdminInviteSuccess,
			members: getOrgMembers({ orgMembers: organization.orgMembers, adminInvites: organization.adminInvites })
		}),
		(dispatch) => ({
			fetchOrgMembers: (orgData) => dispatch(fetchOrgMembers(orgData)),
			sendOrgAdminInvite: (inviteData) => dispatch(sendOrgAdminInvite(inviteData)),
			removeOrgAdmin: (memeberData) => dispatch(removeOrgAdmin(memeberData)),
			cancelOrgAdminInvite: (id) => dispatch(cancelOrgAdminInvite(id)),
			resendOrgAdminInvite: (id) => dispatch(resendOrgAdminInvite(id)),
			clearTeamOrgSuccess: () => dispatch(clearTeamOrgSuccess())
		})
	)
)(OrgMembers);
