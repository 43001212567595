export default () => ({
	listItem: {
		paddingLeft: '0px',
		paddingTop: '16px',
		paddingBottom: '16px'
	},
	avatar: {
		height: '60px',
		width: '60px',
		marginLeft: '16px',
		marginRight: '21px'
	},
	rightArrow: {
		height: '24px',
		width: '14px'
	},
	listItemText: {
		fontSize: '18px'
	}
});
