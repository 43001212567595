import React, { useRef, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormInput from 'components/FormInput';
import Typography from '@material-ui/core/Typography';
import DobFormInput from 'components/DobFormInput';
import { genderOptions } from 'utils/selectOptions';

import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import styles from './styles';
const useStyles = makeStyles(styles);

const AddChildModal = ({
	onClose,
	show,
	onSubmit,
	teams
}) => {
	const theme = useTheme();
	const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();
	const { t } = useTranslation();
	const [validateError, setValidateError] = useState(false);

	const refs = {
		firstName: useRef(null),
		lastName: useRef(null),
		gender: useRef(null),
		dob: useRef({ value: null }),
		teamId: useRef(null)
	};
	const handleOnSubmit = () => {
		const keys = Object.keys(refs);
		if (keys.filter((key) => key !== 'teamId').some((key) => !refs[key].current.value)) {
			setValidateError(true);
		} else {
			let updated = {};
			keys.forEach((key) => updated[key] = refs[key].current.value);
			onSubmit(updated);
		}
	};
	const getTeamOptions = () => teams.map((team) => <option key={team.id} value={team.id}>{team.name}</option>);
	return (
		<Dialog
			onClose={onClose}
			aria-labelledby="add-child-title"
			maxWidth="sm"
			fullScreen={matchesSm}
			disableBackdropClick
			classes={{ paperWidthSm: classes.paperWidthSm }}
			open={show}
		>
			<DialogTitle id="add-child-title" disableTypography className={classes.titleWrapper}>
				<Typography variant="h5" component="h1" className={classes.title}>
					{t('add_a_child_to_your_account')}
				</Typography>
				<Typography variant="body1" color="textSecondary" className={classes.subText}>
					{t('enter_your_child_info')}
				</Typography>
			</DialogTitle>
			<DialogContent className={classes.contentWrapper}>
				<div className={classes.formWrapper} >
					<div className={classes.formRow}>
						<FormInput
							label={t('first_name')}
							required
							id="first_name"
							fullWidth
							errorText={t('field_required')}
							type="text"
							inputRef={refs.firstName}
							error={validateError && (refs.firstName && !refs.firstName.current.value)}
							classes={{ formControl: classes.name }}
						/>
						<FormInput
							label={t('last_name')}
							required
							id="last_name"
							fullWidth
							errorText={t('field_required')}
							type="text"
							inputRef={refs.lastName}
							error={validateError && (refs.lastName && !refs.lastName.current.value)}
							classes={{ formControl: classes.name }}
						/>
					</div>
					<DobFormInput
						date={null}
						required
						error={validateError && (refs.dob && !refs.dob.current.value)}
						errorText={t('field_required')}
						dobRef={refs.dob}
					/>
					<FormInput
						label={t('gender')}
						select
						required
						selectOptions={genderOptions(t, false)}
						native
						errorText={t('field_required')}
						inputRef={refs.gender}
						error={validateError && (refs.gender && !refs.gender.current.value)}
						classes={{ formControl: classes.gender }}
					/>
					<FormInput
						label={t('team')}
						select
						selectOptions={getTeamOptions()}
						native
						disabled={!teams.length}
						inputRef={refs.teamId}
						error={validateError && (refs.teamId && !refs.teamId.current.value)}
						classes={{ formControl: classes.team }}
					/>
				</div>
			</DialogContent>
			<DialogActions className={classes.actionsWrapper} >
				<Button onClick={onClose} variant="outlined" className={classes.cancelBtn}>
					{t('cancel')}
				</Button>
				<Button onClick={handleOnSubmit} color="primary" variant="contained" className={classes.submitBtn}>
					{t('add_child')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AddChildModal;
