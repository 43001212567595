import React, {useEffect, useState} from 'react';
import CreateEditArticle from '../CreateEditArticle';
import {
	Link as RouterLink,
	useHistory,
	useParams
} from 'react-router-dom';
import { ReactComponent as BackArrowIcon } from 'icons/Back_arrow_icon.svg';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Toast from 'components/Toast';
import {TEAM} from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';


const useStyles = makeStyles(styles);
const Article = ({
	isAdmin,
	article,
	fetchArticle,
	team,
	org,
	backUrl,
	createArticleSuccess,
	updateArticleSuccess,
	deleteArticleSuccess,
	clearArticleSuccess
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const history = useHistory();
	let { objId, action, teamId, orgId } = useParams();
	const [toastProps, setToastProps] = useState({
		open: false,
		message: '',
		severity: null
	});
	useEffect(() => {
		if(objId !== 'create') {
			// fetch the article
			fetchArticle(objId);
		}
	}, [objId]);
	useEffect(() => {
		if((createArticleSuccess || updateArticleSuccess) && (action !== 'edit' && objId !== 'create')) {
			setToastProps({
				open: true,
				message: t('article_published_success'),
				severity: 'success'
			});
		}
	}, [createArticleSuccess, updateArticleSuccess, action, objId]);
	useEffect(() => {
		if(deleteArticleSuccess) {
			history.replace(`/${teamId ? `team/${teamId}` : `organization/${orgId}`}/highlights`);
		}
	}, [deleteArticleSuccess]);
	if(objId === 'create' && isAdmin) {
		return (<CreateEditArticle />);
	}
	if(objId && isAdmin && action === 'edit') {
		return article && (<CreateEditArticle isEdit/>);
	}
	const onToastClose = () => {
		setToastProps({
			open: false,
			message: '',
			severity: ''
		});
		clearArticleSuccess();
	};
	return (
		<div className={classes.article}>
			{article && (
				<>
					<div className={classes.actionButtonWrapper}>
						<Button
							className={classes.backButton}
							component={RouterLink}
							color='primary'
							startIcon={<BackArrowIcon />}
							to={
								backUrl
									? backUrl
									: `/${article.type}/${article.entityId}/highlights`
							}
						>
							{t('back')}
						</Button>
						<div className={classes.actionButtonWrapper}>
							<Button
								className={classes.shareToFacebookButton}
								variant='outlined'
								color='default'
								onClick={() => {
									// eslint-disable-next-line
									FB.ui(
										{
											method: 'share',
											href: document.location.href,
											quote: 'Check out this article on Kinectem!',
											hashtag: 'kinectem'
										},
										function (response) {
											console.log(response);
										}
									);
								}}
							>
								Share to Facebook
							</Button>
							{isAdmin && (
								<Button
									className={classes.editButton}
									component={RouterLink}
									color="primary"
									variant="outlined"
									to={`/${article.type}/${article.entityId}/article/${article.id}/edit`}
								>
									{t('edit_article')}
								</Button>
							)}
						</div>
					</div>
					<Paper className={classes.articleWrapper}>
						<div className={classes.header}>
							<Typography variant="h4">{article.title}</Typography>
							<div className={classes.subTextRow}>
								<Typography
									variant="body1"
									component="span"
									color="textSecondary"
									className={classes.entityName}
								>
									{article.type === TEAM ? team.name : org.name}
								</Typography>
								<Typography
									variant="body1"
									component="span"
									color="textSecondary"
									className={classes.date}
								>
									{article.date}
								</Typography>
							</div>
						</div>
						<div className={classes.body}>
							<Typography variant="body1" className={classes.body}>
								{article.body}
							</Typography>
						</div>
					</Paper>
				</>
			)}
			<Toast {...toastProps} onClose={onToastClose} />
		</div>
	);
};

export default Article;
