export default (theme) => ({
	paperWidthSm: {
		[theme.breakpoints.up('sm')]: {
			minWidth: '700px',
			maxWidth: '700px'
		}
	},
	titleWrapper: {
		padding: '32px 36px 32px 36px',
		[theme.breakpoints.down('sm')]: {
			padding: '16px'
		}
	},
	contentWrapper: {
		paddingLeft: '36px',
		paddingRight: '36px',
		[theme.breakpoints.down('sm')]: {
			padding: '16px'
		}
	},
	actionsWrapper: {
		marginBottom: '30px',
		marginTop: '30px',
		[theme.breakpoints.down('sm')]: {
			margin: '8px'
		}
	},
	cancelBtn: {
		paddingLeft: '46px',
		paddingRight: '46px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	submitBtn: {
		paddingLeft: '39px',
		paddingRight: '39px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			paddingLeft: '16px',
			paddingRight: '16px',
			fontSize: '14px',
			height: '52px'
		}
	},
	copyLinkWrapper: {
		backgroundColor: '#f8f8f8',
		padding: '11px 16px 20px 16px',
		border: '1px solid #d6d6d6',
		borderRadius: '4px'
	},
	copyButton: {
		width: '50px',
		height: '50px',
		position: 'absolute',
		right: '0px',
		bottom: '0px'
	}
});
