import { connect } from 'react-redux';
import flow from 'lodash.flow';
import CreateOrgModal from './CreateOrgModal';
import {
	hideCreateOrg,
	createOrganization
} from 'reactive-core';

export default flow(
	connect(
		({
			authUser: {user},
			uievents: { showCreateOrg }
		}) => ({
			authUser: user,
			show: showCreateOrg
		}),
		(dispatch) => ({
			onClose: () => dispatch(hideCreateOrg()),
			onSubmit: (orgData) => dispatch(createOrganization(orgData))
		})
	)
)(CreateOrgModal);
