import React from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import Alert from '@material-ui/lab/Alert';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
const useStyles = makeStyles(styles);

const Toast = ({
	open,
	onClose,
	message,
	severity
}) => {
	const classes = useStyles();
	return (
		<Snackbar
			className={clsx(classes.root, { [classes.hide]: !open })}
			open={open}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			onClose={onClose}
			TransitionComponent={(props) => <Slide {...props} direction="down" />}
			transitionDuration={{ exit: 1000 }}
			autoHideDuration={5000}
			disableWindowBlurListener
		>
			<Alert onClose={onClose} variant="filled" severity={severity} icon={false} className={classes.alert}>
				{message}
			</Alert>
		</Snackbar>
	);
};

export default Toast;
