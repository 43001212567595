export default (theme) => ({
	paperWidthSm: {
		[theme.breakpoints.up('sm')]: {
			minWidth: '559px',
			maxWidth: '559px'
		}
	},
	titleWrapper: {
		padding: '32px 36px 32px 36px',
		[theme.breakpoints.down('sm')]: {
			padding: '16px'
		}
	},
	formRow: {
		display: 'flex',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column'
		}
	},
	name: {
		marginLeft: '16px',
		'&:first-child': {
			margin: '0px'
		},
		[theme.breakpoints.down('sm')]: {
			margin: '0px'
		}
	},
	contentWrapper: {
		paddingLeft: '36px',
		paddingRight: '36px',
		[theme.breakpoints.down('sm')]: {
			padding: '16px'
		}
	},
	actionsWrapper: {
		marginBottom: '50px',
		marginTop: '30px',
		[theme.breakpoints.down('sm')]: {
			margin: '8px'
		}
	}
});
