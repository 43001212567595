export default (theme) => ({
	paperWidthMd: {
		[theme.breakpoints.up('sm')]: {
			maxWidth: '697px',
			minWidth: '697px'
		}
	},
	title: {
		[theme.breakpoints.up('sm')]: {
			fontSize: '26px'
		}
	},
	titleWrapper: {
		padding: '32px 36px 32px 36px',
		[theme.breakpoints.down('sm')]: {
			padding: '16px'
		}
	},
	contentWrapper: {
		display: 'flex',
		paddingTop: '20px',
		paddingLeft: '66px',
		paddingRight: '55px',
		[theme.breakpoints.down('sm')]: {
			padding: '16px'
		}
	},
	formRow: {
		display: 'flex',
		alignItems: 'flex-end',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			alignItems: 'flex-start',
		}
	},
	mobileFormRow: {
		display: 'flex',
		alignItems: 'flex-end'
	},
	stateZipFields: {
		width: '176px',
		marginLeft: '16px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			margin: '0px',
			'&:not(:first-child)': {
				marginLeft: '16px'
			},
		}
	},
	city: {
		width: '176px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	actionsWrapper: {
		paddingBottom: '20px',
		paddingTop: '20px',
		[theme.breakpoints.down('sm')]: {
			padding: '8px'
		}
	},
	cancelBtn: {
		marginRight: '14px',
		width: '141px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	submitBtn: {
		width: '206px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	medWidthFields: {
		width: '176px',
		marginLeft: '16px',
		'&:first-child': {
			margin: '0px'
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	sport: {
		width: '368px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	},
	coachName: {
		'&:first-child': {
			marginTop: '20px'
		},
		[theme.breakpoints.up('sm')]: {
			marginTop: '20px',
			'&:last-child': {
				marginLeft: '16px'
			}
		}
	},
});
