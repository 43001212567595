import React, { useEffect } from 'react';
import {
	Route,
	Switch,
	Redirect,
	useHistory,
	useRouteMatch
} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import TopBar from 'containers/TopBar';
import UserProfile from 'containers/UserProfile';
import OrganizationProfile from 'containers/OrganizationProfile';
import TeamProfile from 'containers/TeamProfile';
import CreateOrgModal from 'containers/CreateOrgModal';
import Settings from 'containers/Settings';
import PageNotFound from '../components/PageNotFound';
import { useQuery } from 'utils/hooks/customHooks';
import styles from './styles';
const useStyles = makeStyles(styles);
const Root = ({
	fetchAuthUser,
	fetchingAuthUser,
	user,
	showCreateUserProfile,
	createdOrgId,
	clearCreatedOrgId,
	signOutAuthUserSuccess,
	isSignedIn,
	acceptInvite,
	acceptInviteSuccess,
	invite,
	resetSuccess,
	clearResourceNotFound
}) => {
	const history = useHistory();
	const query = useQuery();
	const inviteId = query.get('invite');
	const isParent = query.get('isParent');

	useEffect(() => {
		if (isSignedIn) {
			fetchAuthUser();
		}
	}, []);
	useEffect(() => {
		if (signOutAuthUserSuccess) {
			history.push('/sign-in');
		}
	}, [signOutAuthUserSuccess]);
	useEffect(() => {
		if (createdOrgId) {
			history.push(`/organization/${createdOrgId}/teams`);
			clearCreatedOrgId();
		}
	}, [createdOrgId]);
	useEffect(() => {
		// checking to see if we have a signed in user with a profile
		if (isSignedIn && inviteId && user && user.id) {
			acceptInvite(inviteId);
		}
	}, [isSignedIn, user, inviteId]);
	useEffect(() => {
		if (acceptInviteSuccess) {
			if (isParent) {
				history.push('/settings/children');
			} else if (invite.type === 'team') {
				history.push(`/team/${invite.entityId}/roster`);
			} else {
				history.push(`/organization/${invite.entityId}/highlights`);
			}
			resetSuccess();
		}
	}, [acceptInviteSuccess, invite]);

	const classes = useStyles();
	const feedMatch = useRouteMatch('/highlights');
	if (!isSignedIn && feedMatch) {
		return <Redirect to="/sign-in" />;
	}
	if (isSignedIn && showCreateUserProfile) {
		return <Redirect to={`/create-profile${history.location.search}`} />;
	}

	return (
		<>
			{isSignedIn && fetchingAuthUser || inviteId
				?
				<div></div>
				:
				<>
					<TopBar />
					<div className={classes.offset} />
					<Switch>
						<Route exact path="/">
							<Redirect to="/highlights" />
						</Route>
						<Route exact path="/settings">
							<Redirect to="/settings/edit-profile" />
						</Route>
						<Route path={['/highlights']}>
							<UserProfile />
						</Route>
						<Route path={['/settings/:tab?/:userId?']}>
							<Settings />
						</Route>
						<Route exact path={['/user/:userId/:tab?']} >
							<UserProfile />
						</Route>
						<Route path={['/organization/:orgId/:tab?/:objId?/:action?']}>
							<OrganizationProfile />
						</Route>
						<Route path={['/team/:teamId/:tab?/:objId?/:action?']}>
							<TeamProfile />
						</Route>
						<Route path="*">
							<PageNotFound clearResourceNotFound={clearResourceNotFound} />
						</Route>
					</Switch>

					<CreateOrgModal />
				</>
			}
		</>
	);
};

export default Root;
