import { connect } from 'react-redux';
import flow from 'lodash.flow';
import {clearCreatedOrgId} from 'reactive-core';
import Routes from './Routes';
import WithAuth from 'WithAuth';
export default flow(
	connect(
		({
			uievents: {showCreateUserProfile, createdOrgId},
			authevents: {signOutAuthUserSuccess}
		}) => ({
			createdOrgId,
			showCreateUserProfile,
			signOutAuthUserSuccess
		}),
		(dispatch) => ({
			clearCreatedOrgId: () => dispatch(clearCreatedOrgId())
		})
	),
	WithAuth
)(Routes);
