export default (theme) => ({
	paperWidthSm: {
		maxWidth: '800px'
	},
	title: {
		textAlign: 'center',
	},
	contentWrapper: {
		padding: '0px',
		display: 'flex',
		flexDirection: 'column'
	},
	cropContainer: {
		position: 'relative',
		height: '350px',
		[theme.breakpoints.down('sm')]: {
			height: '554px'
		},
		backgroundColor:'#00171f'
	},
	cropArea: {
		color:' rgba(0, 23, 31, 0.5) !important'
	},
	sliderToolbar: {
		width: '435px',
		[theme.breakpoints.down('sm')]: {
			width: '375px'
		},
		display: 'flex',
		alignSelf: 'center'
	},
	actionsWrapper: {
		marginTop: '13px',
		marginBottom: '27px'
	},
	cancelButton: {
		width: '160px',
	},
	saveButton: {
		width: '200px',
		marginLeft: '16px'
	},
	sliderIcons: {
		height: '2px',
		width: '17px',
		'&:last-child': {
			height: '18px',
			width: '18px',
			marginLeft: '11px'
		},
		marginRight:'11px'
	},
	sliderRail: {
		color: '#e6e6e6',
		borderRadius: '4px',
		height: '5px',
		opacity: 1
	},
	sliderTrack: {
		borderRadius: '4px',
		height: '5px',
	},
	sliderThumb: {
		width: '20px',
		height: '20px',
		marginTop: '-7px'
	}
});
