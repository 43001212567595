export default (theme) => ({
	itemList: {
		listStyle: 'none',
		padding: 0,
		margin: 0
	},
	itemWrapper: {
		display: 'flex',
		alignItems: 'center',
		'&:last-child': {
			'& $itemContentWrapper': {
				borderBottom: 'none'
			}
		}
	},
	itemContentWrapper: {
		marginLeft: '20px',
		paddingTop: '20px',
		paddingBottom: '20px',
		flex: 1,
		borderBottom: '1px solid #e6e6e6',
	},
	emptyWrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		minHeight: '190px'
	},
	emptyText: {
		color: theme.palette.text.secondary
	},
	emptyButton: {
		marginTop: '10px',
		minWidth: '167px'
	},
	listItemDivider: {
		marginLeft: '81px'
	},
	hiddenPanel: {
		display: 'none'
	}
});
