import React from 'react';
import { US_STATES, SPORTS } from 'utils/constants';
export const genderOptions = (t, currentVal) => (
	<>
		{!currentVal && <option key='empty' value='empty'></option>}
		<option key='female' value='female'>{t('female')}</option>
		<option key='male' value='male'>{t('male')}</option>
		<option key='other' value='other'>{t('other')}</option>
	</>
);
export const stateOptions = (t, currentVal) => {
	const options = US_STATES.map((state) => (<option key={state} value={state}>{t(`common:${state}`)}</option>));
	if (!currentVal) {
		options.unshift(<option key='empty' value='empty'></option>);
	}
	return options;
};
export const teamGenderOptions = (t, currentVal) => (
	<>
		{!currentVal && <option key='empty' value='empty'></option>}
		<option key='female' value='female'>{t('female')}</option>
		<option key='male' value='male'>{t('male')}</option>
		<option key='coed' value='coed'>{t('coed')}</option>
	</>
);
export const ageGroupOptions = (t, currentVal) => {
	const today = new Date();
	const startingYear = today.getFullYear() - 5;
	const birthYears = [];
	for (let i = startingYear; i >= startingYear - 30; i--) {
		birthYears.push(<option key={i} value={i}>{i}</option>);
	}
	const ages = [];
	for (let i = 5; i <= 30; i++) {
		ages.push(<option key={`u${i}`} value={`u${i}`}>{t('u_age', { age: i })}</option>);
	}

	return (
		<>
			{!currentVal && <option key='empty' value='empty'></option>}
			<optgroup label={t('birth_year')}>
				{birthYears}
			</optgroup>
			<optgroup label={t('age')}>
				{ages}
			</optgroup>
			<optgroup label={t('level')}>
				<option key='freshman' value='freshman'>{t('freshman')}</option>
				<option key='junior_varsity' value='junior_varsity'>{t('junior_varsity')}</option>
				<option key='varsity' value='varsity'>{t('varsity')}</option>
			</optgroup>
		</>
	);
};
export const sportOptions = (t, currentVal) => [!currentVal && <option key='empty' value='empty'></option>].concat(
	SPORTS.map((sport) => (
		<option key={sport} value={sport}>{t(sport)}</option>
	)));
