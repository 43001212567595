import React, { useState, useEffect } from 'react';
import { useQuery } from 'utils/hooks/customHooks';
import AuthSplashForm from 'components/AuthSplashForm';
import EmailForm from './EmailForm';
import PasswordForm from './PasswordForm';
import splash from 'img/signin-splash.png';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import styles from './styles';
import {
	FB_AUTH_EXPIRED_ACTION_CODE,
	FB_AUTH_INVALID_ACTION_CODE
} from 'utils/constants';

const useStyles = makeStyles(styles);
const PasswordReset = ({
	onSubmitSend,
	resetSuccess,
	sendPasswordResetSuccess,
	verifyPasswordResetCode,
	passwordResetCodeVerified,
	passwordResetSuccess,
	confirmPasswordReset,
	authErroCode,
	isReset,
	isSignedIn
}) => {
	const theme = useTheme();
	const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles({ matchesSm });
	const { t } = useTranslation();
	const query = useQuery();
	const history = useHistory();
	const inviteId = query.get('invite');
	const isParent = query.get('isParent');
	const [loginEmail, setLoginEmail] = useState('');
	const [loginPassword, setLoginPassword] = useState('');


	useEffect(() => {
		resetSuccess();
		if (isSignedIn) {
			history.replace(`/${history.location.search}`);
		}
		if (isReset) {
			verifyPasswordResetCode(query.get('oobCode'));
		}
	}, []);
	useEffect(() => {
		if (passwordResetSuccess) {
			history.push(`/sign-in${history.location.search}`, { reason: 'password_reset_success' });
		}
	}, [passwordResetSuccess]);

	const handleSendClick = () => {
		const linkData = { email: loginEmail };
		if (inviteId) {
			linkData['inviteId'] = inviteId;
		}
		if (isParent === 'true') {
			linkData['isParent'] = true;
		}
		onSubmitSend(linkData);
	};
	const handlePasswordClick = () => {
		confirmPasswordReset({
			actionCode: query.get('oobCode'),
			newPassword: loginPassword
		});
	};
	let formTitle = null;
	let formSubTitle = null;
	let rightSide = null;
	let message = null;
	let splashClasess = { formWrapper: classes.fullWidthForm };

	if (sendPasswordResetSuccess) {
		formTitle = t('password_reset_sent');
		formSubTitle = t('check_email_password_reset');
		rightSide = (
			<Button
				component={RouterLink}
				to={`/login${history.location.search}`}
				variant="contained"
				color="primary"
				className={classes.loginLinkBtn}
			>{t('login')}</Button>
		);
	} else if (passwordResetCodeVerified) {
		formTitle = t('reset_your_password');
		rightSide = (
			<PasswordForm
				t={t}
				classes={classes}
				onPasswordInputChange={(e) => { setLoginPassword(e.target.value); }}
				onResetPasswordClick={handlePasswordClick}
			/>
		);
	} else {
		formTitle = t('password_reset');
		formSubTitle = t('enter_email_used_to_register');
		splashClasess = { formWrapper: classes.formWrapper };
		if (authErroCode === FB_AUTH_EXPIRED_ACTION_CODE || authErroCode === FB_AUTH_INVALID_ACTION_CODE) {
			message = t('password_reset_link_expired');
		} else if (authErroCode) {
			message = t(authErroCode);
		}
		rightSide = (
			<EmailForm
				t={t}
				classes={classes}
				onEmailInputChange={(e) => { setLoginEmail(e.target.value); }}
				onSendClick={handleSendClick}
			/>
		);
	}
	return (
		<AuthSplashForm
			formTitle={formTitle}
			formSubTitle={formSubTitle}
			splashImg={splash}
			classes={splashClasess}
			messageSeverity="error"
			message={message}
		>
			{(!isReset || (isReset && passwordResetCodeVerified !== null)) && rightSide}
		</AuthSplashForm>
	);
};

export default PasswordReset;
