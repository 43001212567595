export default (theme) => ({
	formWrapper: {
		marginTop: '127px',
		maxWidth: '100%',
		minWidth: '542px',
		textAlign: 'center'
	},
	inviteWrapper: {
		marginTop: '76px'
	},
	signUpButton: {
		marginTop: '30px',
		[theme.breakpoints.up('sm')]: {
			width: '230px',
		}
	},
	boldText: {
		fontFamily: 'Source Sans Pro Semi Bold',
		lineHeight: '1.28',
		letterSpacing: ' -0.36px',
		fontSize: '18px'
	},
	lowerText: {
		marginTop: '76px'
	}
});
