import React from 'react';
import {Provider as ReduxProvider} from 'react-redux';
import {getStore} from 'reactive-core';
import './App.css';

import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import CssBaseline from '@material-ui/core/CssBaseline';
import Routes from 'Routes';

const store = getStore();

function App() {
	return (
		<ThemeProvider theme={theme}>
			<ReduxProvider store={store}>
				<CssBaseline />
				<Routes/>
			</ReduxProvider>
		</ThemeProvider>
	);
}

export default App;
