export default (theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative'
	},
	offset: theme.mixins.toolbar,
	backgroundImgWrapper: props => ({
		height: '225px',
		display: 'flex',
		position: 'relative',
		backgroundImage: `url(${props.defaultBackgroundImg})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		zIndex: 99,
		[theme.breakpoints.down('sm')]: {
			height: '151px',
			backgroundPosition: 'center',
		}
	}),
	mainContainer: {
		display: 'flex',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column'
		}
	},
	sidebarContainerBg: {
		width: '364px',
		backgroundColor: '#fff',
		boxShadow: '0 0 3px 0 #00000029',
		position: 'fixed',
		height: '100%',
		zIndex: 1,
		top: '0px'
	},
	sidebarContainer: {
		zIndex: 100,
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		width: '364px',
		minWidth: '364px',
		position: 'relative',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			minWidth: '100%'
		}
	},
	avatar: {
		position: 'absolute',
		top: '-95px',
		zIndex: 100,
		width: '190px',
		height: '190px',
		[theme.breakpoints.down('sm')]: {
			width: '120px',
			height: '120px',
			top: '-62px',
		}
	},
	tabContainer: {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
	},
	tabWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		paddingRight: '43px',
		paddingLeft: '26px',
		width: '100%',
		borderBottom: '1px solid #e6e6e6',
		[theme.breakpoints.down('sm')]: {
			borderTop: '1px solid #e6e6e6',
			paddingTop: '16px',
			paddingRight: '16px',
			paddingLeft: '16xp',
		}
	},
	tabs: {
		paddingTop: '45px',
		[theme.breakpoints.down('sm')]: {
			paddingTop: '0px'
		}
	},
	scrollTabContainer: {
		position: 'absolute',
		width: '100%',
		display: 'flex',
		borderBottom: '1px solid #e6e6e6',
		backgroundColor: '#fff'
	},
	scrollTabWrapper: {
		width: 'auto',
		border: 'none'
	},
	showScrollTab: {
		position: 'fixed',
		zIndex: 100
	},
	scrollTabAvatarWrapper: {
		width: '364px',
		display: 'flex',
		alignItems: 'center',
		paddingLeft: '26px',
		paddingRight: '26px'
	},
	scrollTabAvatar: {
		width: '45px',
		height: '45px'
	},
	scrollTabTitle: {
		fontSize: '16px',
		marginLeft: '20px'
	},
	profileTab: {
		fontFamily: 'Source Sans Pro',
		[theme.breakpoints.down('sm')]: {
			marginRight: '16px'
		}
	},
	profileTabSelected: {
		fontFamily: 'Source Sans Pro Semi Bold'
	},
	rightSide: {
		display: 'flex',
		marginLeft: '40px',
		paddingBottom: '14px',
		paddingTop: '14px'
	},
	settingsButton: {
		marginRight: '27px',
		[theme.breakpoints.down('sm')]: {
			position: 'absolute',
			left: '4px',
			top: '4px'
		}
	},
	actionButton: {
		width: '167px'
	},
	hiddenPanel: {
		display: 'none'
	},
	backButton: {
		fontSize: '18px',
		letterSpacing: '-0.36px',
		marginRight: '24px'
	}
});
