import React from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { ReactComponent as GoogleLogo } from 'img/Google_G.svg';
import styles from './styles';
const useStyles = makeStyles(styles);

const ProviderLogin = ({ onSignInWithGoogle }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<div className={classes.root}>
			<div className={classes.divderWrapper}>
				<Divider className={classes.divider} variant="fullWidth" />
				<Typography variant="subtitle1" className={classes.orText}>
					{t('or')}
				</Typography>
				<Divider className={classes.divider} variant="fullWidth" />
			</div>
			<div className={classes.googleButtonWrapper}>
				<div className={classes.googleLogoWrapper}>
					<GoogleLogo className={classes.googleLogo} />
				</div>
				<Button
					variant="contained"
					color="primary"
					fullWidth
					className={classes.googleButton}
					onClick={() => onSignInWithGoogle()}
				>
					{t('continue_with_google')}
				</Button>
			</div>
		</div>
	);
};

export default ProviderLogin;
