import React, { useState, useRef } from 'react';
import { DateTime } from 'luxon';
import FormInput from 'components/FormInput';
import DobFormInput from 'components/DobFormInput';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { genderOptions, stateOptions } from 'utils/selectOptions';
import styles from './styles';
const useStyles = makeStyles(styles);

const CreateProfileForm = ({ onSubmit }) => {
	const theme = useTheme();
	const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();
	const { t } = useTranslation();

	const refs = {
		gender: useRef(null),
		dob: useRef({ value: null }),
		city: useRef(null),
		state: useRef(null),
		zipcode: useRef(null)
	};
	const [validateError, setValidateError] = useState(false);
	const [validateDobError, setValidateDobError] = useState(false);
	const validateDob = () =>{
		const dob = refs.dob.current.value;
		if(dob) {
			const dobDT = DateTime.fromMillis(dob);
			const now = DateTime.local();
			const { years } = now.diff(dobDT, 'years').toObject();
			if ((years < 13) || years < 3) {
				setValidateDobError(true);
				return true;
			}
		}
		setValidateDobError(false);
		return false;
	};
	const handleOnSubmitClick = () => {
		const keys = Object.keys(refs);
		if (keys.some((key) => !refs[key].current.value) || validateDob()) {
			setValidateError(true);
		} else {
			let updated = {};
			keys.forEach((key) => updated[key] = refs[key].current.value);
			onSubmit(updated);
		}
	};
	return (
		<>
			{validateDobError &&
				<Box mb={2}>
					<Fade in={validateDobError}>
						<Alert
							severity="error"
							icon={false}
						>
							{t('must_be_over_13')}
						</Alert>
					</Fade>
				</Box>
			}
			<DobFormInput
				date={null}
				required
				error={(validateError && (refs.dob && !refs.dob.current.value))}
				errorText={t('field_required')}
				dobRef={refs.dob}
			/>
			<FormInput
				label={t('gender')}
				select
				required
				selectOptions={genderOptions(t, false)}
				native
				errorText={t('field_required')}
				inputRef={refs.gender}
				error={validateError && (refs.gender && !refs.gender.current.value)}
				classes={{ formControl: classes.medWidthFields }}
			/>
			<FormInput
				label={t('city')}
				required
				id="profile-city"
				fullWidth
				errorText={t('field_required')}
				type="text"
				inputRef={refs.city}
				error={validateError && (refs.city && !refs.city.current.value)}
				classes={{ formControl: classes.cityField }}
			/>
			<div className={classes.stateZipWrapper}>
				<FormInput
					label={t('state')}
					select
					required
					selectOptions={stateOptions(t, false)}
					native
					errorText={t('field_required')}
					inputRef={refs.state}
					error={validateError && (refs.state && !refs.state.current.value)}
					classes={{ formControl: classes.stateZipFields }}
				/>
				<FormInput
					label={t('zip_code')}
					required
					id="profile-zip-code"
					fullWidth
					errorText={t('field_required')}
					type="text"
					inputRef={refs.zipcode}
					error={validateError && (refs.zipcode && !refs.zipcode.current.value)}
					classes={{ formControl: classes.stateZipFields }}
				/>
			</div>
			<Button variant="contained" color="primary" fullWidth={matchesSm} className={classes.submitButton} onClick={handleOnSubmitClick}>{t('next')}</Button>
		</>
	);
};

export default CreateProfileForm;
