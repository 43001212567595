/* eslint-disable no-empty-pattern */
import { connect } from 'react-redux';
import flow from 'lodash.flow';
import OrgHighlights from './OrgHighlights';
import { fetchOrgArticles, deleteArticle, clearArticleSuccess } from 'reactive-core';
import {getArticleCardsForEntity} from 'selectors/articleSelector';

export default flow(
	connect(
		({
			uievents: {deleteArticleSuccess},
			profile: { organization }
		}) => ({
			org: organization,
			articles: getArticleCardsForEntity({entity:organization}) || null,
			deleteArticleSuccess
		}),
		(dispatch) => ({
			fetchOrgArticles: (id) => dispatch(fetchOrgArticles(id)),
			deleteArticle: (id) => dispatch(deleteArticle(id)),
			clearArticleSuccess: () => dispatch(clearArticleSuccess())
		})
	)
)(OrgHighlights);
