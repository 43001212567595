import React, { useEffect, useState } from 'react';
import {
	Link as RouterLink,
	useHistory,
	useParams
} from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Avatar from '@material-ui/core/Avatar';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { ReactComponent as RightArrowIcon } from 'icons/Right_arrow_icon.svg';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Fade from '@material-ui/core/Fade';

import EditProfile from './EditProfile';
import AccountSettings from './AccountSettings';
import ChildAccountSettings from './ChildAccountSettings';
import ChildrenSettings from './ChildrenSettings';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styles from './styles';

const useStyles = makeStyles(styles);

const Settings = ({
	authUser,
	isSignedIn,
	emailUpdateSuccess,
	updatingEmail,
	profileUser,
	fetchChildFullProfile,
	fetchAuthUserFullProfile
}) => {
	const theme = useTheme();
	const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();
	const { t } = useTranslation();
	const { tab, userId } = useParams();
	const history = useHistory();
	const [user, setUser] = useState(null);
	useEffect(() => {
		if (!isSignedIn && emailUpdateSuccess) {
			history.replace('/sign-up');
		} else if (!isSignedIn && !updatingEmail) {
			history.replace('/sign-in');
		}
	}, [isSignedIn, emailUpdateSuccess, updatingEmail]);

	useEffect(() => {
		if (userId) {
			fetchChildFullProfile(userId);
		} else {
			fetchAuthUserFullProfile();
		}
	}, [userId]);
	useEffect(() => {
		setUser(profileUser);
	}, [profileUser]);

	const handleTabChange = (event, newValue) => {
		history.push(`/settings/${newValue}${userId ? `/${userId}` : ''}`);
	};
	let pageTitle = '';
	if (tab === 'edit-profile') {
		pageTitle = 'edit_profile';
	} else if (tab === 'account-settings') {
		pageTitle = 'account_settings';
	} else {
		pageTitle = tab;
	}

	return user && (
		<>
			<div className={classes.settingsTop}>
				{!matchesSm &&
					<div className={classes.settingsHeader}>
						<Avatar alt={`${user.firstName} ${user.lastName}`} src={user.profileImgUrl} className={classes.avatar} />
						<div className={classes.settingsBreadCrumbs}>
							<Breadcrumbs
								separator={<RightArrowIcon className={classes.rightArrow} />}
								aria-label="breadcrumb"
								classes={{ 'separator': classes.breadCrumbSep }}
							>
								<Link variant="h5" className={classes.breandCrumbs} component={RouterLink} to="/">
									{`${user.firstName} ${user.lastName}`}
								</Link>
								<Link variant="h5" className={classes.breandCrumbs} component={RouterLink} to={`/settings/${tab}`}>
									{t(pageTitle)}
								</Link>
							</Breadcrumbs>
						</div>
					</div>
				}
				<Tabs
					value={tab}
					onChange={handleTabChange}
					indicatorColor="primary"
					textColor="primary"
					className={classes.tabs}
				>
					<Tab value="edit-profile" label={t('edit_profile')} classes={{ root: classes.tab }} />
					<Tab value="account-settings" label={t('account_settings')} classes={{ root: classes.tab }} />
					{user.parentId !== authUser.id &&
						<Tab value="children" label={t('children')} classes={{ root: classes.tab }} />
					}
				</Tabs>
			</div>
			<div className={classes.tabContent}>
				<Fade in={tab === 'edit-profile'} timeout={500}>
					<div className={clsx({ [classes.hiddenPanel]: (tab !== 'edit-profile') })}>
						{tab === 'edit-profile' &&
							<EditProfile
								user={user}
							/>
						}
					</div>
				</Fade>
				<Fade in={tab === 'account-settings'} timeout={500}>
					<div className={clsx({ [classes.hiddenPanel]: (tab !== 'account-settings') })}>
						{tab === 'account-settings' &&
							<>
								{user.parentId !== authUser.id ?
									<AccountSettings
										user={user}
									/>
									:
									<ChildAccountSettings
										user={user}
									/>
								}
							</>
						}
					</div>
				</Fade>
				<Fade in={tab === 'children'} timeout={500}>
					<div className={clsx({ [classes.hiddenPanel]: (tab !== 'children') })}>
						{tab === 'children' &&
							<ChildrenSettings
								user={user}
							/>
						}
					</div>
				</Fade>
			</div>
		</>
	);
};

export default Settings;
