import { connect } from 'react-redux';
import flow from 'lodash.flow';
import TopBar from './TopBar';
import {signOutAuthUser, showCreateOrg, clearResourceNotFound} from 'reactive-core';
import WithAuth from 'WithAuth';

export default flow(
	connect(
		({
			authUser: { user },
			authevents: {signOutAuthUserSuccess}
		}) => ({
			authUser: user,
			signOutAuthUserSuccess,
			clearResourceNotFound
		}),
		(dispatch) => ({
			onSignOutClick: () => dispatch(signOutAuthUser()),
			onCreateOrgClick: () => dispatch(showCreateOrg())
		})
	),
	WithAuth
)(TopBar);
