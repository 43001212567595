export default (theme) => ({
	settingsTop: {
		borderBottom: 'solid 1px #e6e6e6',
		paddingTop: '57px',
		paddingLeft: '61px',
		backgroundColor: '#fff',
		[theme.breakpoints.down('sm')]: {
			padding: '0px'
		}
	},
	settingsHeader: {
		display: 'flex',
		alignItems: 'center'
	},
	avatar: {
		width: '60px',
		height: '60px',
		marginRight: '13px'
	},
	settingsBreadCrumbs: {
		display: 'flex',
	},
	breandCrumbs: {
		fontSize: '24px',
		color: theme.palette.text.primary
	},
	rightArrow: {
		width: '24px',
		height: '18px',
		'& path': {
			fill: theme.palette.secondary.main,
		}
	},
	breadCrumbSep: {
		margin: '0px'
	},
	tabs: {
		marginTop: '48px',
		marginLeft: '300px',
		[theme.breakpoints.down('sm')]: {
			margin: '0px',
			paddingTop: '24px',
			paddingLeft: '16px',
			paddingRight: '16px',
		}
	},
	hiddenPanel: {
		display: 'none'
	}
});
