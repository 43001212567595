import React, {useRef, useState} from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link as RouterLink } from 'react-router-dom';
import { ReactComponent as PencilIcon } from 'icons/Pencil_edit_icon.svg';
import Card from '@material-ui/core/Card';
import LinesEllipsis from 'react-lines-ellipsis';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { useTranslation } from 'react-i18next';

const ArticleItem = ({item, isAdmin, onRemoveItem, classes}) => {
	const { t } = useTranslation();
	const [showMenu, setShowMenu] = useState(false);
	const editButton = useRef(null);
	const onEditButtonClick = (e) => {
		setShowMenu(true);
		e.stopPropagation();
		e.preventDefault();
	};
	const handleDeleteArticle = (e, id) => {
		onRemoveItem(id);
		setShowMenu(false);
		e.stopPropagation();
		e.preventDefault();
	};
	return (
		<ButtonBase 
			className={clsx(classes.itemWrapper, classes.articleItem)}
			component={RouterLink}
			to={item.to}
		>
			<Card className={classes.itemCard}>
				<CardHeader
					className={classes.itemHeader}
					titleTypographyProps={{
						color: 'textSecondary',
						variant: 'h6'
					}}
					title={item.name}
					action={
						isAdmin &&
						(
							<> 
								<IconButton ref={editButton} aria-label="edit" onClick={onEditButtonClick} className={classes.editItemButton}>
									<PencilIcon />
								</IconButton>
								<Menu
									anchorEl={editButton.current}
									keepMounted
									open={showMenu}
									onClose={(e) => { setShowMenu(false); 	e.preventDefault();}}
								>
									<MenuItem component={RouterLink} to={`${item.to}/edit`}>{t('edit_article')}</MenuItem>
									<MenuItem onClick={(e) => {handleDeleteArticle(e, item.id);}} className={classes.deleteItemButton}>{t('delete_article')}</MenuItem>
								</Menu>
							</>
						)
					}
				/>
					
				<CardContent className={classes.itemContent}>
					<Typography variant="h6"  className={classes.itemTitle}>{item.title}</Typography>
					<Typography variant="body1"  className={classes.itemBody}>
						<LinesEllipsis
							text={item.body}
							maxLine={4}
							trimRight={false}
							basedOn='letters'
						/>
					</Typography>
				</CardContent>
				<CardActions className={classes.itemActions}>
					<Typography variant="body1" color="textSecondary" className={classes.itemDate}>{item.date}</Typography>
				</CardActions>
			</Card>
		</ButtonBase>
	);
};

export default ArticleItem;
