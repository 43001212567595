export default (theme) => ({
	ProfileImageEdit: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	heroWrapper: {
		height: '180px',
		width: '100%',
		borderBottom: `3px solid ${theme.palette.secondary.main}`,
		background: 'linear-gradient(45deg, #A7B5DB 30%, #F4E1DC 90%)',
		backgroundColor: '#e4e4e4',
		backgroundPosition: 'center',
		backgroundSize: 'cover', 
		backgroundRepeat: 'no-repeat',
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'flex-end'
	},
	heroImage: {
		position: 'relative',
		width: '100%',
		height: '100%'
	},
	fallbackAvatar:{
		color: '#f2f2f2'
	},
	editHeroButton: {
		marginTop: '5px',
		marginRight: '5px',
		position: 'absolute'
	},
	avatarWrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatarWrapperWithIcon: {
		alignItems: 'flex-end'
	},
	editAvatarButton: {
		marginTop: '-48px',
		borderRadius: '50%',
		display:'flex',
		alignItems: 'center',
		justifyContent: 'center',
		border: 'solid 3px #ffffff',
		backgroundColor: '#f8f8f8',
		width: '47px',
		height: '47px'
	},
	editAvatarTextButton: {
		fontSize: '18px',
		fontFamily: 'Source Sans Pro'
	},
	emptyAvatar:{
		border: '2px dashed #d6d6d6 !important'
	},
	avatar: props => ( {
		cursor: 'pointer',
		backgroundColor: '#fff',
		width: `${props.size}px`,
		height:`${props.size}px`,
		border: '4px solid #fff',
		backgroundPosition: 'center',
		backgroundSize: 'cover', 
		backgroundRepeat: 'no-repeat'
	})
});
