import React from 'react';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import { 
	Link as RouterLink
} from 'react-router-dom';
import Link from '@material-ui/core/Link';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import styles from './styles';
const useStyles = makeStyles(styles);

const KMenu = ({
	title,
	menuItems,
	...menuProps
}) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return(
		<Menu
			{...menuProps}
			marginThreshold={0}
			classes={{paper: classes.mainMenuPaper}}
			TransitionComponent={Collapse}
		>
			<Typography variant="h6" component="span" className={classes.menuNameTitle}>
				{title}
			</Typography>
			<Divider className={classes.divider} />
			{menuItems.map((section, idx) => (
				<div key={idx}>
					{section.title &&
					<Typography variant="body1" className={classes.menuSectionHeader}>{t(section.title)}</Typography>
					}
					{
						section.items.map((item) => (
							<div key={item.label}>
								{item.to ?
									<MenuItem className={clsx(classes.menuItem, item.classes)}>
										<Link
											component={RouterLink}
											underline="none"
											to={item.to}
											onClick={() => {menuProps.onClose();}}
											color="inherit"
										>{t(item.label)}</Link>
									</MenuItem>
									:
									<MenuItem className={clsx(classes.menuItem, item.classes)} onClick={item.onClick}>{t(item.label)}</MenuItem>
								}
							</div>
						))
					}
					{idx < menuItems.length -1 && <Divider className={classes.divider} />}
				</div>
			))}
		</Menu>
	);
};

export default KMenu;
