import { connect } from 'react-redux';
import flow from 'lodash.flow';
import CreateEditArticle from './CreateEditArticle';
import { createArticle, updateArticle, deleteArticle, clearArticleSuccess } from 'reactive-core';
import WithResourceNotFound from 'components/WithResourceNotFound';
import {getEditArticle} from 'selectors/articleSelector';

export default flow(
	connect(
		({
			uievents: {createArticleSuccess, updateArticleSuccess},
			article: {article},
		}) => ({
			article: article && getEditArticle({article}),
			createArticleSuccess,
			updateArticleSuccess
		}),
		(dispatch) => ({
			createArticle: (articleData) => dispatch(createArticle(articleData)),
			editArticle: (articleData) => dispatch(updateArticle(articleData)),
			deleteArticle: (id) => dispatch(deleteArticle(id)),
			clearArticleSuccess: () => dispatch(clearArticleSuccess())
			
		})
	)
)(WithResourceNotFound(CreateEditArticle));
