export default () => ({
	root: {
		textAlign: 'center'
	},
	divider: {
		marginBottom: '18px'
	},
	subText: {
		fontSize: '18px',
		marginBottom: '2px'
	}
});
