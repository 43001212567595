import { connect } from 'react-redux';
import flow from 'lodash.flow';
import OrgTeams from './OrgTeams';

import { createTeam, fetchOrgTeams, clearCreatedTeamId, removeTeam } from 'reactive-core';

export default flow(
	connect(
		({
			authUser: { user },
			uievents: {
				creatingTeam,
				createTeamSuccess,
				removeTeamSuccess,
				removingTeam
			},
		},
		{
			org
		}) => ({
			user,
			createTeamSuccess,
			creatingTeam,
			teams: org && org.teams,
			org: org,
			removeTeamSuccess,
			removingTeam
		}),
		(dispatch) => ({
			onSubmitTeam: (teamData) => dispatch(createTeam(teamData)),
			fetchOrgTeams: (fetchObj) => dispatch(fetchOrgTeams(fetchObj)),
			clearCreatedTeamId: () => dispatch(clearCreatedTeamId()),
			removeTeam: (id) => dispatch(removeTeam(id)),
		})
	)
)(OrgTeams);
