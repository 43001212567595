export default (theme) => ({
	listItem: {
		paddingLeft: '0px',
		paddingTop: '16px',
		paddingBottom: '16px',
		borderBottom: `1px solid ${theme.palette.divider}`
	},
	avatar: {
		height: '60px',
		width: '60px',
	},
	avatarWrapper: {
		marginLeft: '16px',
		marginRight: '21px'
	},
	badge: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.secondary.main,
		border: '2px solid #fff'

	},
	badgeDot: {
		height: '12px',
		width: '12px',
		borderRadius: '50%'
	},
	itemTextWrapper: {
		display: 'flex',
		flex: 1,
		justifyContent: 'space-between'
	},
	listItemText: {
		[theme.breakpoints.up('sm')]: {
			fontSize: '18px'
		}
	},
	primarySection: {
		flex: 'none',
		minWidth: '25%'
	},
	secondarySection: {
		marginLeft: '20px',
		flex: 1,
		[theme.breakpoints.down('sm')]: {
			position: 'absolute',
			top: '5px',
			right: '8px',
			marginLeft: '0px',
			flex: 'none'
		}
	},

	statusWrapper: {
		display: 'flex',
		alignItems: 'center',
		marginRight: '70px'
	},
	status: {
		fontSize: '14px',
		color: theme.palette.secondary.main,
	},
	actionWrapper: {
		[theme.breakpoints.up('sm')]: {
			right: '34px',
		},
		display: 'flex'
	},
	menuButton: {
		cursor: 'pointer'
	}
});
