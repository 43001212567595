import React, { useEffect } from 'react';
import {  useParams, Link as RouterLink, } from 'react-router-dom';
import splash from 'img/invite-splash.png';
import { Trans } from 'react-i18next';
import AuthSplashForm from 'components/AuthSplashForm';

import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(styles);

const InvitationHandler = ({ signOutAuthUser }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { inviteId } = useParams();

	useEffect(() => { signOutAuthUser(); }, []);

	return (
		<AuthSplashForm
			formTitle={t('youve_been_invited')}
			formSubTitle={t('create_your_account_and_join')}
			splashImg={splash}
			classes={classes}
		>
			<div className={classes.inviteWrapper}>
				<Typography variant="subtitle1" >
					<Trans t={t} i18nKey="are_you_a_parent_or_guardian">
						Are you a <span className={classes.boldText}>parent or guardian</span> and want to sign your child up?
					</Trans>
				</Typography>
				<Typography variant="subtitle1">{t('create_your_account_first')}</Typography>
				<Button
					variant="contained"
					color="primary"
					className={classes.signUpButton}
					fullWidth
					component={RouterLink}
					to={`/sign-up?isParent=true&invite=${inviteId}`}
				>{t('parent_sign_up')}</Button>
				<Typography variant="subtitle1" className={classes.lowerText} >
					<Trans t={t} i18nKey="are_you_a_player_or_coach">
						Are you a <span className={classes.boldText}>player or coach or other user?</span> Create your own account now and join your team!
					</Trans>
				</Typography>
				<Button
					variant="contained"
					color="primary"
					className={classes.signUpButton}
					fullWidth
					component={RouterLink}
					to={`/sign-up?invite=${inviteId}`}
				>{t('player_coach_sign_up')}</Button>
			</div>
		</AuthSplashForm>
	);
};

export default InvitationHandler;
