import React, { useState, useCallback, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import styles from './styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Backdrop from '@material-ui/core/Backdrop';
import Slider from '@material-ui/core/Slider';

import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import SvgIcon from '@material-ui/core/SvgIcon';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ReactComponent as PlusIcon } from 'icons/Plus_icon.svg';
import { ReactComponent as MinusIcon } from 'icons/Minus_icon.svg';
import Cropper from 'react-easy-crop';
import getCroppedImg from 'utils/imageUtils/cropImage';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(styles);


const ImageUpload = ({
	show,
	onClose,
	title,
	cropShape,
	aspect,
	image,
	onApply
}) => {
	const theme = useTheme();
	const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));
	const classes = useStyles();
	const { t } = useTranslation();
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [imageUrl, setImageUrl] = useState(image);
	const [croppedAreaPx, setCroppedAreaPx] = useState(null);
	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setCroppedAreaPx(croppedAreaPixels);
	}, []);

	useEffect(() => {
		setImageUrl(image);
	}, [image]);

	const handleOnApply = async () => {
		try {
			const croppedImage = await getCroppedImg(
				imageUrl,
				croppedAreaPx
			);
			setImageUrl(null);
			setZoom(1);
			onApply(imageUrl, croppedImage);
		} catch (e) {
			console.error(e);
		}
	};
	const handleClose = () => {
		setImageUrl(null);
		setZoom(1);
		onClose();
	};

	return (
		<Dialog
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			disableBackdropClick
			open={show}
			classes={{ paperWidthSm: classes.paperWidthSm }}
			fullScreen={matchesSm}
			maxWidth="sm"
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<DialogTitle id="transition-modal-title" disableTypography>
				<Typography variant="h5" component="h1" className={classes.title}>
					{title}
				</Typography>
			</DialogTitle>
			<DialogContent className={classes.contentWrapper}>
				<div className={classes.cropContainer}>
					<Cropper
						classes={{ cropAreaClassName: classes.cropArea }}
						image={imageUrl}
						crop={crop}
						cropShape={cropShape}
						zoom={zoom}
						aspect={aspect}
						onCropChange={setCrop}
						onCropComplete={onCropComplete}
						onZoomChange={setZoom}
					/>
				</div>
				<Toolbar className={classes.sliderToolbar}>
					<SvgIcon component={MinusIcon} viewBox="0 0 17 2" className={classes.sliderIcons} />
					<Slider
						value={zoom}
						min={1}
						max={3}
						step={0.1}
						aria-labelledby="Zoom"
						onChange={(e, zoom) => setZoom(zoom)}
						color="secondary"
						classes={{ rail: classes.sliderRail, track: classes.sliderTrack, thumb: classes.sliderThumb }}
					/>
					<SvgIcon component={PlusIcon} viewBox="0 0 18 18" className={classes.sliderIcons} />
				</Toolbar>
			</DialogContent>
			<DialogActions className={classes.actionsWrapper} >
				<Button
					autoFocus
					onClick={handleClose}
					className={classes.cancelButton}
					variant="outlined"
				>
					{t('cancel')}
				</Button>
				<Button
					variant="contained"
					color="primary"
					onClick={handleOnApply}
					className={classes.saveButton}
				>
					{t('save')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ImageUpload;
