import { connect } from 'react-redux';
import flow from 'lodash.flow';
import TeamSettings from './TeamSettings';
import {
	updateTeam
} from 'reactive-core';

export default flow(
	connect(
		({
			authUser: { user },

			profile: { team }
		}) => ({
			authUser: user,
			team
		}),
		(dispatch) => ({
			onSubmit: (updateData) => dispatch(updateTeam(updateData))
		})
	)
)(TeamSettings);
